import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from '../General/Header';
import Footer from "../General/Footer.js"

import { postApiPrivate } from '../../api';
import { isDesktop } from 'react-device-detect';

import Shop from '../Shop/Shop';
import ShopCategories from '../Shop/ShopCategories';
import ShopCategory from '../Shop/ShopCategory';
import ShopProducts from '../Shop/ShopProducts';
import ShopProduct from '../Shop/ShopProduct';
import ShopOrders from '../Shop/ShopOrders';
import ShopOrder from '../Shop/ShopOrder';
import ShopSubscriptions from '../Shop/ShopSubscriptions';
import ShopSubscription from '../Shop/ShopSubscription';
import Checkout from '../Shop/Checkout';
import CheckoutInfo from '../Shop/CheckoutInfo';
import CheckoutUpsell from '../Shop/CheckoutUpsell';
import CheckoutShipping from '../Shop/CheckoutShipping';
import CheckoutPayment from '../Shop/CheckoutPayment';
import CheckoutConfirmation from '../Shop/CheckoutConfirmation';

const PublicContainer = (props) => {

  const { TRANSLATE, USER, LANGUAGE, onUpdateLanguage, onFetchUser, onFacebookTracking, onKlaviyoTracking, onFetchAnalytics, setOverflow, location, match } = props;

  const sharedProps = {
    ...props,
    TRANSLATE,
    USER,
    LANGUAGE,
    onUpdateLanguage,
    onFetchUser,
    onFacebookTracking,
    onKlaviyoTracking,
    setOverflow
  }

  useEffect(() => {

    const language = match.params.lang;
    const langArray = ['en', 'it', 'fr', 'es'];

    if(langArray.includes(language)){
      onUpdateLanguage(language);
    }
    else{
      const LANG = localStorage.getItem('language') || 'en';
      onUpdateLanguage(LANG);
    }

  }, [match.params.lang]);


  useEffect(() => {
    onFetchAnalytics({ location });
    onFacebookTracking('pageView');

    window.scrollTo(0, 0);

  }, [match.url]);



  const { isModalCart, isModalSearch, isModalLocalisation, isModalUser, isModalProvider, isModalGuide, isModalCity } = props;

  useEffect(() => {

    const isActiveChat = match.params.store && match.params.customer && !isDesktop;

    if (isModalCart || isModalSearch || isModalLocalisation || isModalUser || isModalProvider || isModalGuide || isActiveChat || isModalCity) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isModalCart, isModalSearch, isModalLocalisation, isModalUser, isModalProvider, isModalGuide, isModalCity, match.params])



  return (

    <>

      <Header {...sharedProps} />

      <Switch>

        {/*** Public - Shop ***/}
        <Route exact path="/:lang/shop" render={(props) => <Shop {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/categories" render={(props) => <ShopCategories {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/categories/:id" render={(props) => <ShopCategory {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/products" render={(props) => <ShopProducts {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/products/:id" render={(props) => <ShopProduct {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/orders" render={(props) => <ShopOrders {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/orders/:id" render={(props) => <ShopOrder {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/subscriptions" render={(props) => <ShopSubscriptions {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/subscriptions/:id" render={(props) => <ShopSubscription {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout" render={(props) => <Checkout {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout/info" render={(props) => <CheckoutInfo {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout/upsell" render={(props) => <CheckoutUpsell {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout/shipping" render={(props) => <CheckoutShipping {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout/payment" render={(props) => <CheckoutPayment {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout/confirmation/:id" render={(props) => <CheckoutConfirmation {...sharedProps} {...props} />} />


      </Switch>


    </>

  )


}


export default PublicContainer;
