import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { API_ROOT } from '../../api';
import { DefaultAlert } from '../../alerts';

import { useGoogleLogin } from '@react-oauth/google';

import SRC_LOADER from '../../assets/img/loader.png'
import SRC_GOOGLE from '../../assets/img/google.svg'

import { GetRouteSearchParams } from '../../helpers/GeneralHelper';
import swal from 'sweetalert';


const AuthFormLogin = (props) => {

    const { TRANSLATE, LANGUAGE, history, onStartUser, ContainerClass, AsterixClass, ButtonClass, GoogleClass, ButtonsClass, ContainerStyles, location } = props;

    const [EMAIL, setEmail] = useState('');
    const [PASSWORD, setPassword] = useState('');

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {

        const routeParams = GetRouteSearchParams(location.search);
        const routeCode = routeParams.code || '';

        if (routeCode) {
            //alert('The Code is ===> '+routeCode);
            onLoginGoogleFlow(routeCode);
        }

    }, [location.search])

    const onKeyUp = (event) => {

        event.preventDefault();


        if (event.keyCode === 13) {

            event.target.blur();
            onLogin();

        }
    }

    const onLogin = () => {

        const routeParams = GetRouteSearchParams(location.search);
        const routeNext = routeParams.next || '';

        // if (!isValidEmail(EMAIL)) {
        //     return DefaultAlert(TRANSLATE.wd_login, TRANSLATE.error_invalid_email)
        // }

        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        var form = {
            email: EMAIL.trim(),
            password: PASSWORD.trim(),
            lang: LANGUAGE,
            timezone: userTimezone

        }

        setLoading(true);

        axios.post(API_ROOT + '/users/login', form).then(response => {


            setLoading(false);

            localStorage.setItem('jwt_token', response.data.jwt_token);

            const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
            if (isNativeApp) {
                const webData = {
                    type: 'LOGIN',
                    data: response.data.jwt_token
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(webData));
            }

            onStartUser();

            if (routeNext) {
                history.push(routeNext);
                return
            }

            history.push('/' + TRANSLATE.lang + '/settings');

        })
            .catch(error => {

                setLoading(false);

                if (!error || !error.response) {
                    return DefaultAlert('Login', TRANSLATE.error_invalid_credentials)
                }

                var error_data = error.response.data;
                var error_message = error_data.error;

                if (error_message === 'missing_password') {
                    return DefaultAlert('Login', TRANSLATE.error_missing_password)
                }
                else if (error_message === 'missing_email') {
                    return DefaultAlert('Login', TRANSLATE.error_invalid_email)
                }

                return DefaultAlert('Login', TRANSLATE.error_invalid_credentials)
            });
    }

    const onLoginGoogle = async (params) => {

        const routeParams = GetRouteSearchParams(location.search);
        const routeNext = routeParams.next || '';

        if (!params) {
            return DefaultAlert(TRANSLATE.wd_login, TRANSLATE.error_invalid_email)
        }

        setLoading(true);

        if (params.access_token) {
            const Response = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo?access_token=" + params.access_token);
            const ResponseData = Response ? Response.data : {};
            params = {
                ...params,
                ...ResponseData
            }
        }

        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        var FORM = {
            lang: LANGUAGE,
            oauth: params,
            timezone: userTimezone

        }

        axios.post(API_ROOT + '/users/login/google', FORM).then(response => {


            setLoading(false);

            localStorage.setItem('jwt_token', response.data.jwt_token);

            const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
            if (isNativeApp) {
                const webData = {
                    type: 'LOGIN',
                    data: response.data.jwt_token
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(webData));
            }

            onStartUser();

            if (routeNext) {
                history.push(routeNext);
                return
            }

            history.push(`/${LANGUAGE}/settings`);

        })
            .catch(error => {

                setLoading(false);

                history.push(`/${LANGUAGE}/login`);

                if (!error || !error.response) {
                    return DefaultAlert('Login', TRANSLATE.error_invalid_credentials)
                }

                var error_data = error.response.data;
                var error_message = error_data.error;

                if (error_message === 'missing_password') {
                    return DefaultAlert('Login', TRANSLATE.error_missing_password)
                }
                else if (error_message === 'missing_email') {
                    return DefaultAlert('Login', TRANSLATE.error_invalid_email)
                }

                return DefaultAlert('Login', TRANSLATE.error_invalid_credentials)
            });
    }

    const onLoginGoogleFlow = async (authCode) => {

        const isLocalhost = window.location.href.includes('localhost:3000');

        try {

            setLoading(true);

            const clientId = '863081838943-30fkte6e38age2e1jj31nl9jm0it54t9.apps.googleusercontent.com';
            const clientSecret = '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';
            const redirectUri = isLocalhost ? 'http://localhost:3000/en/login' : 'https://www.avioyx.com/en/login';

            const tokenEndpoint = 'https://oauth2.googleapis.com/token';
            const userDataEndpoint = 'https://www.googleapis.com/oauth2/v3/userinfo';

            const data = new URLSearchParams();
            data.append('code', authCode);
            data.append('client_id', clientId);
            data.append('client_secret', clientSecret);
            data.append('redirect_uri', redirectUri);
            data.append('grant_type', 'authorization_code');

            // Step 1: Exchange auth code for access token and refresh token
            const tokenResponse = await axios.post(tokenEndpoint, data,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            //return swal('success', JSON.stringify(tokenResponse, null, 2), 'success');


            const tokenData = tokenResponse.data;


            // Step 2: Fetch user data using access token
            const userDataResponse = await fetch(userDataEndpoint, {
                headers: {
                    Authorization: `Bearer ${tokenData.access_token}`
                }
            });

            const userData = await userDataResponse.json();

            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


            var FORM = {
                lang: LANGUAGE,
                oauth: {
                    ...userData,
                    accessToken: tokenData.access_token,
                    refreshToken: tokenData.refresh_token,
                    picture: userData.picture ? userData.picture.replace('s96', 's512') : ''

                },
                timezone: userTimezone

            }

            //return swal('success', JSON.stringify(FORM, null, 2), 'success');

            axios.post(API_ROOT + '/users/login/google', FORM).then(response => {

                setLoading(false);

                localStorage.setItem('jwt_token', response.data.jwt_token);

                const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
                if (isNativeApp) {
                    const webData = {
                        type: 'LOGIN',
                        data: response.data.jwt_token
                    }
                    window.ReactNativeWebView.postMessage(JSON.stringify(webData));
                }

                onStartUser();

                history.push(`/${LANGUAGE}/settings`);

            })
                .catch(error => {

                    setLoading(false);

                    history.push(`/${LANGUAGE}/login`);

                    if (!error || !error.response) {
                        return DefaultAlert('Login', TRANSLATE.error_invalid_credentials)
                    }

                    var error_data = error.response.data;
                    var error_message = error_data.error;

                    if (error_message === 'missing_password') {
                        return DefaultAlert('Login', TRANSLATE.error_missing_password)
                    }
                    else if (error_message === 'missing_email') {
                        return DefaultAlert('Login', TRANSLATE.error_invalid_email)
                    }

                    return DefaultAlert('Login', TRANSLATE.error_invalid_credentials)
                });

        }
        catch (e) {
            setLoading(false);
            history.push(`/${LANGUAGE}/login`);
            swal('error', e.message, 'success')
        }

    }


    const routeParams = GetRouteSearchParams(location.search);
    const routeCode = routeParams.code || '';

    return (
        <>

            {routeCode ? (
                <div className={ContainerClass} style={ContainerStyles}>
                    <div className="w-full flex  bg-zinc-100">
                        <div className="w-1/2 text-center py-4 bg-white font-semibold">Login</div>
                        <Link to={'/' + LANGUAGE + '/signup'} className="w-1/2 py-4 text-center"> {TRANSLATE.wd_signup}</Link>
                    </div>
                    <div className="w-full p-6 pb-4">
                        <label className="text-sm font-semibold">{TRANSLATE.wd_email} <span className={AsterixClass}>*</span></label>
                        <input className="border border-zinc-200 focus:border-zinc-300 h-[50px] w-full outline-0 bg-white ring-0 bg-transparent rounded-lg pl-4 focus:outline-none" type="text" placeholder={TRANSLATE.tool_your_email}
                            name="email"
                            value={EMAIL}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyUp={(e) => onKeyUp(e)}

                        />
                    </div>
                    <div className="w-full p-6 pt-0">
                        <label className="text-sm font-semibold">{TRANSLATE.wd_password} <span className={AsterixClass}>*</span></label>
                        <input className="border border-zinc-200 focus:border-zinc-300 h-[50px] w-full outline-0 ring-0  bg-white rounded-lg focus:outline-none pl-4" type="password" placeholder="*************"
                            name="password"
                            value={PASSWORD}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyUp={(e) => onKeyUp(e)}

                        />
                    </div>



                    <div className="flex w-full px-6">

                        {isLoading ? (
                            <div className='w-full h-[150] p-10 flex items-center justify-center'>
                                <img className='animate-spin w-[24px] h-[24px]' src={SRC_LOADER} />
                            </div>
                        ) : (
                            <div className={ButtonsClass}>
                                <button className={ButtonClass} onClick={e => onLogin(e)}>
                                    {TRANSLATE.wd_login}
                                </button>
                                <div className="flex my-4 w-full items-center before:h-px  before:w-full before:block before:bg-zinc-200  after:h-px after:w-full after:block after:bg-zinc-200"><div className="px-3">or</div></div>
                                <GoogleLoginComponent {...props} GoogleClass={GoogleClass} onLoginGoogle={onLoginGoogle} />

                            </div>
                        )}

                    </div>

                </div>
            ) : (
                <div className={ContainerClass} style={ContainerStyles}>
                    <div className="w-full flex  bg-zinc-100">
                        <div className="w-1/2 text-center py-4 bg-white font-semibold">Login</div>
                        <Link to={'/' + LANGUAGE + '/signup'} className="w-1/2 py-4 text-center"> {TRANSLATE.wd_signup}</Link>
                    </div>
                    <div className="w-full p-6 pb-4">
                        <label className="text-sm font-semibold">{TRANSLATE.wd_email} <span className={AsterixClass}>*</span></label>
                        <input className="border pl-4 border-zinc-200 focus:border-zinc-300 h-[50px] w-full outline-0 bg-white ring-0 bg-transparent rounded-lg pl-4 focus:outline-none" type="text" placeholder={TRANSLATE.tool_your_email}
                            name="email"
                            value={EMAIL}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyUp={(e) => onKeyUp(e)}

                        />
                    </div>
                    <div className="w-full p-6 pt-0">
                        <label className="text-sm font-semibold">{TRANSLATE.wd_password} <span className={AsterixClass}>*</span></label>
                        <input className="border pl-4 border-zinc-200 focus:border-zinc-300 h-[50px] w-full outline-0 ring-0  bg-white rounded-lg focus:outline-none" type="password" placeholder="*************"
                            name="password"
                            value={PASSWORD}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyUp={(e) => onKeyUp(e)}

                        />
                    </div>



                    <div className="flex w-full px-6">

                        {isLoading ? (
                            <div className='w-full h-[150] p-10 flex items-center justify-center'>
                                <img className='animate-spin w-[24px] h-[24px]' src={SRC_LOADER} />
                            </div>
                        ) : (
                            <div className={ButtonsClass}>
                                <button className={ButtonClass} onClick={e => onLogin(e)}>
                                    {TRANSLATE.wd_login}
                                </button>
                                <div className="flex my-4 w-full items-center before:h-px  before:w-full before:block before:bg-zinc-200  after:h-px after:w-full after:block after:bg-zinc-200"><div className="px-3">or</div></div>
                                <GoogleLoginComponent {...props} GoogleClass={GoogleClass} onLoginGoogle={onLoginGoogle} />

                            </div>
                        )}

                    </div>

                </div>
            )}


        </>
    )


}

const isValidEmail = (email) => {

    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);

}


const GoogleLoginComponent = (props) => {

    const { GoogleClass, TRANSLATE, onLoginGoogle } = props;

    const [DATA, setData] = useState();

    const isLocalhost = window.location.href.includes('localhost:3000');

    const login = useGoogleLogin({
        flow: 'auth-code',
        ux_mode: 'redirect',
        prompt: 'select_account',
        redirect_uri: isLocalhost ? 'http://localhost:3000/en/login' : 'https://www.avioyx.com/en/login',
        onSuccess: credentialResponse => {
            console.log(JSON.stringify(credentialResponse));
            setData(credentialResponse);
            onLoginGoogle(credentialResponse);
            return
        },
        onError: error => {
            console.log('Login Failed');
            setData(error)
        },
    });

    return (
        <div className="w-full h-full">
            <button className={GoogleClass + ' mb-5'} onClick={() => login()}>
                <img src={SRC_GOOGLE} />{TRANSLATE.wd_login_google}
            </button>
        </div>
    );
}


export default AuthFormLogin
