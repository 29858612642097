import React from "react";

import SRC_HOME from '../../assets/img/mobile-bar/home.svg';
import SRC_SEARCH from '../../assets/img/mobile-bar/search.svg';
import SRC_PETS from '../../assets/img/mobile-bar/pets.svg';
import SRC_CHAT from '../../assets/img/mobile-bar/chat.svg';
import SRC_USER from '../../assets/img/mobile-bar/user.svg';


const MobileBar = (props) => {

     const { USER, LANGUAGE, match, history, CART = [] } = props;

     const CURRENT_URL = match.url;

     let active = 'home';

     if (CURRENT_URL.includes('/search')) {
          active = 'search'
     }

     if (CURRENT_URL.includes('/pets')) {
          active = 'pets'
     }

     if (CURRENT_URL.includes('/dashboard')) {
          active = 'dashboard'
     }

     if (CURRENT_URL.includes('/inbox')) {
          active = 'inbox'
     }

     if (CURRENT_URL.includes('/settings')) {
          active = 'settings'
     }

     if (CURRENT_URL.includes('/home')) {
          active = 'home'
     }

     //Setup Counts
     const { CMC } = props;
     const INBOX_COUNT = CMC || 0;


     const isInvisible = (
          match.params.code ||
          match.params.id ||
          match.url.includes('/bookings') ||
          match.url.includes('/challenges') || 
          match.url.includes('/wallet') || 
          match.url.includes('/earnings') ||
          match.url.includes('/reviews')
     );

     if (isInvisible) {
          return <></>
     }


     return (
          <>
               <div className="w-full flex-shrink-0 flex md:hidden items-center relative bg-white h-[70px] z-[8888] border-t border-zinc-100">
                    <div className={"w-1/5 h-[2px] bg-black absolute -top-[1px] transition-all duration-200 " + (active === 'home' ? 'left-0' : '') + (active === 'search' ? 'left-[20%]' : '') + (active === 'dashboard' ? 'left-[40%]' : '') + (active === 'inbox' ? 'left-[60%]' : '') + (active === 'settings' ? 'left-[80%]' : '')} />

                    <div className="w-full absolute h-full left-0 top-0">
                         <div className={"w-1/5 h-[44px] rounded-lg top-[calc(50%-22px)] absolute flex items-center justify-center transition-all duration-500 " + (active === 'home' ? 'left-0' : '') + (active === 'search' ? 'left-[calc(20%+0px)]' : '') + (active === 'dashboard' ? 'left-[40%]' : '') + (active === 'inbox' ? 'left-[calc(60%)]' : '') + (active === 'settings' ? 'left-[calc(80%)]' : '')}>
                              <div className="w-[44px] h-[44px] bg-slate-100 rounded-lg" />
                         </div>
                    </div>


                    <div className="mx-auto max-w-md w-full flex items-center relative z-20 justify-between my-auto">
                         <div className={"w-1/5 h-[44px] flex items-center justify-center cursor-pointer rounded-lg transition-all disable-tap " + (active === 'home' ? '' : '')} onClick={() => history.push('/' + LANGUAGE + '/home')}>
                              <img src={SRC_HOME} alt="" />
                         </div>
                         <div className={"w-1/5 h-[44px] flex items-center justify-center cursor-pointer rounded-lg disable-tap " + (active === 'search' ? '' : '')} onClick={() => history.push('/' + LANGUAGE + '/search')}>
                              <img src={SRC_SEARCH} alt="" />
                         </div>
                         <div className={"w-1/5 h-[44px] flex items-center justify-center cursor-pointer rounded-lg relative disable-tap " + (active === 'dashboard' ? '' : '')} onClick={() => history.push('/' + LANGUAGE + '/dashboard')}>
                              <img src={SRC_PETS} alt="" />
                         </div>
                         <div className={"w-1/5 h-[44px] flex items-center justify-center cursor-pointer rounded-lg relative disable-tap " + (active === 'inbox' ? '' : '')} onClick={() => history.push('/' + LANGUAGE + '/inbox')}>
                              <img src={SRC_CHAT} alt="" />
                              {INBOX_COUNT > 0 && (
                                   <div className='w-[16px] h-[16px] text-[9px] text-white flex justify-center items-center rounded-full bg-avioyx-100 absolute' style={{ top: 6, right: 5 }}>{INBOX_COUNT > 9 ? '9+' : INBOX_COUNT}</div>
                              )}
                         </div>

                         <div className="w-1/5 h-[44px] flex items-center justify-center cursor-pointer disable-tap " onClick={() => history.push('/' + LANGUAGE + '/settings')}>
                              <img src={SRC_USER} alt="" />
                         </div>


                    </div>
               </div>
          </>
     )
}

export default MobileBar