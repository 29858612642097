import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from '../General/Header';
import Footer from "../General/Footer.js"

import Aircrafts from '../Aircrafts/Aircrafts.js';
import Airports from '../Airports/Airports.js';
import Calculator from '../Calculator/Calculator.js';
import Community from '../Community/Community.js';
import Courses from '../Courses/Courses.js';
import Dashboard from '../Dashboard/Dashboard.js';
import Flights from '../Flights/Flights.js';
import NewFlight from '../Flights/NewFlight.js';
import Logbook from '../Logbook/Logbook.js';
import Licenses from '../Licenses/Licenses.js';
import Profile from '../Profile/Profile.js';
import Calendar from '../Calendar/Calendar.js';
import Search from '../Search/Search.js';
import Inbox from '../Inbox/Container.js';
import Notifications from '../Inbox/Notifications.js';

import Aircraft from '../Aircrafts/Aircraft.js';
import Airport from '../Airports/Airport.js';
import Flight from '../Flights/Flight.js';
import Sidebar from '../Dashboard/Sidebar.js';


const DashboardContainer = (props) => {

  const { TRANSLATE, USER, CART, LANGUAGE, onUpdateLanguage, onFetchUser, onFacebookTracking, onKlaviyoTracking, onFetchAnalytics, setOverflow, location, match, history } = props;

  const [isSelecting, setSelecting] = useState(false);

  const sharedProps = {
    ...props,
    TRANSLATE,
    USER,
    CART,
    LANGUAGE,
    onUpdateLanguage,
    onFetchUser,
    onFacebookTracking,
    onKlaviyoTracking,
    setOverflow,
    isSelecting,
    setSelecting
  }

  useEffect(() => {

    const language = match.params.lang;
    const langArray = ['en', 'it', 'fr', 'es'];

    if (langArray.includes(language)) {
      onUpdateLanguage(language);
    }
    else {
      const LANG = localStorage.getItem('language') || 'en';
      onUpdateLanguage(LANG);
    }

  }, [match.params.lang]);


  useEffect(() => {
    onFetchAnalytics({ location });
    onFacebookTracking('pageView');

    window.scrollTo(0, 0);

  }, [match.url]);


  // useEffect(() => {
  //   const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
  //   if (!JWT) {
  //     return history.replace('/' + LANGUAGE + '/login?next=' + match.url);
  //   }
  //   else {
  //     //alert("USER ===> ", USER)
  //     if (USER && USER.name) {
  //       if (!USER.setup) {
  //         return history.replace('/' + LANGUAGE + '/flow');
  //       }
  //     }
  //   }
  // }, [USER]);


  const { isModalCart, isModalSearch, isModalLocalisation, isModalUser, isModalProvider, isModalGuide, isModalCity } = props;

  useEffect(() => {

    const isActiveChat = match.params.store && match.params.customer;
    const isModalCode = match.params.code;

    if (isModalCode || isModalCart || isModalSearch || isModalLocalisation || isModalUser || isModalProvider || isModalGuide || isActiveChat || isModalCity) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isModalCart, isModalSearch, isModalLocalisation, isModalUser, isModalProvider, isModalGuide, isModalCity, match.params])




  // if (!USER) {
  //   return (
  //     <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
  //       <div className='avioyx-circle'>
  //         <div className='avioyx-paw-small left' />
  //         <div className='avioyx-paw-small right' />
  //         <div className='avioyx-paw-big left' />
  //         <div className='avioyx-paw-big right' />
  //       </div>
  //     </div>
  //   )
  // }

  return (

    <div className='flex w-full h-full overflow-auto'>

      

      <Sidebar {...sharedProps} />
      
      <div className='w-full h-full mx-auto max-w-6xl px-4 sm:px-6 lg:px-8'>
        <Header {...sharedProps} />
        <Switch>

          <Route exact path="/:lang/dashboard" render={(props) => <Dashboard {...sharedProps} {...props} />} />
          <Route exact path="/:lang/aircrafts" render={(props) => <Aircrafts {...sharedProps} {...props} />} />
          <Route exact path="/:lang/aircrafts/:id" render={(props) => <Aircraft {...sharedProps} {...props} />} />
          <Route exact path="/:lang/courses" render={(props) => <Courses {...sharedProps} {...props} />} />
          <Route exact path="/:lang/flights" render={(props) => <Flights {...sharedProps} {...props} />} />
          <Route exact path="/:lang/flights/flight" render={(props) => <Flight {...sharedProps} {...props} />} />
          <Route exact path="/:lang/flights/new-flight" render={(props) => <NewFlight {...sharedProps} {...props} />} />
          <Route exact path="/:lang/logbook" render={(props) => <Logbook {...sharedProps} {...props} />} />
          <Route exact path="/:lang/licenses" render={(props) => <Licenses {...sharedProps} {...props} />} />
          <Route exact path="/:lang/calendar" render={(props) => <Calendar {...sharedProps} {...props} />} />
          <Route exact path="/:lang/calculator" render={(props) => <Calculator {...sharedProps} {...props} />} />
          <Route exact path="/:lang/airports" render={(props) => <Airports {...sharedProps} {...props} />} />
          <Route exact path="/:lang/airports/:id" render={(props) => <Airport {...sharedProps} {...props} />} />
          <Route exact path="/:lang/community" render={(props) => <Community {...sharedProps} {...props} />} />
          <Route exact path="/:lang/search" render={(props) => <Search {...sharedProps} {...props} />} />
          <Route exact path="/:lang/inbox" render={(props) => <Inbox {...sharedProps} {...props} />} />
          <Route exact path="/:lang/notifications" render={(props) => <Notifications {...sharedProps} {...props} />} />
          <Route exact path="/:lang/profile" render={(props) => <Profile {...sharedProps} {...props} />} />

        </Switch>
      </div>

      <Footer {...sharedProps} />

    </div>

  )


}


export default DashboardContainer;
