import React, { useEffect, useState } from "react";

import Search from "./Search"
import Guide from "./Guide"
import Language from "./Language";

import SidebarMobile from "./SidebarMobile";
import ReactFileReader from 'react-file-reader';

//IMAGES
import SRC_LOGO from '../../assets/img/logo.png';
import SRC_DOG from '../../assets/img/dog.svg';
import SRC_INFO from '../../assets/img/info.svg';
import SRC_CHAT from '../../assets/img/chat.svg';
import SRC_AVATAR from '../../assets/img/avatar.svg';
import SRC_BURGER from '../../assets/img/burger.svg';
import SRC_CART from '../../assets/img/cart.svg';
import SRC_BACK from '../../assets/img/back.svg';

import SRC_NOTIMOBILE from '../../assets/img/mobile-bar/notifications.svg';
import SRC_PLUS from '../../assets/img/mobile-bar/add.svg';
import SRC_SWIPES from '../../assets/img/mobile-bar/swipes.svg';
import SRC_CARDS from '../../assets/img/mobile-bar/cards.svg';
import SRC_HEART from '../../assets/img/mobile-bar/heart.svg';
import SRC_FILTER from '../../assets/img/mobile-bar/filters.svg';
import SRC_SETTINGS from '../../assets/img/mobile-bar/settings.svg';
import SRC_SEARCH_MOBILE from '../../assets/img/mobile-bar/search.svg';

import SRC_SEARCH from '../../assets/img/icons/search.svg';
import SRC_MESSAGES from '../../assets/img/icons/messages.svg';
import SRC_NOTIFICATIONS from '../../assets/img/icons/notifications.svg';
import SRC_MAYA from '../../assets/img/icons/Maya.svg';

import { GetRouteSearchParams } from "../../helpers/GeneralHelper";


const Header = (props) => {

    const { match } = props;

    const { isModalSearch, setModalSearch } = props;
    const { isModalLocalisation, setModalLocalisation, setSelecting } = props;
    const { isModalGuide, setModalGuide } = props;
    const { isModalUser, setModalUser } = props;
    const { isModalPosts, setModalPosts } = props;
    const { isModalAvatar, setModalAvatar } = props;


    const onHandleUpload = (files) => {

        console.log("Files ==> ", files.base64);

        let BasedFinal = files.base64;
        if (BasedFinal) {
            setModalPosts(BasedFinal);
        }

        // let BasedFiles = files.base64 || [];
        // let BasedFinal = [];

        // for (var i = 0; i < BasedFiles.length; i++) {
        //     const BasedImage = BasedFiles[i];
        //    // console.log("BasedImage ==> ", BasedImage);

        //     BasedFinal.push({
        //         id: nanoid(),
        //         src: BasedImage,
        //         based: true
        //     })
        //     if (i === BasedFiles.length - 1) {
        //         //  alert(BasedFinal.length)
        //         // setUploadPhotos(BasedFinal)
        //         setModalPosts(BasedFinal);
        //     }
        // }
    }

    //Setup Counts
    const { CMC, NC } = props;

    const CART_COUNT = 0;
    const NOTIFICATION_COUNT = NC || 0;
    const INBOX_COUNT = CMC || 0;


    //Identify URLS
    let CURRENT_URL = match.url;

    let isHome = CURRENT_URL.includes('/home');
    let isSearch = CURRENT_URL.includes('/search');
    let isDashboard = CURRENT_URL.includes('/dashboard');
    let isInbox = CURRENT_URL.includes('/inbox');
    let isSettings = CURRENT_URL.includes('/settings');
    let isCalculator = CURRENT_URL.includes('/calculator');
    let isAircrafts = CURRENT_URL.includes('/aircrafts');
    let isNewFlight = CURRENT_URL.includes('/flights/new-flight');
    let isCourses = CURRENT_URL.includes('/courses');
    let isFlights = CURRENT_URL.includes('/flights');
    let isLogbook = CURRENT_URL.includes('/logbook');
    let isLicenses = CURRENT_URL.includes('/licenses');
    let isAirports = CURRENT_URL.includes('/airports');

    let isNotifications = CURRENT_URL.includes('/notifications');
    let isChallenges = CURRENT_URL.includes('/challenges');
    let isBookings = CURRENT_URL.includes('/bookings');
    let isReviews = CURRENT_URL.includes('/reviews');
    let isWallet = CURRENT_URL.includes('/wallet');
    let isEarnings = CURRENT_URL.includes('/earnings');

    let isServices = CURRENT_URL.includes('/services');

    let isPosts = CURRENT_URL.includes('/post');
    let isPublic = CURRENT_URL.includes('/public');

    let isGeneral = (!isHome && !isSearch && !isNewFlight && !isAircrafts && !isAirports && !isCalculator && !isLicenses && !isLogbook && !isFlights && !isCourses && !isDashboard && !isInbox && !isSettings && !isNotifications && !isChallenges && !isBookings)


    const sharedProps = {
        ...props,
        onHandleUpload
    }

    return (

        <>

            {isModalSearch && (
                <Search {...props}
                    onClose={() => setModalSearch(false)}

                />
            )}

            {isModalGuide && (
                <Guide {...props}
                    onClose={() => setModalGuide(false)}

                />
            )}

            {isModalLocalisation && (
                <Language {...props}
                    onClose={() => setModalLocalisation(false)}

                />
            )}

            {isModalUser && (
                <SidebarMobile {...props}
                    onClose={() => setModalUser(false)}

                />
            )}


            {/* {isTypeSearch && (
                <SearchType {...props}
                    onClose={() => setTypeSearch(false)}
                    onSelectType={(type) => onSelectType(type)}
                    currentType={type}

                />
            )}

            {isAvatarReady && (
                <Avatars {...props}
                    onClose={() => setAvatarReady(false)}

                />
            )} */}

            {/* Render Headers */}

            {isHome ? (
                <HomeHeader {...sharedProps} />

            ) : isSearch ? (
                <SearchHeader {...sharedProps} />

            ) : isDashboard ? (
                <DashboardHeader {...sharedProps} title={'Dashboard'} />

            ) : isAirports ? (
                <DashboardHeader {...sharedProps} title={'Airports'} />
    
            ) : isInbox ? (
                <InboxHeader {...sharedProps} />

            ) : isSettings ? (
                <SettingsHeader {...sharedProps} />

            ) : isNotifications ? (
                <NotificationsHeader {...sharedProps} />

            ) : isChallenges ? (
                <ChallengesHeader {...sharedProps} />

            ) : isBookings ? (
                <BookingsHeader {...sharedProps} />

            ) : isReviews ? (
                <ReviewsHeader {...sharedProps} />

            ) : isWallet ? (
                <WalletHeader {...sharedProps} />

            ) : isEarnings ? (
                <EarningsHeader {...sharedProps} />

            ) : isServices ? (
                <ServicesHeader {...sharedProps} />

            ) : isPosts ? (
                <PublicHeader {...sharedProps} title={'Posts'} />

            ) : isPublic ? (
                <PublicHeader {...sharedProps} title={'Profile'} />

            ) : isAircrafts ? (
                <TabsHeader {...sharedProps} title={'Aircrafts'} />
            ) : isCourses ? (
                <TabsHeader {...sharedProps} title={'Courses'} />
            ) : isFlights ? (
                <TabsButton {...sharedProps} title={'Flights'} button={'Add Flight'} />
            ) : isLogbook ? (
                <TabsButton {...sharedProps} title={'Logbook'} button={'Add Log'} />
            ) : isLicenses ? (
                <TabsButton {...sharedProps} title={'Licenses'} button={'Add License'} />
            ) : isCalculator ? (
                <DashboardHeader {...sharedProps} title={'Calculator'} />
            ) : isNewFlight ? (
                <BackHeader {...sharedProps} title={'New Flight'} />
            ) : (
                <></>
            )}

        </>

    )
}

//Headers


const HomeHeader = (props) => {

    const { match, history, LANGUAGE } = props;
    const { setModalAvatar, setModalPosts, onHandleUpload } = props;

    const onSelectItem = (item) => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        if (!JWT) {
            return history.replace('/' + LANGUAGE + '/login?next=' + match.url);
        }

        if (item === 'avatar') {
            return setModalAvatar(true)
        }
        else if (item === 'post') {
            return setModalPosts(true)
        }
    }


    return (
        <header className="py-4 md:py-8 w-full flex-shrink-0 bg-avioyx-200 z-50">
            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <nav className="relative z-50 flex justify-between text-sm">
                    <div className='flex items-center justify-start gap-x-2 lg:gap-x-6'>
                        <div onClick={() => history.push(`/${LANGUAGE}/home`)}>
                            <img className='w-[150px] mr-4' src={SRC_LOGO} alt="" />
                        </div>
                    </div>
                    <div className='flex items-center gap-x-4 md:gap-x-2'>

                        <ReactFileReader fileTypes={[".jpg", ".jpeg", ".png", ".gif"]} base64={true} multipleFiles={false} handleFiles={onHandleUpload} >
                            <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative'>
                                <img src={SRC_PLUS} alt="" />
                            </div>
                        </ReactFileReader>

                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative' onClick={() => history.push('/' + LANGUAGE + '/notifications')}>
                            <img src={SRC_NOTIMOBILE} alt="" />
                        </div>

                    </div>
                </nav>
            </div>
        </header>
    )

}

const SearchHeader = (props) => {

    const { match, history, location, LANGUAGE } = props;

    const routeParams = GetRouteSearchParams(location.search);
    const routeEx = routeParams.ex || 'cards';

    if (match.url.includes('/booking')) {
        return <></>
    }

    if (match.params.code) {
        return (
            <header className="px-0 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-b border-zinc-200 ">
                <div className="flex" onClick={() => history.goBack()}>
                    <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
                    <div className="text-lg ml-2 font-semibold capitalize">
                        {match.params.code.replace('-', ' ')}
                    </div>
                </div>
                <div className='flex items-center gap-x-4 md:gap-x-2'>

                    {match.params.city && (
                        <>
                            {routeEx === 'cards' ? (
                                <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative' onClick={() => history.push('?ex=swipes')}>
                                    <img src={SRC_SWIPES} alt="" />
                                </div>
                            ) : (
                                <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative' onClick={() => history.push(`?ex=cards`)}>
                                    <img src={SRC_CARDS} alt="" />
                                </div>
                            )}

                            <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative'>
                                <img src={SRC_FILTER} alt="" />
                            </div>
                        </>
                    )}

                    {match.url.includes('/provider') && (
                        <>
                            <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative' onClick={() => history.push(`/${LANGUAGE}/search/${match.params.code}/provider/${match.params.id}/chat`)}>
                                <img src={SRC_CHAT} alt="" className="w-[30px] h-[30px]" />
                            </div>
                            {/* <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative' onClick={()=> history.push(`?heart=true`)}>
                                <img src={SRC_HEART} alt="" />
                            </div> */}
                        </>
                    )}

                </div>
            </header>
        )
    }

    return (
        <header className="py-4 md:py-8 w-full flex-shrink-0 bg-avioyx-200 z-50">
            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <nav className="relative z-50 flex justify-between text-sm">
                    <div className='flex items-center justify-start gap-x-2 lg:gap-x-6'>
                        <div onClick={() => history.push(`/${LANGUAGE}/home`)}>
                            <img className='w-[150px] mr-4' src={SRC_LOGO} alt="" />
                        </div>
                    </div>
                    <div className='flex items-center gap-x-4 md:gap-x-2'>

                        {/* <div className='w-[36px] h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative'>
                            <img src={SRC_SWIPES} alt="" />
                        </div>

                        <div className='w-[36px] h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative'>
                            <img src={SRC_CARDS} alt="" />
                        </div> */}

                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative'>
                            <img src={SRC_FILTER} alt="" />
                        </div>

                    </div>
                </nav>
            </div>
        </header>
    )

}

const DashboardHeader = (props) => {

    const { match, history, LANGUAGE, setModalPosts, setSelecting, title } = props;

    let CURRENT_URL = match.url;
    if (CURRENT_URL.includes('/challenges')) {
        return (
            <ChallengesHeader {...props} isDashboard={true} />
        )
    }
    else if (CURRENT_URL.includes('/services')) {
        return (
            <ServicesHeader {...props} isDashboard={true} />
        )
    }
    else if (CURRENT_URL.includes('/wallet')) {
        return (
            <WalletHeader {...props} isDashboard={true} />
        )
    }
    else if (CURRENT_URL.includes('/earnings')) {
        return (
            <EarningsHeader {...props} isDashboard={true} />
        )
    }
    else if (CURRENT_URL.includes('/reviews')) {
        return (
            <ReviewsHeader {...props} isDashboard={true} />
        )
    }

    return (
        <header className="py-8 w-full flex-shrink-0  z-50 sticky top-0 bg-gradient-to-b from-avioyx-200 via-avioyx-200 to-transparent">
            
                <nav className="relative z-50 flex justify-between items-center text-sm">
                    <div className="text-xl font-semibold">{title}</div>
                    <div className='flex items-center gap-x-4'>

                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_SEARCH} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_NOTIFICATIONS} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_MESSAGES} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_MAYA} alt="" />
                        </div>

                    </div>
                </nav>

        </header>
    )

}

const InboxHeader = (props) => {

    const { match, history, LANGUAGE } = props;

    return (
        <header className="py-4 md:py-8 w-full flex-shrink-0 bg-white z-50">
            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <nav className="relative z-50 flex justify-between text-sm">
                    <div className='flex items-center justify-start gap-x-2 lg:gap-x-6'>
                        <div onClick={() => history.push(`/${LANGUAGE}/home`)}>
                            <img className='w-[150px] mr-4' src={SRC_LOGO} alt="" />
                        </div>
                    </div>
                    <div className='flex items-center gap-x-4 md:gap-x-2'>

                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative'>
                            <img src={SRC_SEARCH_MOBILE} alt="" />
                        </div>

                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative'>
                            <img src={SRC_FILTER} alt="" />
                        </div>

                    </div>
                </nav>
            </div>
        </header>
    )

}

const SettingsHeader = (props) => {

    const { match, history, LANGUAGE } = props;

    return (
        <header className="py-4 md:py-8 w-full flex-shrink-0 bg-white z-50">
            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <nav className="relative z-50 flex justify-between text-sm">
                    <div className='flex items-center justify-start gap-x-2 lg:gap-x-6'>
                        <div onClick={() => history.push(`/${LANGUAGE}/home`)}>
                            <img className='w-[150px] mr-4' src={SRC_LOGO} alt="" />
                        </div>
                    </div>
                    <div className='flex items-center gap-x-4 md:gap-x-2'>

                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-zinc-100 relative'>
                            <img src={SRC_SETTINGS} alt="" />
                        </div>

                    </div>
                </nav>
            </div>
        </header>
    )

}

const NotificationsHeader = (props) => {

    const { history } = props;

    return (
        <header className="px-4 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-b border-zinc-200">
            <div className="flex" onClick={() => history.goBack()}>
                <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
                <div className="text-lg ml-2 font-semibold">Your Notifications</div>
            </div>
        </header>
    )
}

const ServicesHeader = (props) => {

    const { match, history, isDashboard } = props;

    return <></>

    // if (isDashboard && match.params.id) {
    //     return (
    //         <div className="h-[70px] w-full flex px-3 items-center justify-start flex-shrink-0 bg-white border-b border-zinc-200 from-top">
    //             <img className="mr-2" src={SRC_BACK} alt="" onClick={() => history.goBack()} />
    //             <div className="font-bold">Service</div>
    //             <div className='flex items-center ml-auto text-sm'>
    //                 <button className='bg-slate-100 rounded-full text-black px-4 py-3 mr-2'>Back</button>
    //                 <button className='bg-green-500 rounded-full text-white px-4 py-3'>Participate</button>
    //             </div>
    //         </div>
    //     )
    // }

    // return (
    //     <header className="px-4 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-b border-zinc-200 from-top">
    //         <div className="flex" onClick={() => history.goBack()}>
    //             <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
    //             <div className="text-lg ml-2 font-semibold">Your Services</div>
    //         </div>
    //     </header>
    // )
}

const ChallengesHeader = (props) => {

    const { match, history, isDashboard } = props;

    if (isDashboard && match.params.id) {
        return (
            <div className="h-[70px] w-full flex px-3 items-center justify-start flex-shrink-0 bg-white border-b border-zinc-200">
                <img className="mr-2" src={SRC_BACK} alt="" onClick={() => history.goBack()} />
                <div className="font-bold">Challenge</div>
                <div className='flex items-center ml-auto text-sm'>
                    <button className='bg-slate-100 rounded-full text-black px-4 py-3 mr-2'>Back</button>
                    <button className='bg-green-500 rounded-full text-white px-4 py-3'>Participate</button>
                </div>
            </div>
        )
    }

    if (match.params.id) {
        return (
            <header className="px-4 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-b border-zinc-200">
                <div className="flex" onClick={() => history.goBack()}>
                    <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
                    <div className="text-lg ml-2 font-semibold">Challenges</div>
                </div>
            </header>
        )
    }
    return (
        <header className="px-4 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-b border-zinc-200">
            <div className="flex" onClick={() => history.goBack()}>
                <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
                <div className="text-lg ml-2 font-semibold">Your Challenges</div>
            </div>
        </header>
    )
}

const BookingsHeader = (props) => {

    const { match, history, isDashboard } = props;

    if (isDashboard && match.params.id) {
        return (
            <div className="h-[70px] w-full flex px-3 items-center justify-start flex-shrink-0 bg-white border-b border-zinc-200">
                <img className="mr-2" src={SRC_BACK} alt="" onClick={() => history.goBack()} />
                <div className="font-bold">Booking</div>
                <div className='flex items-center ml-auto text-sm'>
                    <button className='bg-slate-100 rounded-full text-black px-4 py-3 mr-2'>Deny</button>
                    <button className='bg-green-500 rounded-full text-white px-4 py-3'>Approve</button>
                </div>
            </div>
        )
    }

    return (
        <header className="px-4 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-b border-zinc-200">
            <div className="flex" onClick={() => history.goBack()}>
                <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
                <div className="text-lg ml-2 font-semibold">Your Bookings</div>
            </div>
        </header>
    )
}

const ReviewsHeader = (props) => {

    const { match, history, isDashboard } = props;

    if (isDashboard && match.params.id) {
        return (
            <div className="h-[70px] w-full flex px-3 items-center justify-start flex-shrink-0 bg-white border-b border-zinc-200">
                <img className="mr-2" src={SRC_BACK} alt="" onClick={() => history.goBack()} />
                <div className="font-bold">Review</div>
                <div className='flex items-center ml-auto text-sm'>
                    <button className='bg-slate-100 rounded-full text-black px-4 py-3 mr-2'>Close</button>
                    <button className='bg-green-500 rounded-full text-white px-4 py-3'>Share</button>
                </div>
            </div>
        )
    }

    return (
        <header className="px-4 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-b border-zinc-200">
            <div className="flex" onClick={() => history.goBack()}>
                <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
                <div className="text-lg ml-2 font-semibold">Your Reviews</div>
            </div>
        </header>
    )
}

const WalletHeader = (props) => {

    const { history, match, location } = props;

    const walletDetail = match.params.id;



    if (walletDetail) {
        if (walletDetail === 'credits') {
            return (
                <header className="px-4 flex-shrink-0 w-full bg-slate-100 z-50 h-[70px] flex items-center justify-between border-zinc-200">
                    <div className="flex" onClick={() => history.goBack()}>
                        <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
                        <div className="text-lg ml-2 font-semibold">Avioyx Credits</div>
                    </div>
                </header>
            )
        }
        else if (walletDetail === 'add') {

            const routeParams = GetRouteSearchParams(location.search) || {};
            const routeType = routeParams.type || '';

            return (
                <header className="px-4 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-b border-zinc-200">
                    <div className="flex" onClick={() => history.goBack()}>
                        <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
                        {routeType === 'gifts' ? (
                             <div className="text-lg ml-2 font-semibold">Add PIN/Gift Code</div>
                        ) : routeType === 'cards' ? (
                            <div className="text-lg ml-2 font-semibold">Add Card</div>
                        ) : (
                            <div className="text-lg ml-2 font-semibold">Add Payment Method</div>
                        )}
                    </div>
                </header>
            )
        }
        else {
            return (
                <header className="px-4 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-zinc-200">
                    <div className="flex" onClick={() => history.goBack()}>
                        <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
                        <div className="text-lg ml-2 font-semibold"></div>
                    </div>
                </header>
            )
        }
    }

    return (
        <header className="px-4 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-b border-zinc-200">
            <div className="flex" onClick={() => history.goBack()}>
                <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />

                <div className="text-lg ml-2 font-semibold">Your Wallet</div>

            </div>
        </header>
    )
}

const EarningsHeader = (props) => {

    const { history } = props;

    return (
        <header className="px-4 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-b border-zinc-200">
            <div className="flex" onClick={() => history.goBack()}>
                <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
                <div className="text-lg ml-2 font-semibold">Your Earnings</div>
            </div>
        </header>
    )
}

const PublicHeader = (props) => {

    const { history, title } = props;

    return (
        <header className="px-4 flex-shrink-0 w-full bg-white z-50 h-[70px] flex items-center justify-between border-b border-zinc-200">
            <div className="flex" onClick={() => history.goBack()}>
                <img className="cursor-pointer h-[30px]" src={SRC_BACK} alt="" />
                <div className="text-lg ml-2 font-semibold">
                    {title}
                </div>
            </div>
        </header>
    )
}

const TabsHeader = (props) => {

    const { match, history, LANGUAGE, setModalPosts, setSelecting, title } = props;

 

    return (
        <header className="pt-8 mb-8 w-full flex-shrink-0  z-50 sticky top-0 bg-avioyx-200">
            
                <nav className="relative z-50 flex items-center justify-between text-sm">
                    <div className="text-xl font-semibold">{title}</div>
                    <div className='flex items-center gap-x-4'>

                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_SEARCH} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_NOTIFICATIONS} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_MESSAGES} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_MAYA} alt="" />
                        </div>

                    </div>
                </nav>
                <div className="w-full border-b border-avioyx-100 mt-8 flex gap-x-8">
                    <div className="py-3 cursor-pointer -mb-px border-b border-sky-500">Tab Active</div>
                    <div className="py-3 cursor-pointer -mb-px hover:border-b hover:border-sky-500 text-gray-400 hover:text-white">Tab Default</div>
                </div>

        </header>
    )

}

const TabsButton = (props) => {

    const { match, history, LANGUAGE, setModalPosts, setSelecting, title, button } = props;

 

    return (
        <header className="py-8 w-full flex-shrink-0  z-50 sticky top-0 bg-gradient-to-b from-avioyx-200 via-avioyx-200 to-transparent">
            
                <nav className="relative z-50 flex justify-start items-center text-sm">
                    <div className="text-xl font-semibold">{title}</div>
                    <button className="px-4 py-2 bg-sky-500 rounded-lg mr-auto ml-4 hover:bg-sky-300"><span className="mr-2">+</span>{button}</button>
                    <div className='flex items-center gap-x-4'>

                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_SEARCH} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_NOTIFICATIONS} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_MESSAGES} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_MAYA} alt="" />
                        </div>

                    </div>
                </nav>

        </header>
    )

}

const BackHeader = (props) => {

    const { match, history, LANGUAGE, setModalPosts, setSelecting, title } = props;

 

    return (
        <header className="py-8 w-full flex-shrink-0  z-50 sticky top-0 bg-gradient-to-b from-avioyx-200 via-avioyx-200 to-transparent">
            
                <nav className="relative z-50 flex justify-start items-center text-sm">
                    <img src={SRC_BACK} className="mr-2" />
                    <div className="text-xl font-semibold">{title}</div>
                    
                    <div className='flex items-center gap-x-4'>

                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_SEARCH} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_NOTIFICATIONS} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_MESSAGES} alt="" />
                        </div>
                        <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex  items-center justify-center hover:bg-avioyx-50 relative'>
                            <img src={SRC_MAYA} alt="" />
                        </div>

                    </div>
                </nav>

        </header>
    )

}

export default Header