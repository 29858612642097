import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import AuthFormLogin from './AuthFormLogin';

import SRC_DOG_1 from './dog1.jpg';
import SRC_DOG_2 from './dog2.jpg';
import SRC_LOGO from '../../assets/img/logo-white.svg';
import AuthFormSignup from './AuthFormSignup';

const AuthLogin = (props) => {

    const { TRANSLATE, LANGUAGE } = props;

    return (
        <>

            <Helmet prioritizeSeoTags>

                <html lang={LANGUAGE} />

                <title>{'Avioyx.app | ' + TRANSLATE.wd_signup}</title>

            </Helmet>

            <div className="w-full h-full fixed top-0 left-0 bg-avioyx-100 z-50">
                <div className="mx-auto max-w-[320px] flex flex-col h-full md:flex-nowrap items-center justify-between">

                    <img className='w-32 mt-10 md:mb-0 mb-10 from-top' src={SRC_LOGO} alt='' />
                   
                    <div className='relative'>
                        <img className='scale-in h-[400px] w-[300px] object-cover rounded-3xl absolute -right-3 drop-shadow-2xl -top-6 rotate-6' src={SRC_DOG_2} alt='' />
                        <img className='scale-in h-[400px] w-[300px] z-20 object-cover rounded-3xl absolute -left-3 drop-shadow-2xl top-6 -rotate-6' src={SRC_DOG_1} alt=''/>

                        <AuthFormSignup {...props}
                            ContainerClass="scale-in flex flex-col w-full bg-white rounded-3xl relative z-40 overflow-hidden"
                            ContainerStyles={{ boxShadow: '0 25px 25px rgb(0 0 0 / 0.15)' }}
                            AsterixClass="text-avioyx-100"
                            ButtonClass="btn-auto ml-auto px-3 py-4 w-full text-center flex justify-center"
                            ButtonsClass="w-full flex items-center justify-between flex-col"
                            GoogleClass="btn-blank px-3 w-full flex justify-center items-center"
                            OriginFrom="root"
                        />
                    </div>

                    <p className="text-sm my-6 relative z-20 scale-in">{TRANSLATE.auth_login.c}{' '}
                        <Link className="text-white" to={'/' + LANGUAGE + '/recovery'}>{TRANSLATE.wd_click_here}</Link>
                    </p>
                </div>
            </div>
        </>
    )
}

export default AuthLogin

