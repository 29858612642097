import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';

import SRC_LOGO_ICON from '../../assets/img/logo_icon.png';
import SRC_CALENDAR from '../../assets/img/icons/calendar.svg';
import SRC_TIME from '../../assets/img/icons/time.svg';
import SRC_AIRCRAFTS from '../../assets/img/icons/aircrafts.svg';
import SRC_SEARCH from '../../assets/img/icons/search.svg';
import SRC_MAP from '../../assets/img/icons/map-placeholder.png';

import SRC_DEPARTURE from '../../assets/img/icons/departure.svg';
import SRC_ARRIVAL from '../../assets/img/icons/arrival.svg';

const NewFlight = (props) => {

    const {  LANGUAGE, TRANSLATE, homelang } = props;

    return (
        <>
            <HeaderSEO {...props}
                LANGUAGE={homelang || LANGUAGE}

            />

            <div className='w-full mt-10'>
                <div className='w-full flex'>
                    <div className='w-full input-group with-icon border-r mb-0 border-black'>
                        <img className='input-icon' src={SRC_DEPARTURE} />
                        <input className='!rounded-tr-none !rounded-br-none' type='text' placeholder='Departure airport' />
                    </div>
                    <div className='w-full input-group with-icon mb-0'>
                        <img className='input-icon' src={SRC_ARRIVAL} />
                        <input className='!rounded-tl-none !rounded-bl-none' type='text' placeholder='Arrival airport' />
                    </div>
                    <button className="px-8 py-1 bg-sky-500 rounded-lg mr-auto ml-4 hover:bg-sky-600 flex-shrink-0">Plan Flight</button>
                </div>
                <div className='w-full flex mt-3 gap-x-3'>
                    <div className='w-full input-group with-icon mb-0'>
                        <img className='input-icon' src={SRC_AIRCRAFTS} />
                        <input className=' ' type='text' placeholder='Aircraft' />
                    </div>
                    <div className='w-full input-group with-icon mb-0'>
                        <img className='input-icon' src={SRC_TIME} />
                        <input className=' ' type='text' placeholder='Scheduled Departure' />
                    </div>
                    <div className='w-full input-group with-icon mb-0'>
                        <img className='input-icon' src={SRC_TIME} />
                        <input className=' ' type='text' placeholder='Scheduled Arrival' />
                    </div>
                    
                </div>

                <div className='w-full h-[500px] rounded-lg overflow-hidden mt-10'>
                    <img src={SRC_MAP} className='w-full h-full object-cover' />
                </div>

            </div>
        </>
    )
}


const HeaderSEO = (props) => {

    const { TRANSLATE, LANGUAGE } = props;

    const routeUrl = 'https://www.avioyx.com';
    const shareUrl = routeUrl + '/' + LANGUAGE;
    const imageUrl = routeUrl + "/assets/img/landing/hero.jpg";

    return (

        <Helmet prioritizeSeoTags>

            <html lang={LANGUAGE} />

            <title>{TRANSLATE.seo_title}</title>
            <meta name="description" content={TRANSLATE.seo_description} />

            <meta itemprop="name" content={TRANSLATE.seo_title} />
            <meta itemprop="description" content={TRANSLATE.seo_description} />
            <meta itemprop="image" content={imageUrl} />

            <link rel="canonical" href={routeUrl} />
            <link rel="alternate" href={routeUrl + '/en'} hrefLang='x-default' />
            <link rel="alternate" href={routeUrl + '/it'} hrefLang='it' />
            <link rel="alternate" href={routeUrl + '/fr'} hrefLang='fr' />
            <link rel="alternate" href={routeUrl + '/es'} hrefLang='es' />
            <link rel="alternate" href={routeUrl + '/de'} hrefLang='de' />
            <link rel="alternate" href={routeUrl + '/ar'} hrefLang='ar' />
            <link rel="alternate" href={routeUrl + '/ja'} hrefLang='ja' />
            <link rel="alternate" href={routeUrl + '/zh'} hrefLang='zh' />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@publisher_handle" />
            <meta name="twitter:title" content={TRANSLATE.seo_title} />
            <meta name="twitter:description" content={TRANSLATE.seo_description} />
            <meta name="twitter:creator" content="@avioyx" />
            <meta name="twitter:image:src" content={imageUrl} />

            <meta property="og:title" content={TRANSLATE.seo_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={shareUrl} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:description" content={TRANSLATE.seo_description} />
            <meta property="og:site_name" content="avioyx" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta property='fb:app_id' content='236904211103455' />

            <meta itemprop="thumbnailUrl" content={imageUrl} />
            <meta itemprop="image_src" content={imageUrl} />
            <meta itemprop="image" content={imageUrl} />

        </Helmet>
    )
}



export default NewFlight