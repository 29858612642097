import React, {useEffect} from 'react';

const AuthLogout = (props) => {

    const {history, TRANSLATE, onStartUser} = props;

    useEffect(()=> {

        setTimeout(()=> {

            localStorage.removeItem('jwt_token');
            localStorage.removeItem('cart');
            localStorage.removeItem('ano');
            
            onStartUser('reset');

            const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
            if(isNativeApp){
                const webData = {
                    type: 'LOGOUT',
                    data: null
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(webData));
            }
            
            history.push('/'+TRANSLATE.lang);
        
        }, 1000)
        
    },[])

    return( 
        <div className="overflow-auto h-full pt-8">
            <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
                <div className='avioyx-circle'>
                    <div className='avioyx-paw-small left' />
                    <div className='avioyx-paw-small right' />
                    <div className='avioyx-paw-big left' />
                    <div className='avioyx-paw-big right' />
                </div>
            </div>
        </div>
    )


}


export default AuthLogout