import React from "react";

const LearnDetails = () => {
  return (
    <>
      <h1>PLACEHOLDER</h1>
    </>
  )
}

export default LearnDetails