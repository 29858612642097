import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { connect } from 'react-redux';
import { onUpdateLanguage } from '../../actions';

import Header from "../General/Header.js"
import Footer from "../General/Footer.js"

import AuthLogin from '../Auth/AuthLogin';
import AuthSignup from '../Auth/AuthSignup';
import AuthRecovery from '../Auth/AuthRecovery';
import AuthLogout from '../Auth/AuthLogout'; 

const AuthContainer = (props) => {

  const { TRANSLATE, USER, CART, LANGUAGE, onChangeLanguage, onFetchUser, onFacebookTracking, onKlaviyoTracking, onFetchAnalytics, setOverflow, location, match } = props;

  const sharedProps = {
    ...props,
    TRANSLATE,
    USER,
    CART,
    LANGUAGE,
    onChangeLanguage,
    onFetchUser,
    onFacebookTracking,
    onKlaviyoTracking,
    setOverflow
  }

  useEffect(() => {

    const language = match.params.lang;
    const langArray = ['en', 'it', 'fr', 'es'];

    if(langArray.includes(language)){
      onChangeLanguage(language);
    }
    else{
      const LANG = localStorage.getItem('language') || 'en';
      onChangeLanguage(LANG);
    }

  }, [match.params.lang]);


  useEffect(() => {
    onFetchAnalytics({ location });
    onFacebookTracking('pageView');

    window.scrollTo(0, 0);

  }, [match.url])

  const { isModalCart, isModalSearch, isModalLocalisation, isModalUser, isModalProvider, isModalGuide, isModalCity } = props;

  useEffect(() => {

    if (isModalCart || isModalSearch || isModalLocalisation || isModalUser || isModalProvider || isModalGuide || isModalCity) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isModalCart, isModalSearch, isModalLocalisation, isModalUser, isModalProvider, isModalGuide, isModalCity, match.params])

  return (
    <>
      <Header {...sharedProps} />

      <Switch>

        <Route exact path="/:lang/login" render={(props) => <AuthLogin {...sharedProps} {...props} />} />
        <Route exact path="/:lang/signup" render={(props) => <AuthSignup {...sharedProps} {...props} />} />
        <Route exact path="/:lang/recovery" render={(props) => <AuthRecovery {...sharedProps} {...props} />} />
        <Route exact path="/:lang/logout" render={(props) => <AuthLogout {...sharedProps} {...props} />} />

      </Switch>

      {/* <Footer {...sharedProps} /> */}

    </>

  )


}


const mapStateToProps = state => {
  return ({
    USER: state.user,
    CART: state.cart
  });
}

const mapDispatchToProps = dispatch => ({
  onChangeLanguage: (lang) => dispatch(onUpdateLanguage(lang))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthContainer);
