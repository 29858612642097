import React, { useEffect, useRef, useState } from "react";

import axios from "axios";

import SRC_CLOSE from '../../assets/img/close.svg';
import SRC_LOCATION from '../../assets/img/location.svg';
import SRC_CHECK from '../../assets/img/check.svg';
import SRC_LOADER from '../../assets/img/loader-white.png';
import SRC_CURRENT_LOCATION from '../../assets/img/current-location.svg';

import ReactCountryFlag from "react-country-flag";
import { onFormatAddress } from "../../helpers/GeneralHelper";


const Search = (props) => {

    const myRef = useRef();

    const { onClose, TYPE, PLACEHOLDER } = props;

    const [isLoading, setLoading] = useState(false);
    const [isSearching, setSearching] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [Location, setLocation] = useState('');
    const [Locations, setLocations] = useState([]);
    const [debouncedSearch, setDebouncedSearch] = useState(null);

    const [SavedLocations, setSavedLocations] = useState([]);

    useEffect(() => {
        if (myRef.current) {
            myRef.current.focus()
        }
    }, [])

    useEffect(()=> {
        const savedLocations = localStorage.getItem(`${TYPE}Saved`);
        if(savedLocations){
            const parsedLocations = JSON.parse(savedLocations) || [];
            setSavedLocations(parsedLocations);
        }
    }, [])

    // Function to fetch nearby places from OpenStreetMap API
    const onFetchLocations = async (query) => {
        setLoading(true);
        const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${query}`;
        try {
            const response = await axios.get(apiUrl);

            console.log("RESPONSE ===> ", response.data);
            setLoading(false);
            return response.data.map((place) => ({
                label: place.display_name,
                latitude: place.lat,
                longitude: place.lon,
                ...place
            }));
        } catch (error) {
            console.error('Error fetching nearby places:', error);
            setLoading(false);
            return [];
        }
    };

    const onFetchLocation = () => {

        setSearching(true);

        if ("geolocation" in navigator) {

            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;

                    try {
                        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

                        const response = await fetch(url);
                        const data = await response.json();

                        console.log("HERE ===> ", data);

                        setLocation(data);
                        setSearching(false);
                    } catch (error) {
                        console.error("Error fetching location data:", error);
                        setSearching(false);
                    }
                },
                (error) => {
                    console.error("Error getting geolocation:", error);
                    setSearching(false);
                }
            );
        } else {
            console.log("Geolocation is not available in this browser.");
            setSearching(false);
        }
    }

    // Function to handle the input change and fetch nearby places
    const onChangeLocation = async (value) => {
        setInputValue(value);
        if (!value || (value && value.length < 3)) {
            return
        }


        clearTimeout(debouncedSearch); // Clear the previous debounce timeout
        const timeoutId = setTimeout(async () => {
            const nearbyPlaces = await onFetchLocations(value);
            setLocations(nearbyPlaces);
            console.log("nearbyPlaces ===> ", nearbyPlaces);
        }, 500); // Adjust the delay time as needed

        setDebouncedSearch(timeoutId); // Store the new debounce timeout


    };

    const onSelectLocation = (location) => {

        if(SavedLocations.findIndex((item)=> item.place_id === location.place_id) == -1){
            localStorage.setItem(`${TYPE}Saved`,JSON.stringify([location, ...SavedLocations]))
        } 
        setLocation(location);
        props.onSelectLocation(location);
        onClose()
    }

    // Find Current Location

    const dataAddress = Location?.address || {}
    const dataLabel = Location?.display_name || '';
    const city = dataAddress.city;
    const country = dataAddress.country;
    const country_code = dataAddress.country_code;
    

    return ( 

        <div className="fixed w-screen h-screen top-0 left-0 z-[10000] flex items-end md:items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]">
            <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" onClick={() => onClose()} />

            <div className="mx-auto w-full max-w-[700px] overflow-auto md:max-h-[calc(100vh-100px)] max-h-[calc(100vh-50px)] pb-4 md:pb-10 bg-white md:rounded-2xl rounded-0 rounded-tr-2xl rounded-tl-2xl relative z-20 drawer-in" style={{ height: '92%' }}>

                <header className="py-4 w-full sticky top-0 bg-white fixed border-b" style={{ zIndex: 9999 }}>
                    <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-3 lg:pt-1">
                        <nav className="relative z-50 flex justify-between text-sm">
                            <div className='flex w-full items-center jusity-between gap-x-2 lg:gap-x-6'>
                                <div className="input-group with-icon w-full md:w-1/2 pr-2 md:z-50 z-[9999] mb-0">
                                    <img className="input-icon bottom-[12px]" src={SRC_LOCATION} />
                                    <span className="inner-label">Location</span>
                                    <input type="text" placeholder={PLACEHOLDER}
                                        value={inputValue}
                                        onChange={(e) => onChangeLocation(e.target.value)}
                                        autoComplete="new-password"
                                        ref={myRef}

                                    />

                                    {isLoading && (
                                        <div className="w-[20px] h-[20px] rounded-full bg-avioyx-100 hover:bg-avioyx-200 cursor-pointer flex justify-center items-center absolute top-[15px] right-[20px] md:right-[25px] z-30">
                                            <img className='animate-spin w-[10px] h-[10px]' src={SRC_LOADER} />
                                        </div>
                                    )}

                                </div>
                                <div className='w-[36px] h-[36px] rounded-lg cursor-pointer flex items-center justify-center bg-zinc-100 relative' onClick={() => onClose()}>
                                    <img src={SRC_CLOSE} alt="" />
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>

                <div className="w-full flex flex-col items-start mb-8 gap-4 px-4 mt-4 overflow-auto">

                    {Locations.length == 0 ? (
                        <>
                            <div className="w-full text-sm p-3 flex items-center justify-between border-b border-zinc-200 cursor-pointer hover:bg-zinc-100" onClick={() => onFetchLocation()}>
                               
                               <div className="flex">
                                    <img src={SRC_CURRENT_LOCATION} alt="" className="w-[24px] h-[24px] mr-2" />
                                    <div className="text-lg">Current Location</div>
                                </div>
                               
                                {isSearching ? (
                                    <div className="w-[20px] h-[20px] rounded-full bg-avioyx-100 hover:bg-avioyx-200 cursor-pointer flex justify-center items-center z-30">
                                        <img className='animate-spin w-[10px] h-[10px]' src={SRC_LOADER} />
                                    </div>
                                ) : (
                                    <>
                                        {Location && (
                                            <div className="w-[20px] h-[20px] rounded-full bg-green-700 hover:bg-green-700 cursor-pointer flex justify-center items-center z-30">
                                                <img className='w-[10px] h-[10px]' src={SRC_CHECK} style={{ filter: 'brightness(0) invert(1)' }} />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>


                            {Location && (
                                <div className="w-full flex flex-col items-center justify-center text-center py-20">
                                    <ReactCountryFlag svg countryCode={country_code} className="w-[40px]" />
                                    <div className="font-semibold text-lg">{city}, {country}</div>
                                    <div className="text-sm">{dataLabel}</div>

                                    <button className="btn-auto mt-4" onClick={()=> onSelectLocation(Location)}>Use Location</button>
                                </div>
                            )}

                            {SavedLocations.map((item, index) => {

                                const label = onFormatAddress(item)

                                return (

                                    <div className="w-full text-sm p-3 flex items-center border-b border-zinc-200 cursor-pointer hover:bg-zinc-100" key={index} onClick={() => onSelectLocation(item)}>
                                        <img src={SRC_LOCATION} alt="" className="mr-1" />
                                        {TYPE === 'city' ? `${label}` : `${item.label}`}
                                    </div>
                                )

                            })}

                            
                        </>
                    ) : (
                        <>
                            {Locations.map((item, index) => {

                                return (

                                    <div className="w-full text-sm p-3 flex items-center border-b border-zinc-200 cursor-pointer hover:bg-zinc-100" key={index} onClick={() => onSelectLocation(item)}>
                                        <img src={SRC_LOCATION} alt="" className="mr-1" />
                                        {item.label}
                                    </div>
                                )

                            })}
                        </>
                    )}


                </div>

            </div>

        </div>

    )


}



export default Search