import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';

import SRC_LOGO_ICON from '../../assets/img/logo_icon.png';
import SRC_CALENDAR from '../../assets/img/icons/calendar.svg';

const Licenses = (props) => {

    const {  LANGUAGE, TRANSLATE, homelang } = props;

    return (
        <>
            <HeaderSEO {...props}
                LANGUAGE={homelang || LANGUAGE}

            />

            <div className='w-full grid gap-4 grid-cols-3'>
                <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50'> 
                    <div className='text-xl font-semibold'>PPL</div>
                    <div className='text-base font-light'>Private Pilot License </div>
                    <div className='flex justify-between pt-6 mt-6'>
                        <div className='flex items-center text-xs opacity-50'>
                            <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                            01/12/2023
                        </div>
                        <div className='text-xs px-4 py-1 bg-red-500 rounded-full'>Expiring in 2 days</div>
                    </div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50'> 
                    <div className='text-xl font-semibold'>CPL</div>
                    <div className='text-base font-light'>Commercial Pilot License </div>
                    <div className='flex justify-between pt-6 mt-6'>
                        <div className='flex items-center text-xs opacity-50'>
                            <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                            01/12/2023
                        </div>
                    </div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50'> 
                    <div className='text-xl font-semibold'>IR</div>
                    <div className='text-base font-light'>Instrument Rating</div>
                    <div className='flex justify-between pt-6 mt-6'>
                        <div className='flex items-center text-xs opacity-50'>
                            <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                            01/12/2023
                        </div>
                    </div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50'> 
                    <div className='text-xl font-semibold'>FIC</div>
                    <div className='text-base font-light'>Flight Instructor Certificate </div>
                    <div className='flex justify-between pt-6 mt-6'>
                        <div className='flex items-center text-xs opacity-50'>
                            <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                            01/12/2023
                        </div>
                    </div>
                </div>
                <div className='rounded-lg bg-avioyx-100 text-gray-400 p-4 cursor-pointer hover:bg-avioyx-50 text-sm flex items-center justify-center flex-col'>
                    <div className='font-light text-xl mb-2 flex items-center justify-center rounded-full border border-gray-400 w-[24px] h-[24px]'>
                        <span className='-translate-y-[1px]'>+</span>
                    </div>
                    Add License
               </div>
            </div>
        </>
    )
}


const HeaderSEO = (props) => {

    const { TRANSLATE, LANGUAGE } = props;

    const routeUrl = 'https://www.avioyx.com';
    const shareUrl = routeUrl + '/' + LANGUAGE;
    const imageUrl = routeUrl + "/assets/img/landing/hero.jpg";

    return (

        <Helmet prioritizeSeoTags>

            <html lang={LANGUAGE} />

            <title>{TRANSLATE.seo_title}</title>
            <meta name="description" content={TRANSLATE.seo_description} />

            <meta itemprop="name" content={TRANSLATE.seo_title} />
            <meta itemprop="description" content={TRANSLATE.seo_description} />
            <meta itemprop="image" content={imageUrl} />

            <link rel="canonical" href={routeUrl} />
            <link rel="alternate" href={routeUrl + '/en'} hrefLang='x-default' />
            <link rel="alternate" href={routeUrl + '/it'} hrefLang='it' />
            <link rel="alternate" href={routeUrl + '/fr'} hrefLang='fr' />
            <link rel="alternate" href={routeUrl + '/es'} hrefLang='es' />
            <link rel="alternate" href={routeUrl + '/de'} hrefLang='de' />
            <link rel="alternate" href={routeUrl + '/ar'} hrefLang='ar' />
            <link rel="alternate" href={routeUrl + '/ja'} hrefLang='ja' />
            <link rel="alternate" href={routeUrl + '/zh'} hrefLang='zh' />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@publisher_handle" />
            <meta name="twitter:title" content={TRANSLATE.seo_title} />
            <meta name="twitter:description" content={TRANSLATE.seo_description} />
            <meta name="twitter:creator" content="@avioyx" />
            <meta name="twitter:image:src" content={imageUrl} />

            <meta property="og:title" content={TRANSLATE.seo_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={shareUrl} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:description" content={TRANSLATE.seo_description} />
            <meta property="og:site_name" content="avioyx" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta property='fb:app_id' content='236904211103455' />

            <meta itemprop="thumbnailUrl" content={imageUrl} />
            <meta itemprop="image_src" content={imageUrl} />
            <meta itemprop="image" content={imageUrl} />

        </Helmet>
    )
}



export default Licenses