import React, { useEffect, useState } from 'react';

import {Link} from 'react-router-dom'

import SRC_GOOGLE from '../../assets/img/google-store.png';
import SRC_APPLE from '../../assets/img/apple-store.png';
import SRC_DOWNLOAD from '../../assets/img/download.svg';
import SRC_APP from '../../assets/img/app-screen.jpg';
import SRC_CHAT from '../../assets/img/chat-screen.jpg';
import SRC_LOGO from '../../assets/img/logo-white.svg';
import SRC_POSTONE from '../../assets/img/post-1.jpg';
import SRC_POSTTWO from '../../assets/img/post-2.jpg';
import SRC_MATCH from '../../assets/img/match-screen.jpg';
import SRC_STAY from '../../assets/img/dog-stay.jpg';

import SRC_PONE from '../../assets/img/person-1.jpg';
import SRC_PTWO from '../../assets/img/person-2.jpg';
import SRC_PTHREE from '../../assets/img/person-3.jpg';

import SRC_WALKING from '../../assets/img/mobile-bar/walking.svg';
import SRC_SITTING from '../../assets/img/mobile-bar/sitting.svg';
import SRC_DOG from '../../assets/img/dog.svg';
import SRC_TRAINING from '../../assets/img/mobile-bar/training.svg';

import SRC_GENDER from '../../assets/img/mobile-bar/pets.svg';
import SRC_BREED from '../../assets/img/dog.svg';
import SRC_VACCIN from '../../assets/img/mobile-bar/vaccin.svg';



const Landing = (props) => {

    const { LANGUAGE } = props;

    const DownloadUrl = '/'+LANGUAGE+'/home';

    const sharedProps = {
        ...props,
        DownloadUrl
    }
    return (
        <>
            <Header {...sharedProps} />
            <Hero {...sharedProps} />
            <SocialMedia {...sharedProps} />
            <Icons {...sharedProps} />
            <WalkingBuddy {...sharedProps} />
            <Trainers {...sharedProps} />
            <Chat {...sharedProps} />
            <DogStay {...sharedProps} />
            <Download {...sharedProps} />
            <Footer {...sharedProps} />
        </>
    )
}

const Header = (props) => {

    const {DownloadUrl, history} = props;

    return (
        <div className='w-full max-w-[1400px] mx-auto px-4 py-8 flex items-center justify-start absolute top-0'>
            <img className='w-[100px]' src={SRC_LOGO} />
            <div className='hidden md:flex items-center gap-x-10 text-sm text-white ml-16'>
                <a href='#'>Home</a>
                <a href='#'>Connect</a>
                <a href='#'>Trainers</a>
                <a href='#'>Walk</a>
                <a href='#'>Shop</a>
                <a href='#'>Blog</a>
            </div>
            <div className='px-6 text-sm ml-auto avioyx-shadow cursor-pointer w-auto py-3 rounded-full bg-white text-black flex items-center gap-x-2' onClick={()=> history.push(DownloadUrl)}>
                Download
                <img className='w-[18px]' src={SRC_DOWNLOAD} />
            </div>

        </div>
    )
}

const Hero = (props) => {

    const {LANGUAGE} = props;

    return (
        <div className=' h-auto md:h-screen min-h-[600px] flex-shrink-0 w-full pt-[150px] overflow-hidden md:pt-[100px] pb-10 bg-landvert text-white'>
                
                <div className='w-screen max-w-[1140px] md:px-10 px-4 mx-auto flex md:flex-nowrap md:flex-row flex-col justify-center flex-wrap items-center h-full'>
                    <div className='w-full md:w-1/2 md:pr-10 flex-shrink-0 text-center md:text-left'>
                        <p className='opacity-60 uppercase mb-2 md:mb-4'>Welcome to Avioyx™</p>
                        <h1 className='md:text-7xl text-3xl font-bold'>The Social App for Dogs</h1>
                        <p className='md:my-4 font-light'>Connect with the furry friends in your neighborhood and discover new adventures for your beloved pup.</p>
                        <Link className='px-8 avioyx-shadow cursor-pointer w-auto mx-auto md:float-left mt-2 py-4 rounded-full bg-white text-black mr-auto hidden md:flex items-center gap-x-2' to={`/${LANGUAGE}/services`}>
                            Download
                            <img src={SRC_DOWNLOAD} alt=''/>
                        </Link>
                    </div>
                    <div className='w-full md:w-1/2 flex items-center justify-center md:mt-0 mt-8 md:pl-32'>
                        <div className='max-w-[240px] md:ml-32 flex-shrink-0 overflow-hidden border-black border-[10px] relative z-30 rounded-3xl avioyx-shadow'>
                            <img src={SRC_APP} alt=''/>
                            <div className='absolute top-0 left-0 w-full h-full py-12 px-2'>
                                <div className='w-full h-full relative rounded-xl overflow-hidden avioyx-shadow'>
                                    <img className='w-full h-full object-cover' src='https://images.unsplash.com/photo-1537151625747-768eb6cf92b2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=985&q=80' alt='' />
                                    <div className='absolute bottom-0 left-0 p-6 z-20'>
                                        <div className='text-xl font-medium'>Manuel</div>
                                        <div className='text-xs'>7km away</div>
                                    </div>
                                    <div className='absolute top-0 left-0 bg-gradient-to-t from-black opacity-25 w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center px-4 md:px-0 md:-translate-x-8 md:relative absolute w-full justify-between'>
                                
                                <div className='w-[200px] h-[370px] md:rotate-6 z-20 scale-95 relative rounded-xl overflow-hidden avioyx-shadow'>
                                    <img className='w-full h-full object-cover' src='https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1035&q=80' />
                                    <div className='absolute bottom-0 left-0 p-6 z-20'>
                                        <div className='text-xl font-medium'>Anthony</div>
                                        <div className='text-xs'>7km away</div>
                                    </div>
                                    <div className='absolute top-0 left-0 bg-gradient-to-t from-black opacity-25 w-full h-full' />
                                </div>
                                <div className='w-[200px] h-[370px] md:-rotate-6 md:-translate-x-10 md:scale-90 relative rounded-xl overflow-hidden avioyx-shadow'>
                                    <img className='w-full h-full object-cover' src='https://images.unsplash.com/photo-1585908286456-991b5d0e53f4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80' alt='' />
                                    <div className='absolute bottom-0 left-0 p-6 z-20'>
                                        <div className='text-xl font-medium'>Charlie</div>
                                        <div className='text-xs'>7km away</div>
                                    </div>
                                    <div className='absolute top-0 left-0 bg-gradient-to-t from-black opacity-25 w-full h-full' />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}


const SocialMedia = (props) => {

    const {LANGUAGE} = props;
    return (
        <div className='w-full pt-20 md:pt-44  flex-shrink-0'>
                
                <div className='w-screen max-w-[1140px] md:px-10 px-4 mx-auto flex md:flex-nowrap flex-wrap items-center h-full'>
                    <div className='w-full md:w-1/2 md:pr-20 flex-shrink-0 md:mb-0 mb-10'>
                        <h1 className='md:text-4xl font-bold'>Avioyx is a social media platform designed exclusively for dogs.</h1>
                        <p className='my-4 font-light'>Bringing you a range of features that will make your dog's life more exciting and enjoyable</p>
                        <Link className='px-8 avioyx-shadow cursor-pointer w-auto float-left mt-2 py-4 rounded-full bg-avioyx-100 hover:bg-red-600 text-white mr-auto flex items-center gap-x-2' to={`/${LANGUAGE}/services`}>
                            Download
                            <img className='invert' src={SRC_DOWNLOAD} alt='' />
                        </Link>
                    </div>
                    <div className='w-full md:w-1/2 md:pl-10 flex-shrink-0 items-center justify-center flex items-center'>
                        <div className='relative'>
                            <img className='w-[320px] relative z-20 avioyx-shadow' src={SRC_POSTONE} alt='' />
                            <img className='w-[280px] absolute md:-right-10 rotate-6 top-0  avioyx-shadow' src={SRC_POSTTWO} alt='' />
                        </div>
                    </div>
                </div>
        </div>

        )
}

const Icons = (props) => {
    return (
        <div className='w-full pt-20 md:pt-44  flex-shrink-0'>
                
                <div className='w-screen max-w-[1140px] md:px-10 px-4 mx-auto flex md:flex-nowrap flex-wrap items-center h-full'>
                    <div className='w-full md:w-1/2 md:pl-20 flex-shrink-0 md:order-2 md:mb-0 mb-10'>
                        <h1 className='md:text-4xl font-bold'>Everything you’ve been looking for at your fingertips.</h1>
                        <p className='my-4 font-light'>Whether you're looking for a walking buddy, a professional trainer, a reliable dog walker, or a cozy dog stay, Avioyx™ has got you covered. Join the pack today!</p>
                        <div className='px-8 avioyx-shadow cursor-pointer w-auto float-left mt-2 py-4 rounded-full bg-avioyx-100 hover:bg-red-600 text-white mr-auto flex items-center gap-x-2'>
                            Download
                            <img className='invert' src={SRC_DOWNLOAD} alt='' />
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 flex-shrink-0 items-center justify-center md:pr-10'>
                        <div className='grid grid-cols-2 gap-4'>
                            <div className='w-full min-h-[200px] bg-slate-100 text-center rounded-2xl flex flex-col items-center justify-center p-10 text-sm font-semibold'>
                                <img className='w-10 mb-2' src={SRC_DOG}  alt=''/>
                                Connect with other dogs
                            </div>
                            <div className='w-full min-h-[200px] bg-slate-100 text-center rounded-2xl flex flex-col items-center justify-center p-10 text-sm font-semibold'>
                                <img className='w-10 mb-2' src={SRC_TRAINING} alt='' />
                                Trainers
                            </div>
                            <div className='w-full min-h-[200px] bg-slate-100 text-center rounded-2xl flex flex-col items-center justify-center p-10 text-sm font-semibold'>
                                <img className='w-10 mb-2' src={SRC_WALKING} alt=''/>
                                Walkers
                            </div>
                            <div className='w-full min-h-[200px] bg-slate-100 text-center rounded-2xl flex flex-col items-center justify-center p-10 text-sm font-semibold'>
                                <img className='w-10 mb-2' src={SRC_SITTING} alt='' />
                                Dog stays
                            </div>
                           
                            
                        </div>
                    </div>
                </div>
        </div>

        )
}


const WalkingBuddy = (props) => {
    return (
        <div className='w-full pt-20 md:pt-44  flex-shrink-0'>
                
                <div className='w-screen max-w-[1140px] md:px-10 px-4 mx-auto flex md:flex-nowrap flex-wrap items-center h-full'>
                    <div className='w-full md:w-1/2 md:pr-20 flex-shrink-0 md:mb-0 mb-10'>
                        <h1 className='md:text-4xl font-bold'>Find Your Perfect Walking Buddy.</h1>
                        <p className='my-4 font-light'>Does your dog love to explore the great outdoors? With Avioyx™, you can easily find other dogs in your area that are ready for a pawsome adventure. Discover compatible playmates for your pup and arrange meetups for exhilarating walks. Your furry friend will thank you!</p>
                        <div className='px-8 avioyx-shadow cursor-pointer w-auto float-left mt-2 py-4 rounded-full bg-avioyx-100 hover:bg-red-600 text-white mr-auto flex items-center gap-x-2'>
                            Download
                            <img className='invert' src={SRC_DOWNLOAD} />
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 md:pl-10 flex-shrink-0 items-center justify-center'>
                         <div className='max-w-[240px] md:ml-32 flex-shrink-0 border-black border-[10px] relative z-20 rounded-3xl avioyx-shadow mx-auto'>
                            <img className=' rounded-xl' src={SRC_MATCH} />
                            <div className='flex absolute -left-10 top-10 avioyx-shadow items-center justify-center text-black px-4 py-2 rounded-full capitalize' style={{ background: '#FFD2CF' }}>
                                <img src={SRC_GENDER} className='w-[20px] h-[20px] mr-2' /> Male
                            </div>
                            <div className='flex absolute -right-20 bottom-14 avioyx-shadow items-center justify-center text-black px-4 py-2 rounded-full capitalize' style={{ background: '#FFD2CF' }}>
                                <img src={SRC_VACCIN} className='w-[20px] h-[20px] mr-2' /> Vaccinated
                            </div>
                            <div className='flex absolute -left-20 bottom-24 avioyx-shadow items-center justify-center text-black px-4 py-2 rounded-full capitalize' style={{ background: '#FFD2CF' }}>
                                <img src={SRC_BREED} className='w-[20px] h-[20px] mr-2' /> Bulldog
                            </div>
                            
                        </div>
                    </div>
                </div>
        </div>

        )
}


const Trainers = (props) => {
    return (
        <div className='w-full pt-20 md:pt-44  flex-shrink-0'>
                
                <div className='w-screen max-w-[1140px] md:px-10 px-4 mx-auto flex md:flex-nowrap flex-wrap items-center h-full'>
                    <div className='w-full md:w-1/2 md:pl-20 flex-shrink-0 md:order-2 md:mb-0 mb-10'>
                        <h1 className='md:text-4xl font-bold'>Expert Trainers at Your Paws</h1>
                        <p className='my-4 font-light'>Unlock your dog's full potential with the help of top-notch trainers on Avioyx™. From obedience training to agility classes, you'll find a diverse range of professionals ready to guide your dog towards becoming a well-rounded companion. Take the first step towards a well-behaved and happy pup!</p>
                        <div className='px-8 avioyx-shadow cursor-pointer w-auto float-left mt-2 py-4 rounded-full bg-avioyx-100 hover:bg-red-600 text-white mr-auto flex items-center gap-x-2'>
                            Download
                            <img className='invert' src={SRC_DOWNLOAD} />
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 flex flex-shrink-0 items-center justify-center md:pr-10'>

                        <div className='w-[230px] h-[400px] text-white flex-shrink-0 translate-x-40 md:translate-x-20 scale-90 relative rounded-xl overflow-hidden avioyx-shadow'>
                            <img className='w-full h-full object-cover' src={SRC_PTHREE} />
                            <div className='absolute bottom-0 left-0 p-6 z-20'>
                                <div className='text-xl font-medium'>James</div>
                                <div className='text-xs'>3km away</div>
                            </div>
                            <div className='absolute top-0 left-0 bg-gradient-to-t from-black opacity-25 w-full h-full' />
                        </div>
                        
                        <div className='w-[230px] h-[400px] text-white flex-shrink-0 z-20 relative rounded-xl overflow-hidden avioyx-shadow'>
                            <img className='w-full h-full object-cover' src={SRC_PONE} />
                            <div className='absolute bottom-0 left-0 p-6 z-20'>
                                <div className='text-xl font-medium'>Richard</div>
                                <div className='text-xs'>7km away</div>
                            </div>
                            <div className='absolute top-0 left-0 bg-gradient-to-t from-black opacity-25 w-full h-full' />
                        </div>

                        <div className='w-[230px] h-[400px] text-white flex-shrink-0 -translate-x-40 md:-translate-x-20 scale-90 relative rounded-xl overflow-hidden avioyx-shadow'>
                            <img className='w-full h-full object-cover'  src={SRC_PTWO} />
                            <div className='absolute bottom-0 left-0 p-6 z-20'>
                                <div className='text-xl font-medium'>James</div>
                                <div className='text-xs'>3km away</div>
                            </div>
                            <div className='absolute top-0 left-0 bg-gradient-to-t from-black opacity-25 w-full h-full' />
                        </div>
                           
                        
                    </div>
                </div>
        </div>

        )
}


const Chat = (props) => {
    return (
        <div className='w-full pt-20 md:pt-44  flex-shrink-0'>
                
                <div className='w-screen max-w-[1140px] md:px-10 px-4 mx-auto flex md:flex-nowrap flex-wrap items-center h-full'>
                    <div className='w-full md:w-1/2 md:pr-20 flex-shrink-0 md:mb-0 mb-10'>
                        <h1 className='md:text-4xl font-bold'>Reliable Dog Walkers on Demand</h1>
                        <p className='my-4 font-light'>Busy schedule? No worries! Avioyx™ has a dedicated network of reliable dog walkers who are passionate about keeping your dog active and content. Whether you need occasional assistance or regular walking services, our trusted walkers are just a tap away. Give your dog the exercise they need, even on your busiest days.</p>
                        <div className='px-8 avioyx-shadow cursor-pointer w-auto float-left mt-2 py-4 rounded-full bg-avioyx-100 hover:bg-red-600 text-white mr-auto flex items-center gap-x-2'>
                            Download
                            <img className='invert' src={SRC_DOWNLOAD} />
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 md:pl-10 flex-shrink-0 items-center justify-center'>
                         <div className='max-w-[240px] md:ml-32 flex-shrink-0 border-black border-[10px] relative z-20 rounded-3xl avioyx-shadow mx-auto'>
                            <img className=' rounded-xl' src={SRC_CHAT} />
                            <div className='flex flex-col absolute -left-20 top-14 avioyx-shadow items-start justify-center text-white bg-avioyx-100 px-4 py-2 rounded-lg text-xs' style={{ background: '#FFD2CF' }}>
                                 <strong>Maya</strong>
                                 Let's go to the park?
                            </div>
                            <div className='flex flex-col absolute -right-20 bottom-14 avioyx-shadow items-start justify-center text-white bg-avioyx-100 px-4 py-2 rounded-lg text-xs' style={{ background: '#FFD2CF' }}>
                                 <strong>Morty</strong>
                                 Can you take me for a walk?
                            </div>
                           
                            
                        </div>
                    </div>
                </div>
        </div>

        )
}

const DogStay = (props) => {
    return (
        <div className='w-full pt-20 md:py-44  flex-shrink-0'>
                
                <div className='w-screen max-w-[1140px] md:px-10 px-4 mx-auto flex md:flex-nowrap flex-wrap items-center h-full'>
                    <div className='w-full md:w-1/2 md:pl-20 flex-shrink-0 md:order-2 md:mb-0 mb-10'>
                        <h1 className='md:text-4xl font-bold'>Cozy Dog Stays for Peace of Mind</h1>
                        <p className='my-4 font-light'>Planning a trip and need a safe place for your four-legged family member? Avioyx™ connects you with reputable dog stays, ensuring that your dog receives the love, care, and attention they deserve while you're away. Enjoy your vacation knowing that your furry friend is in safe and loving hands.</p>
                        <div className='px-8 avioyx-shadow cursor-pointer w-auto float-left mt-2 py-4 rounded-full bg-avioyx-100 hover:bg-red-600 text-white mr-auto flex items-center gap-x-2'>
                            Download
                            <img className='invert' src={SRC_DOWNLOAD} />
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 flex flex-shrink-0 items-center justify-center md:pr-10'>

                        <img src={SRC_STAY} />
                           
                        
                    </div>
                </div>
        </div>

        )
}

const Download = (props) => {
    return (
        <div className='w-full pb-20 md:pb-44 md:mt-0 mt-20  flex-shrink-0'>
                
                <div className='w-screen max-w-[640px] md:px-10 px-4 mx-auto flex flex-col justify-center text-center md:flex-nowrap flex-wrap items-center h-full'>
                    
                        <h1 className='md:text-4xl font-bold'>Don't wait! Join the Avioyx community today!</h1>
                        <p className='my-4 font-light'>Give your dog the social life they've always dreamed of. Sign up now and start exploring the world of possibilities for your furry friend!</p>
                        <div className='flex items-center justify-center gap-x-4 mt-4'>
                            <img className='h-12 cursor-pointer' src={SRC_GOOGLE} />
                            <img className='h-12 cursor-pointer' src={SRC_APPLE} />
                        </div>
                    
                </div>
        </div>

        )
}


const Footer = (props) => {
    return (
        <div className='w-full mb-20  flex-shrink-0'>
                
                <div className='w-screen max-w-[1440px] border-t border-zinc-200 px-4 py-8 mx-auto flex justify-between md:flex-nowrap flex-wrap items-center'>
                        <img className='invert w-[80px]' src={SRC_LOGO} />
                        <div className='hidden md:flex items-center gap-x-6 text-xs'>
                            <a href='#'>Home</a>
                            <a href='#'>Connect</a>
                            <a href='#'>Trainers</a>
                            <a href='#'>Walk</a>
                            <a href='#'>Shop</a>
                            <a href='#'>Blog</a>
                        </div>
                        <div className='px-6 avioyx-shadow cursor-pointer w-auto float-left mt-2 py-3 rounded-full bg-zinc-800 hover:bg-zinc-600 text-white text-xs flex items-center gap-x-2'>
                            Download
                            <img className='invert w-4' src={SRC_DOWNLOAD} />
                        </div>
                </div>
        </div>

        )
}


export default Landing
