import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from '../General/Header';
import Footer from "../General/Footer.js"

import { postApiPrivate } from '../../api';
import { isDesktop } from 'react-device-detect';

import Blog from '../Blog/Blog';
import BlogCategories from '../Blog/BlogCategories';
import BlogCategory from '../Blog/BlogCategory';
import BlogSubCategory from '../Blog/BlogSubCategory';
import BlogPosts from '../Blog/BlogPosts';
import BlogPost from '../Blog/BlogPost';

const PublicContainer = (props) => {

  const { TRANSLATE, USER, LANGUAGE, onUpdateLanguage, onFetchUser, onFacebookTracking, onKlaviyoTracking, onFetchAnalytics, setOverflow, location, match } = props;

  const sharedProps = {
    ...props,
    TRANSLATE,
    USER,
    LANGUAGE,
    onUpdateLanguage,
    onFetchUser,
    onFacebookTracking,
    onKlaviyoTracking,
    setOverflow
  }

  useEffect(() => {

    const language = match.params.lang;
    const langArray = ['en', 'it', 'fr', 'es'];

    if(langArray.includes(language)){
      onUpdateLanguage(language);
    }
    else{
      const LANG = localStorage.getItem('language') || 'en';
      onUpdateLanguage(LANG);
    }

  }, [match.params.lang]);


  useEffect(() => {
    onFetchAnalytics({ location });
    onFacebookTracking('pageView');

    window.scrollTo(0, 0);

  }, [match.url]);



  const { isModalCart, isModalSearch, isModalLocalisation, isModalUser, isModalProvider, isModalGuide, isModalCity } = props;

  useEffect(() => {

    const isActiveChat = match.params.store && match.params.customer && !isDesktop;

    if (isModalCart || isModalSearch || isModalLocalisation || isModalUser || isModalProvider || isModalGuide || isActiveChat || isModalCity) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isModalCart, isModalSearch, isModalLocalisation, isModalUser, isModalProvider, isModalGuide, isModalCity, match.params])



  return (

    <>

      <Header {...sharedProps} />

      <Switch>

        {/*** Public - Blog ***/}
        <Route exact path="/:lang/blog" render={(props) => <Blog {...sharedProps} {...props} />} />
        <Route exact path="/:lang/blog/categories" render={(props) => <BlogCategories {...sharedProps} {...props} />} />
        <Route exact path="/:lang/blog/categories/:id" render={(props) => <BlogCategory {...sharedProps} {...props} />} />
        <Route exact path="/:lang/blog/categories/:id/:sub" render={(props) => <BlogSubCategory {...sharedProps} {...props} />} />
        <Route exact path="/:lang/blog/posts" render={(props) => <BlogPosts {...sharedProps} {...props} />} />
        <Route exact path="/:lang/blog/posts/:id" render={(props) => <BlogPost {...sharedProps} {...props} />} />
        

      </Switch>


    </>

  )


}


export default PublicContainer;
