import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';

import SRC_CALENDAR from '../../assets/img/icons/calendar.svg';
import SRC_HOME from '../../assets/img/icons/home.svg';
import SRC_TIME from '../../assets/img/icons/time.svg';
import SRC_AIRCRAFTS from '../../assets/img/icons/aircrafts.svg';
import SRC_TEMP from '../../assets/img/icons/temperature.svg';
import SRC_WEATHER from '../../assets/img/icons/weather.svg';
import SRC_WIND from '../../assets/img/icons/wind.svg';
import SRC_VISIBILITY from '../../assets/img/icons/visibility.svg';
import SRC_ALT from '../../assets/img/icons/arrow-up.svg';
import SRC_HEART from '../../assets/img/icons/heart.svg';
import SRC_SEARCH from '../../assets/img/icons/search.svg';

import SRC_LOGO_ICON from '../../assets/img/logo_icon.png';

const Airports = (props) => {

    const {  LANGUAGE, TRANSLATE, homelang } = props;

    return (
        <>
            <HeaderSEO {...props}
                LANGUAGE={homelang || LANGUAGE}

            />

            <div className='w-full'>
                <div className='w-full input-group with-icon'>
                    <img className='input-icon' src={SRC_SEARCH} />
                    <input type='text' placeholder='Search Airports' />
                </div>
                <div className='w-full flex flex-col gap-y-4 pb-10'>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:blue-shadow transition-all duration-100 relative'> 
                        <div className='flex w-full items-center text-2xl'>
                            <div className='font-semibold flex-shrink-0'>FSFO</div>
                            <div className='w-1 h-1 rounded-full bg-gray-400 mx-3' />
                            <div className='font-light'>San Francisco International Airport</div>
                            <img className='ml-auto blue-icon' src={SRC_HOME} />
                        </div>
                        <div className='flex gap-x-10 pt-6 mt-6 justify-start items-center'>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_TEMP} />
                                13.9 °C
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_WEATHER} />
                                Broken Clouds
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_WIND} />
                                Wind None
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_ALT} />
                                25,000 ft
                            </div>
                            <div className='text-xs px-4 py-1 bg-green-500 rounded-full'>VFR</div>
                        </div>
                    </div>

                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:blue-shadow transition-all duration-100 relative'> 
                        <div className='flex w-full items-center text-2xl'>
                            <div className='font-semibold flex-shrink-0'>FSAN</div>
                            <div className='w-1 h-1 rounded-full bg-gray-400 mx-3' />
                            <div className='font-light'>San Diego International Airport</div>
                            <img className='ml-auto blue-icon' src={SRC_HEART} />
                        </div>
                        <div className='flex gap-x-10 pt-6 mt-6 justify-start items-center'>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_TEMP} />
                                13.9 °C
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_WEATHER} />
                                Broken Clouds
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_WIND} />
                                Wind None
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_ALT} />
                                25,000 ft
                            </div>
                            <div className='text-xs px-4 py-1 bg-green-500 rounded-full'>VFR</div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:blue-shadow transition-all duration-100 relative'> 
                        <div className='flex w-full items-center text-2xl'>
                            <div className='font-semibold flex-shrink-0'>FSAN</div>
                            <div className='w-1 h-1 rounded-full bg-gray-400 mx-3' />
                            <div className='font-light'>San Diego International Airport</div>
                            <img className='ml-auto blue-icon' src={SRC_HEART} />
                        </div>
                        <div className='flex gap-x-10 pt-6 mt-6 justify-start items-center'>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_TEMP} />
                                13.9 °C
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_WEATHER} />
                                Broken Clouds
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_WIND} />
                                Wind None
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_ALT} />
                                25,000 ft
                            </div>
                            <div className='text-xs px-4 py-1 bg-green-500 rounded-full'>VFR</div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:blue-shadow transition-all duration-100 relative'> 
                        <div className='flex w-full items-center text-2xl'>
                            <div className='font-semibold flex-shrink-0'>FSAN</div>
                            <div className='w-1 h-1 rounded-full bg-gray-400 mx-3' />
                            <div className='font-light'>San Diego International Airport</div>
                            <img className='ml-auto blue-icon' src={SRC_HEART} />
                        </div>
                        <div className='flex gap-x-10 pt-6 mt-6 justify-start items-center'>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_TEMP} />
                                13.9 °C
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_WEATHER} />
                                Broken Clouds
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_WIND} />
                                Wind None
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_ALT} />
                                25,000 ft
                            </div>
                            <div className='text-xs px-4 py-1 bg-green-500 rounded-full'>VFR</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const HeaderSEO = (props) => {

    const { TRANSLATE, LANGUAGE } = props;

    const routeUrl = 'https://www.avioyx.com';
    const shareUrl = routeUrl + '/' + LANGUAGE;
    const imageUrl = routeUrl + "/assets/img/landing/hero.jpg";

    return (

        <Helmet prioritizeSeoTags>

            <html lang={LANGUAGE} />

            <title>{TRANSLATE.seo_title}</title>
            <meta name="description" content={TRANSLATE.seo_description} />

            <meta itemprop="name" content={TRANSLATE.seo_title} />
            <meta itemprop="description" content={TRANSLATE.seo_description} />
            <meta itemprop="image" content={imageUrl} />

            <link rel="canonical" href={routeUrl} />
            <link rel="alternate" href={routeUrl + '/en'} hrefLang='x-default' />
            <link rel="alternate" href={routeUrl + '/it'} hrefLang='it' />
            <link rel="alternate" href={routeUrl + '/fr'} hrefLang='fr' />
            <link rel="alternate" href={routeUrl + '/es'} hrefLang='es' />
            <link rel="alternate" href={routeUrl + '/de'} hrefLang='de' />
            <link rel="alternate" href={routeUrl + '/ar'} hrefLang='ar' />
            <link rel="alternate" href={routeUrl + '/ja'} hrefLang='ja' />
            <link rel="alternate" href={routeUrl + '/zh'} hrefLang='zh' />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@publisher_handle" />
            <meta name="twitter:title" content={TRANSLATE.seo_title} />
            <meta name="twitter:description" content={TRANSLATE.seo_description} />
            <meta name="twitter:creator" content="@avioyx" />
            <meta name="twitter:image:src" content={imageUrl} />

            <meta property="og:title" content={TRANSLATE.seo_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={shareUrl} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:description" content={TRANSLATE.seo_description} />
            <meta property="og:site_name" content="avioyx" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta property='fb:app_id' content='236904211103455' />

            <meta itemprop="thumbnailUrl" content={imageUrl} />
            <meta itemprop="image_src" content={imageUrl} />
            <meta itemprop="image" content={imageUrl} />

        </Helmet>
    )
}



export default Airports