import React from 'react';

function Shop() {
    return (
        <div>
            <h1>Pilot Shop</h1>
            {ShopCategories.map(category => (
                <div key={category.id}>
                    <h2>{category.name}</h2>
                    <div className="products">
                        {category.products.map(product => (
                            <ProductCard key={product.id} product={product} />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

function ProductCard({ product }) {
    return (
        <div className="product-card">
            <img src={product.image} alt={product.name} />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>Price: ${product.price}</p>
            <button>Add to Cart</button>
        </div>
    );
}

const ShopCategories = [
    {
        "id": 1,
        "name": "Pilot Uniforms",
        "subcategories": [
            {
                "id": 11,
                "name": "Men"
            },
            {
                "id": 12,
                "name": "Women"
            },
            {
                "id": 13,
                "name": "Kids"
            }
        ],
        "products": [
            {
                "id": 111,
                "name": "Pilot Uniform for Men",
                "description": "Description of Pilot Uniform for Men",
                "price": 99.99,
                "image": "https://example.com/products/pilot_uniform_men.jpg",
                "color": "Navy",
                "size": ["S", "M", "L", "XL"],
                "material": "Polyester",
                "availability": true
            },
            {
                "id": 112,
                "name": "Pilot Uniform for Women",
                "description": "Description of Pilot Uniform for Women",
                "price": 99.99,
                "image": "https://example.com/products/pilot_uniform_women.jpg",
                "color": "Black",
                "size": ["XS", "S", "M", "L"],
                "material": "Cotton",
                "availability": true
            },
            {
                "id": 113,
                "name": "Pilot Uniform for Kids",
                "description": "Description of Pilot Uniform for Kids",
                "price": 69.99,
                "image": "https://example.com/products/pilot_uniform_kids.jpg",
                "color": "Red",
                "size": ["2T", "3T", "4T"],
                "material": "Polyester",
                "availability": true
            }
        ]
    },
    {
        "id": 2,
        "name": "Pilot Accessories",
        "subcategories": [
            {
                "id": 21,
                "name": "Men"
            },
            {
                "id": 22,
                "name": "Women"
            },
            {
                "id": 23,
                "name": "Kids"
            }
        ],
        "products": [
            {
                "id": 211,
                "name": "Aviator Sunglasses for Men",
                "description": "Description of Aviator Sunglasses for Men",
                "price": 39.99,
                "image": "https://example.com/products/aviator_sunglasses_men.jpg",
                "color": "Black",
                "material": "Metal",
                "availability": true
            },
            {
                "id": 212,
                "name": "Pilot Watch for Women",
                "description": "Description of Pilot Watch for Women",
                "price": 79.99,
                "image": "https://example.com/products/pilot_watch_women.jpg",
                "color": "Rose Gold",
                "material": "Stainless Steel",
                "availability": true
            },
            {
                "id": 213,
                "name": "Pilot Cap for Kids",
                "description": "Description of Pilot Cap for Kids",
                "price": 19.99,
                "image": "https://example.com/products/pilot_cap_kids.jpg",
                "color": "Blue",
                "material": "Cotton",
                "availability": true
            }
        ]
    },
    {
        "id": 3,
        "name": "Simulators",
        "products": [
            {
                "id": 311,
                "name": "Flight Simulator Kit",
                "description": "Description of Flight Simulator Kit",
                "price": 599.99,
                "image": "https://example.com/products/flight_simulator.jpg",
                "compatibility": "PC, Mac",
                "features": [
                    "Realistic controls",
                    "Multiple aircraft options",
                    "Scenic locations"
                ],
                "availability": true
            },
            {
                "id": 312,
                "name": "VR Flight Simulator",
                "description": "Description of VR Flight Simulator",
                "price": 899.99,
                "image": "https://example.com/products/vr_flight_simulator.jpg",
                "compatibility": "PC, VR headset",
                "features": [
                    "Immersive virtual reality experience",
                    "Realistic cockpit visuals",
                    "Interactive controls"
                ],
                "availability": true
            }
        ]
    },
    {
        "id": 4,
        "name": "Courses",
        "products": [
            {
                "id": 411,
                "name": "Private Pilot License Course",
                "description": "Description of Private Pilot License Course",
                "price": 1499.99,
                "image": "https://example.com/products/ppl_course.jpg",
                "duration": "6 months",
                "instructor": "John Smith",
                "availability": true
            },
            {
                "id": 412,
                "name": "Instrument Rating Course",
                "description": "Description of Instrument Rating Course",
                "price": 1999.99,
                "image": "https://example.com/products/instrument_rating_course.jpg",
                "duration": "3 months",
                "instructor": "Jane Doe",
                "availability": true
            },
            {
                "id": 413,
                "name": "Multi-Engine Rating Course",
                "description": "Description of Multi-Engine Rating Course",
                "price": 2499.99,
                "image": "https://example.com/products/multi_engine_rating_course.jpg",
                "duration": "2 months",
                "instructor": "David Johnson",
                "availability": true
            }
        ]
    },
    {
        "id": 5,
        "name": "Books",
        "products": [
            {
                "id": 511,
                "name": "The Pilot's Handbook",
                "description": "Description of The Pilot's Handbook",
                "price": 29.99,
                "image": "https://example.com/products/pilots_handbook.jpg",
                "author": "John Anderson",
                "format": "Paperback",
                "availability": true
            },
            {
                "id": 512,
                "name": "Aviation Weather",
                "description": "Description of Aviation Weather",
                "price": 24.99,
                "image": "https://example.com/products/aviation_weather.jpg",
                "author": "Sarah Thompson",
                "format": "E-book",
                "availability": true
            },
            {
                "id": 513,
                "name": "Pilot's Logbook",
                "description": "Description of Pilot's Logbook",
                "price": 14.99,
                "image": "https://example.com/products/pilots_logbook.jpg",
                "author": "Michael Davis",
                "format": "Hardcover",
                "availability": true
            }
        ]
    },
    {
        "id": 6,
        "name": "Digital Subscription",
        "products": [
            {
                "id": 611,
                "name": "Flight Simulator Magazine Subscription",
                "description": "Description of Flight Simulator Magazine Subscription",
                "price": 9.99,
                "image": "https://example.com/products/flight_simulator_magazine.jpg",
                "period": "1 year",
                "publisher": "Flight Publications",
                "availability": true
            },
            {
                "id": 612,
                "name": "Aviation News Digital Subscription",
                "description": "Description of Aviation News Digital Subscription",
                "price": 14.99,
                "image": "https://example.com/products/aviation_news.jpg",
                "period": "6 months",
                "publisher": "Aviation Media",
                "availability": true
            },
            {
                "id": 613,
                "name": "Pilot Training Videos Subscription",
                "description": "Description of Pilot Training Videos Subscription",
                "price": 19.99,
                "image": "https://example.com/products/training_videos.jpg",
                "period": "1 month",
                "publisher": "Flight Academy",
                "availability": true
            }
        ]
    },
    {
        "id": 7,
        "name": "Flight Bags",
        "products": [
            {
                "id": 711,
                "name": "Pilot's Carry-On Bag",
                "description": "Description of Pilot's Carry-On Bag",
                "price": 79.99,
                "image": "https://example.com/products/carry_on_bag.jpg",
                "color": "Black",
                "material": "Nylon",
                "availability": true
            },
            {
                "id": 712,
                "name": "Flight Crew Rolling Bag",
                "description": "Description of Flight Crew Rolling Bag",
                "price": 149.99,
                "image": "https://example.com/products/rolling_bag.jpg",
                "color": "Gray",
                "material": "Polyester",
                "availability": true
            },
            {
                "id": 713,
                "name": "Flight Backpack",
                "description": "Description of Flight Backpack",
                "price": 59.99,
                "image": "https://example.com/products/flight_backpack.jpg",
                "color": "Blue",
                "material": "Canvas",
                "availability": true
            }
        ]
    },
    {
        "id": 8,
        "name": "Gifts and Souvenirs",
        "products": [
            {
                "id": 811,
                "name": "Pilot Keychain",
                "description": "Description of Pilot Keychain",
                "price": 9.99,
                "image": "https://example.com/products/pilot_keychain.jpg",
                "material": "Metal",
                "availability": true
            },
            {
                "id": 812,
                "name": "Airplane Model",
                "description": "Description of Airplane Model",
                "price": 24.99,
                "image": "https://example.com/products/airplane_model.jpg",
                "material": "Plastic",
                "availability": true
            },
            {
                "id": 813,
                "name": "Pilot Mug",
                "description": "Description of Pilot Mug",
                "price": 12.99,
                "image": "https://example.com/products/pilot_mug.jpg",
                "material": "Ceramic",
                "availability": true
            }
        ]
    },
    {
        "id": 9,
        "name": "Aviation Art",
        "products": [
            {
                "id": 911,
                "name": "Flight Canvas Print",
                "description": "Description of Flight Canvas Print",
                "price": 49.99,
                "image": "https://example.com/products/flight_canvas_print.jpg",
                "size": "16x24 inches",
                "material": "Canvas",
                "availability": true
            },
            {
                "id": 912,
                "name": "Vintage Airplane Poster",
                "description": "Description of Vintage Airplane Poster",
                "price": 19.99,
                "image": "https://example.com/products/airplane_poster.jpg",
                "size": "18x24 inches",
                "material": "Paper",
                "availability": true
            },
            {
                "id": 913,
                "name": "Aerial Photography Book",
                "description": "Description of Aerial Photography Book",
                "price": 34.99,
                "image": "https://example.com/products/aerial_photography_book.jpg",
                "author": "Robert Johnson",
                "format": "Hardcover",
                "availability": true
            }
        ]
    },
    {
        "id": 10,
        "name": "Safety Equipment",
        "products": [
            {
                "id": 1011,
                "name": "Emergency Locator Transmitter",
                "description": "Description of Emergency Locator Transmitter",
                "price": 299.99,
                "image": "https://example.com/products/emergency_locator_transmitter.jpg",
                "brand": "ACME",
                "model": "ELT-2000",
                "availability": true
            },
            {
                "id": 1012,
                "name": "Aviation Headset",
                "description": "Description of Aviation Headset",
                "price": 199.99,
                "image": "https://example.com/products/aviation_headset.jpg",
                "brand": "PilotPro",
                "model": "P7",
                "availability": true
            },
            {
                "id": 1013,
                "name": "Pilot Life Jacket",
                "description": "Description of Pilot Life Jacket",
                "price": 129.99,
                "image": "https://example.com/products/pilot_life_jacket.jpg",
                "color": "Orange",
                "size": ["S/M", "L/XL"],
                "availability": true
            }
        ]
    },
    {
        "id": 11,
        "name": "Flight Planning",
        "products": [
            {
                "id": 1111,
                "name": "Aeronautical Charts",
                "description": "Description of Aeronautical Charts",
                "price": 19.99,
                "image": "https://example.com/products/aeronautical_charts.jpg",
                "type": "Sectional",
                "region": "Northern California",
                "availability": true
            },
            {
                "id": 1112,
                "name": "Flight Computer",
                "description": "Description of Flight Computer",
                "price": 49.99,
                "image": "https://example.com/products/flight_computer.jpg",
                "brand": "Jeppesen",
                "model": "E6B",
                "availability": true
            },
            {
                "id": 1113,
                "name": "Pilot's Logbook",
                "description": "Description of Pilot's Logbook",
                "price": 14.99,
                "image": "https://example.com/products/pilots_logbook.jpg",
                "author": "Michael Davis",
                "format": "Hardcover",
                "availability": true
            }
        ]
    },
    {
        "id": 12,
        "name": "Pilot Gifts",
        "products": [
            {
                "id": 1211,
                "name": "Pilot Coffee Mug",
                "description": "Description of Pilot Coffee Mug",
                "price": 12.99,
                "image": "https://example.com/products/pilot_coffee_mug.jpg",
                "material": "Ceramic",
                "availability": true
            },
            {
                "id": 1212,
                "name": "Aviation T-Shirt",
                "description": "Description of Aviation T-Shirt",
                "price": 24.99,
                "image": "https://example.com/products/aviation_tshirt.jpg",
                "color": "White",
                "size": ["S", "M", "L", "XL"],
                "availability": true
            },
            {
                "id": 1213,
                "name": "Flight Simulator Poster",
                "description": "Description of Flight Simulator Poster",
                "price": 9.99,
                "image": "https://example.com/products/flight_simulator_poster.jpg",
                "size": "24x36 inches",
                "availability": true
            }
        ]
    },
    {
        "id": 13,
        "name": "Pilot Training",
        "products": [
            {
                "id": 1311,
                "name": "Private Pilot Ground School",
                "description": "Description of Private Pilot Ground School",
                "price": 499.99,
                "image": "https://example.com/products/private_pilot_ground_school.jpg",
                "duration": "3 months",
                "instructor": "John Smith",
                "availability": true
            },
            {
                "id": 1312,
                "name": "Instrument Rating Exam Prep",
                "description": "Description of Instrument Rating Exam Prep",
                "price": 299.99,
                "image": "https://example.com/products/instrument_rating_prep.jpg",
                "duration": "2 months",
                "instructor": "Jane Doe",
                "availability": true
            },
            {
                "id": 1313,
                "name": "Commercial Pilot Flight Training",
                "description": "Description of Commercial Pilot Flight Training",
                "price": 9999.99,
                "image": "https://example.com/products/commercial_pilot_training.jpg",
                "duration": "1 year",
                "instructor": "David Johnson",
                "availability": true
            }
        ]
    },
    {
        "id": 14,
        "name": "Aircraft Models",
        "products": [
            {
                "id": 1411,
                "name": "Cessna 172 Model",
                "description": "Description of Cessna 172 Model",
                "price": 99.99,
                "image": "https://example.com/products/cessna_172_model.jpg",
                "scale": "1:48",
                "material": "Plastic",
                "availability": true
            },
            {
                "id": 1412,
                "name": "Boeing 747 Model",
                "description": "Description of Boeing 747 Model",
                "price": 199.99,
                "image": "https://example.com/products/boeing_747_model.jpg",
                "scale": "1:200",
                "material": "Die-cast",
                "availability": true
            },
            {
                "id": 1413,
                "name": "Piper Cub Model",
                "description": "Description of Piper Cub Model",
                "price": 59.99,
                "image": "https://example.com/products/piper_cub_model.jpg",
                "scale": "1:72",
                "material": "Wood",
                "availability": true
            }
        ]
    },
    {
        "id": 15,
        "name": "Pilot Accessories",
        "products": [
            {
                "id": 1511,
                "name": "Aviator Sunglasses",
                "description": "Description of Aviator Sunglasses",
                "price": 49.99,
                "image": "https://example.com/products/aviator_sunglasses.jpg",
                "color": "Gold",
                "material": "Metal",
                "availability": true
            },
            {
                "id": 1512,
                "name": "Pilot Watch",
                "description": "Description of Pilot Watch",
                "price": 199.99,
                "image": "https://example.com/products/pilot_watch.jpg",
                "color": "Black",
                "material": "Stainless Steel",
                "availability": true
            },
            {
                "id": 1513,
                "name": "Aviation Kneeboard",
                "description": "Description of Aviation Kneeboard",
                "price": 39.99,
                "image": "https://example.com/products/aviation_kneeboard.jpg",
                "color": "Gray",
                "availability": true
            }
        ]
    }
]


export default Shop;
