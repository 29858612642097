import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';

import SRC_LOGO_ICON from '../../assets/img/logo_icon.png';
import SRC_CALENDAR from '../../assets/img/icons/calendar.svg';
import SRC_TIME from '../../assets/img/icons/time.svg';

const Courses = (props) => {

    const {  LANGUAGE, TRANSLATE, homelang } = props;

    return (
        <>
            <HeaderSEO {...props}
                LANGUAGE={homelang || LANGUAGE}

            />

            <div className='w-full flex gap-x-4'>
                <div className='w-full flex flex-col gap-y-4 pb-10'>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                        <div className='text-xs px-3 py-2 absolute top-3 right-3 bg-sky-500 rounded-full'>Upcoming</div>
                        <div className='text-lg font-semibold'>Aircraft Systems and Components</div>
                        <div className='flex gap-x-10 pt-6 opacity-50 mt-6'>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                                01/12/2023
                            </div>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_TIME} />
                                1h 59min
                            </div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                        <div className='text-lg font-semibold'>Aircraft Systems and Components</div>
                        <div className='flex gap-x-10 pt-6 opacity-50 mt-6'>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                                01/12/2023
                            </div>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_TIME} />
                                1h 59min
                            </div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                        <div className='text-lg font-semibold'>Aircraft Systems and Components</div>
                        <div className='flex gap-x-10 pt-6 opacity-50 mt-6'>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                                01/12/2023
                            </div>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_TIME} />
                                1h 59min
                            </div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                        <div className='text-lg font-semibold'>Aircraft Systems and Components</div>
                        <div className='flex gap-x-10 pt-6 opacity-50 mt-6'>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                                01/12/2023
                            </div>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_TIME} />
                                1h 59min
                            </div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                        <div className='text-lg font-semibold'>Aircraft Systems and Components</div>
                        <div className='flex gap-x-10 pt-6 opacity-50 mt-6'>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                                01/12/2023
                            </div>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_TIME} />
                                1h 59min
                            </div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                        <div className='text-lg font-semibold'>Aircraft Systems and Components</div>
                        <div className='flex gap-x-10 pt-6 opacity-50 mt-6'>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                                01/12/2023
                            </div>
                            <div className='flex items-center text-xs'>
                                <img className='mr-2 w-[18px]' src={SRC_TIME} />
                                1h 59min
                            </div>
                        </div>
                    </div>
                </div>
                <div className='rounded-lg sticky top-[170px] w-[300px] flex-shrink-0 bg-avioyx-100 p-4 flex items-center justify-center mb-auto'>
                    <span className='py-10'>Calendar</span>
                </div>

            </div>
        </>
    )
}


const HeaderSEO = (props) => {

    const { TRANSLATE, LANGUAGE } = props;

    const routeUrl = 'https://www.avioyx.com';
    const shareUrl = routeUrl + '/' + LANGUAGE;
    const imageUrl = routeUrl + "/assets/img/landing/hero.jpg";

    return (

        <Helmet prioritizeSeoTags>

            <html lang={LANGUAGE} />

            <title>{TRANSLATE.seo_title}</title>
            <meta name="description" content={TRANSLATE.seo_description} />

            <meta itemprop="name" content={TRANSLATE.seo_title} />
            <meta itemprop="description" content={TRANSLATE.seo_description} />
            <meta itemprop="image" content={imageUrl} />

            <link rel="canonical" href={routeUrl} />
            <link rel="alternate" href={routeUrl + '/en'} hrefLang='x-default' />
            <link rel="alternate" href={routeUrl + '/it'} hrefLang='it' />
            <link rel="alternate" href={routeUrl + '/fr'} hrefLang='fr' />
            <link rel="alternate" href={routeUrl + '/es'} hrefLang='es' />
            <link rel="alternate" href={routeUrl + '/de'} hrefLang='de' />
            <link rel="alternate" href={routeUrl + '/ar'} hrefLang='ar' />
            <link rel="alternate" href={routeUrl + '/ja'} hrefLang='ja' />
            <link rel="alternate" href={routeUrl + '/zh'} hrefLang='zh' />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@publisher_handle" />
            <meta name="twitter:title" content={TRANSLATE.seo_title} />
            <meta name="twitter:description" content={TRANSLATE.seo_description} />
            <meta name="twitter:creator" content="@avioyx" />
            <meta name="twitter:image:src" content={imageUrl} />

            <meta property="og:title" content={TRANSLATE.seo_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={shareUrl} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:description" content={TRANSLATE.seo_description} />
            <meta property="og:site_name" content="avioyx" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta property='fb:app_id' content='236904211103455' />

            <meta itemprop="thumbnailUrl" content={imageUrl} />
            <meta itemprop="image_src" content={imageUrl} />
            <meta itemprop="image" content={imageUrl} />

        </Helmet>
    )
}



export default Courses