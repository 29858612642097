import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';

import SRC_AIRPORTS from '../../assets/img/icons/airports.svg';
import SRC_RAIN from '../../assets/img/icons/rain.svg';
import SRC_SUNSET from '../../assets/img/icons/sunset.svg';
import SRC_SUNRISE from '../../assets/img/icons/sunrise.svg';
import SRC_USA from '../../assets/img/icons/usa.png';
import SRC_LOGO_ICON from '../../assets/img/logo_icon.png';
import SRC_FLIGHTS from '../../assets/img/icons/flights.svg';
import SRC_CALENDAR from '../../assets/img/icons/calendar.svg';
import SRC_TIME from '../../assets/img/icons/time.svg';
import SRC_COMMUNITY from '../../assets/img/icons/community.svg';
import SRC_AIRCRAFTS from '../../assets/img/icons/aircrafts.svg';
import SRC_LICENSES from '../../assets/img/icons/licenses.svg';
import SRC_SPEED from '../../assets/img/icons/speed.svg';


const Dashboard = (props) => {

    const {  LANGUAGE, TRANSLATE, homelang } = props;

    return (
        <>
            <HeaderSEO {...props}
                LANGUAGE={homelang || LANGUAGE}

            />
            <div className='w-full grid grid-cols-4 gap-4'>
                <div className='p-6 bg-avioyx-100 rounded-lg col-span-2 flex flex-col'>
                    <div className='w-full flex items-start justify-between'>
                        <div className='flex items-center text-sm'>
                            <img className='mr-3 blue-icon' src={SRC_AIRPORTS} />
                            Base Airport
                        </div>
                        <img className='rounded-full w-[30px] h-[30px]' src={SRC_USA} />
                    </div>
                    <div className='w-full py-12'>
                        <div className='text-4xl font-semibold'>SFO</div>
                        <div className='text-gray-400 text-sm font-light mt-1'>San Francisco International Airport</div>
                    </div>
                    <div className='flex gap-x-10'>
                        <div className='flex items-center text-sm'>
                            <img className='mr-3' src={SRC_RAIN} />
                            Heavy Rain
                        </div>
                        <div className='flex items-center text-sm'>
                            <img className='mr-3' src={SRC_SUNRISE} />
                            6:43am
                        </div>
                        <div className='flex items-center text-sm'>
                            <img className='mr-3' src={SRC_SUNSET} />
                            7:21pm
                        </div>
                    </div>
                    
                </div>

                <div className='p-6 bg-avioyx-100 rounded-lg col-span-2 flex flex-col'>
                    <div className='w-full flex items-start justify-between'>
                        <div className='flex items-center text-sm'>
                            <img className='mr-3 blue-icon' src={SRC_COMMUNITY} />
                            Last Flight
                        </div>
                    </div>
                    <div className='w-full py-12'>
                        <div className='text-4xl font-semibold'>LAX - SAN</div>
                        <div className='text-gray-400 text-sm font-light mt-1'>I-CNTR - Cessna - C152</div>
                    </div>
                    <div className='flex gap-x-10'>
                        <div className='flex items-center text-sm'>
                            <img className='mr-3' src={SRC_CALENDAR} />
                            01/12/2023
                        </div>
                        <div className='flex items-center text-sm'>
                            <img className='mr-3' src={SRC_TIME} />
                            1h 59min
                        </div>
                        <div className='flex items-center text-sm'>
                            <img className='mr-3' src={SRC_FLIGHTS} />
                            1,291 km
                        </div>
                    </div>
                    
                </div>

                <div className='col-span-2 grid grid-cols-2 gap-4'>
                    <div className='p-6 bg-avioyx-100 rounded-lg col-span-2 flex flex-col'>
                        <div className='w-full flex items-start justify-between'>
                            <div className='flex items-center text-sm'>
                                <img className='mr-3 blue-icon' src={SRC_AIRCRAFTS} />
                                Your Aircraft
                            </div>
                        </div>
                        <div className='w-full pt-6 flex items-center'>
                            <img className='w-[85px] h-[85px] rounded-lg mr-6' src='https://cessna.txtav.com/-/media/cessna/images/aircraft/piston/skyhawk/skyhawk_header_07_2019.ashx' />
                            <div>
                                <div className='text-2xl font-semibold'>Cessna 172</div>
                                <div className='text-gray-400 text-sm font-light mt-1'>10 Flights</div>
                            </div>
                        </div>
                    </div>

                    <div className='p-6 bg-avioyx-100 rounded-lg col-span-1 flex flex-col'>
                        <div className='w-full flex items-start justify-between'>
                            <div className='flex items-center text-sm'>
                                <img className='mr-3 blue-icon' src={SRC_SPEED} />
                                Flight totals
                            </div>
                        </div>
                        <div className='w-full py-6 flex items-center'>
                            <div>
                                <div className='text-2xl font-semibold'>10,948km</div>
                                <div className='text-gray-400 text-sm  font-light mt-1'>928 Hours</div>
                            </div>
                        </div>
                    </div>

                    <div className='p-6 bg-avioyx-100 rounded-lg col-span-1 flex flex-col'>
                        <div className='w-full flex items-start justify-between'>
                            <div className='flex items-center text-sm'>
                                <img className='mr-3 blue-icon' src={SRC_LICENSES} />
                                Licenses
                            </div>
                        </div>
                        <div className='w-full py-6 flex items-center'>
                            <div>
                                <div className='text-2xl font-semibold'>Medical</div>
                                <div className='text-gray-400 text-sm font-light mt-1'>Expiring 24/12/2024</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='p-6 bg-avioyx-100 rounded-lg col-span-2 flex flex-col'>
                    Calendar here
                    
                </div>

            </div>
               
        </>
    )
}


const HeaderSEO = (props) => {

    const { TRANSLATE, LANGUAGE } = props;

    const routeUrl = 'https://www.avioyx.com';
    const shareUrl = routeUrl + '/' + LANGUAGE;
    const imageUrl = routeUrl + "/assets/img/landing/hero.jpg";

    return (

        <Helmet prioritizeSeoTags>

            <html lang={LANGUAGE} />

            <title>{TRANSLATE.seo_title}</title>
            <meta name="description" content={TRANSLATE.seo_description} />

            <meta itemprop="name" content={TRANSLATE.seo_title} />
            <meta itemprop="description" content={TRANSLATE.seo_description} />
            <meta itemprop="image" content={imageUrl} />

            <link rel="canonical" href={routeUrl} />
            <link rel="alternate" href={routeUrl + '/en'} hrefLang='x-default' />
            <link rel="alternate" href={routeUrl + '/it'} hrefLang='it' />
            <link rel="alternate" href={routeUrl + '/fr'} hrefLang='fr' />
            <link rel="alternate" href={routeUrl + '/es'} hrefLang='es' />
            <link rel="alternate" href={routeUrl + '/de'} hrefLang='de' />
            <link rel="alternate" href={routeUrl + '/ar'} hrefLang='ar' />
            <link rel="alternate" href={routeUrl + '/ja'} hrefLang='ja' />
            <link rel="alternate" href={routeUrl + '/zh'} hrefLang='zh' />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@publisher_handle" />
            <meta name="twitter:title" content={TRANSLATE.seo_title} />
            <meta name="twitter:description" content={TRANSLATE.seo_description} />
            <meta name="twitter:creator" content="@avioyx" />
            <meta name="twitter:image:src" content={imageUrl} />

            <meta property="og:title" content={TRANSLATE.seo_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={shareUrl} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:description" content={TRANSLATE.seo_description} />
            <meta property="og:site_name" content="avioyx" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta property='fb:app_id' content='236904211103455' />

            <meta itemprop="thumbnailUrl" content={imageUrl} />
            <meta itemprop="image_src" content={imageUrl} />
            <meta itemprop="image" content={imageUrl} />

        </Helmet>
    )
}



export default Dashboard