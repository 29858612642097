import React, { useEffect, useState, useRef } from 'react';

import Messages from './Messages'
import Notifications from './Notifications'

const Container = (props) => {

    const { match } = props;

    const [Type, setType] = useState('inbox');

    return (
        <>
           
            
            {/* <ChatWindow /> */}

            <div className='w-full flex h-[50px] border-b border-zinc-200 sticky top-0 bg-white z-50 from-bottom'>

                {Type === 'inbox' ? (
                    <>
                        <div className='w-1/2 font-semibold h-full border-b-2 border-zinc-700 flex items-center justify-center' onClick={() => setType('inbox')}>
                            Messages
                            {/* <div className='w-3 h-3 bg-avioyx-100 rounded-full -translate-y-2' /> */}
                        </div>
                        <div className='w-1/2 font-semibold h-full border-b-2 border-white flex items-center justify-center' onClick={() => setType('notifications')}>
                            Notifications
                            {/* <div className='w-3 h-3 bg-avioyx-100 rounded-full -translate-y-2' /> */}
                        </div>
                    </>
                ) : (Type === 'notifications') ? (
                    <>
                        <div className='w-1/2 font-semibold h-full border-b-2 border-white flex items-center justify-center' onClick={() => setType('inbox')}>
                            Messages
                            {/* <div className='w-3 h-3 bg-avioyx-100 rounded-full -translate-y-2' /> */}
                        </div>
                        <div className='w-1/2 font-semibold h-full border-b-2 border-zinc-700 flex items-center justify-center' onClick={() => setType('notifications')}>
                            Notifications
                            {/* <div className='w-3 h-3 bg-avioyx-100 rounded-full -translate-y-2' /> */}
                        </div>
                    </>
                ) : (
                    <></>
                )}

            </div>

            <div className="w-full h-full overflow-auto">

                {Type === 'inbox' ? (
                    <Messages {...props} />
                ) : (Type === 'notifications') ? (
                    <Notifications {...props} />
                ) : (
                    <></>
                )}

            </div>



        </>
    )
}


export default Container