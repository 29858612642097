import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';

import SRC_LOGO_ICON from '../../assets/img/logo_icon.png';
import SRC_CALENDAR from '../../assets/img/icons/calendar.svg';
import SRC_TIME from '../../assets/img/icons/time.svg';
import SRC_AIRCRAFTS from '../../assets/img/icons/aircrafts.svg';
import SRC_SEARCH from '../../assets/img/icons/search.svg';

const Flights = (props) => {

    const {  LANGUAGE, TRANSLATE, homelang } = props;

    return (
        <>
            <HeaderSEO {...props}
                LANGUAGE={homelang || LANGUAGE}

            />

            <div className='w-full flex gap-x-4'>
                <div className='w-full flex flex-col gap-y-4 pb-10'>
                    <div className='w-full input-group with-icon mb-2'>
                        <img className='input-icon' src={SRC_SEARCH} />
                        <input type='text' placeholder='Search Flights' />
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                        <div className='flex w-full justify-between items-center'>
                            <div className='text-lg font-semibold flex-shrink-0'>SFO</div>
                            <div className='w-full h-[2px] bg-black mx-6 relative'>
                                <div className='absolute left-0 top-0 h-full w-[44%] bg-sky-500'>
                                    <div className='w-[10px] h-[10px] rounded-full bg-sky-500 absolute right-0 -top-[4px]' />
                                </div>
                                <div className='absolute top-3 w-full flex items-center justify-center opacity-50 text-xs'>
                                    1hr 59min
                                </div>
                            </div>
                            <div className='text-lg font-semibold flex-shrink-0'>SAN</div>
                        </div>
                        <div className='flex gap-x-10 pt-6 mt-6 justify-between'>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                                01/12/2023
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_AIRCRAFTS} />
                                I-CNTR - Cessna - C152
                            </div>
                            <div className='text-xs px-4 py-1 bg-sky-500 rounded-full'>Upcoming</div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                        <div className='flex w-full justify-between items-center'>
                            <div className='text-lg font-semibold flex-shrink-0'>SFO</div>
                            <div className='w-full h-[2px] bg-black mx-6 relative'>
                                <div className='absolute left-0 top-0 h-full w-[100%] bg-sky-500'>
                                    <div className='w-[10px] h-[10px] rounded-full bg-sky-500 absolute right-0 -top-[4px]' />
                                </div>
                                <div className='absolute top-3 w-full flex items-center justify-center opacity-50 text-xs'>
                                    1hr 59min
                                </div>
                            </div>
                            <div className='text-lg font-semibold flex-shrink-0'>SAN</div>
                        </div>
                        <div className='flex gap-x-10 pt-6 mt-6 justify-between'>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                                01/12/2023
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_AIRCRAFTS} />
                                I-CNTR - Cessna - C152
                            </div>
                            <div className='text-xs px-4 py-1 bg-sky-500 rounded-full opacity-0'>Complete</div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                        <div className='flex w-full justify-between items-center'>
                            <div className='text-lg font-semibold flex-shrink-0'>SFO</div>
                            <div className='w-full h-[2px] bg-black mx-6 relative'>
                                <div className='absolute left-0 top-0 h-full w-[100%] bg-sky-500'>
                                    <div className='w-[10px] h-[10px] rounded-full bg-sky-500 absolute right-0 -top-[4px]' />
                                </div>
                                <div className='absolute top-3 w-full flex items-center justify-center opacity-50 text-xs'>
                                    1hr 59min
                                </div>
                            </div>
                            <div className='text-lg font-semibold flex-shrink-0'>SAN</div>
                        </div>
                        <div className='flex gap-x-10 pt-6 mt-6 justify-between'>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                                01/12/2023
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_AIRCRAFTS} />
                                I-CNTR - Cessna - C152
                            </div>
                            <div className='text-xs px-4 py-1 bg-sky-500 rounded-full opacity-0'>Complete</div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                        <div className='flex w-full justify-between items-center'>
                            <div className='text-lg font-semibold flex-shrink-0'>SFO</div>
                            <div className='w-full h-[2px] bg-black mx-6 relative'>
                                <div className='absolute left-0 top-0 h-full w-[100%] bg-sky-500'>
                                    <div className='w-[10px] h-[10px] rounded-full bg-sky-500 absolute right-0 -top-[4px]' />
                                </div>
                                <div className='absolute top-3 w-full flex items-center justify-center opacity-50 text-xs'>
                                    1hr 59min
                                </div>
                            </div>
                            <div className='text-lg font-semibold flex-shrink-0'>SAN</div>
                        </div>
                        <div className='flex gap-x-10 pt-6 mt-6 justify-between'>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                                01/12/2023
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_AIRCRAFTS} />
                                I-CNTR - Cessna - C152
                            </div>
                            <div className='text-xs px-4 py-1 bg-sky-500 rounded-full opacity-0'>Complete</div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                        <div className='flex w-full justify-between items-center'>
                            <div className='text-lg font-semibold flex-shrink-0'>SFO</div>
                            <div className='w-full h-[2px] bg-black mx-6 relative'>
                                <div className='absolute left-0 top-0 h-full w-[100%] bg-sky-500'>
                                    <div className='w-[10px] h-[10px] rounded-full bg-sky-500 absolute right-0 -top-[4px]' />
                                </div>
                                <div className='absolute top-3 w-full flex items-center justify-center opacity-50 text-xs'>
                                    1hr 59min
                                </div>
                            </div>
                            <div className='text-lg font-semibold flex-shrink-0'>SAN</div>
                        </div>
                        <div className='flex gap-x-10 pt-6 mt-6 justify-between'>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_CALENDAR} />
                                01/12/2023
                            </div>
                            <div className='flex items-center text-xs opacity-50'>
                                <img className='mr-2 w-[18px]' src={SRC_AIRCRAFTS} />
                                I-CNTR - Cessna - C152
                            </div>
                            <div className='text-xs px-4 py-1 bg-sky-500 rounded-full opacity-0'>Complete</div>
                        </div>
                    </div>
                </div>
                <div className='rounded-lg sticky  w-[300px] flex-shrink-0 bg-avioyx-100 p-4 flex items-center justify-center mb-auto'>
                    <span className='py-10'>Calendar</span>
                </div>

            </div>
        </>
    )
}


const HeaderSEO = (props) => {

    const { TRANSLATE, LANGUAGE } = props;

    const routeUrl = 'https://www.avioyx.com';
    const shareUrl = routeUrl + '/' + LANGUAGE;
    const imageUrl = routeUrl + "/assets/img/landing/hero.jpg";

    return (

        <Helmet prioritizeSeoTags>

            <html lang={LANGUAGE} />

            <title>{TRANSLATE.seo_title}</title>
            <meta name="description" content={TRANSLATE.seo_description} />

            <meta itemprop="name" content={TRANSLATE.seo_title} />
            <meta itemprop="description" content={TRANSLATE.seo_description} />
            <meta itemprop="image" content={imageUrl} />

            <link rel="canonical" href={routeUrl} />
            <link rel="alternate" href={routeUrl + '/en'} hrefLang='x-default' />
            <link rel="alternate" href={routeUrl + '/it'} hrefLang='it' />
            <link rel="alternate" href={routeUrl + '/fr'} hrefLang='fr' />
            <link rel="alternate" href={routeUrl + '/es'} hrefLang='es' />
            <link rel="alternate" href={routeUrl + '/de'} hrefLang='de' />
            <link rel="alternate" href={routeUrl + '/ar'} hrefLang='ar' />
            <link rel="alternate" href={routeUrl + '/ja'} hrefLang='ja' />
            <link rel="alternate" href={routeUrl + '/zh'} hrefLang='zh' />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@publisher_handle" />
            <meta name="twitter:title" content={TRANSLATE.seo_title} />
            <meta name="twitter:description" content={TRANSLATE.seo_description} />
            <meta name="twitter:creator" content="@avioyx" />
            <meta name="twitter:image:src" content={imageUrl} />

            <meta property="og:title" content={TRANSLATE.seo_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={shareUrl} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:description" content={TRANSLATE.seo_description} />
            <meta property="og:site_name" content="avioyx" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta property='fb:app_id' content='236904211103455' />

            <meta itemprop="thumbnailUrl" content={imageUrl} />
            <meta itemprop="image_src" content={imageUrl} />
            <meta itemprop="image" content={imageUrl} />

        </Helmet>
    )
}



export default Flights