import React from "react";
import { Helmet } from "react-helmet-async";

const Notifications = (props) => {

    const {LANGUAGE} = props;

    return (
        <div className='p-3 flex flex-col flex-shrink-0 from-bottom'>
            <Helmet prioritizeSeoTags>
                <html lang={LANGUAGE} />
                <title>{'Avioyx - Notifications'}</title>
            </Helmet>
            <div className="flex px-0 py-3 items-center relative rounded-lg bg-white">
                <img
                    className="rounded-full mr-4 flex-shrink-0 object-cover w-[60px] h-[60px] border border-zinc-200"
                    src='https://images.unsplash.com/photo-1623366302587-b38b1ddaefd9?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cG9ydHJhaXQlMjBtYW58ZW58MHx8MHx8fDA%3D'
                    alt=""
                />
                <div className="w-full text-sm">
                    <div className="mb-1">
                        <strong>John Doe</strong> accepted your <strong>booking request</strong>
                    </div>
                    <div className='text-xs text-gray-500 font-normal'>
                        5 Minutes Ago
                    </div>
                </div>
            </div>

            <div className="flex px-0 py-3 items-center relative rounded-lg bg-white">
                <img
                    className="rounded-full flex-shrink-0 mr-4 object-cover w-[60px] h-[60px] border border-zinc-200"
                    src='https://images.unsplash.com/photo-1580489944761-15a19d654956?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cG9ydHJhaXQlMjB3b21hbnxlbnwwfHwwfHx8MA%3D%3D'
                    alt=""
                />
                <div className="w-full text-sm">
                    <div className="mb-1">
                        <strong>Jessica Alba</strong> requested 4 sessions of <strong>puppy obediance training</strong>
                    </div>
                    <div className='text-xs text-gray-500 font-normal'>
                        5 Minutes Ago
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notifications