import React from "react";

const Guide = (props) => {

    const {onClose, LANGUAGE, TRANSLATE, history} = props;
    
    const onSelectMore = () => {
        onClose();
        history.push('/'+LANGUAGE+'/how-it-works')
    }
    return ( 
        <>
            <div className="fixed top-0 left-0 bottom-0 right-0 z-[9999] flex items-end md:items-end drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]">

                <div className= "w-full h-full md:h-auto relative z-20 fade-in md:from-bottom bg-white">
                    <div className="mx-auto md:bg-transparent flex flex-col bg-white h-full w-full md:h-auto md:w-auto md:rounded-none rounded-lg m-auto max-w-8xl px-4 sm:px-6 lg:px-8 md:pb-10 pt-10 overflow-auto md:overflow-hidden">
                        <div className="flex items-center w-full justify-between md:mb-10 md:mt-0 fixed md:relative bottom-0 left-0 md:py-0 py-4 bg-white z-[9999]">
                            <span className="font-semibold text-lg cursor-pointer hidden md:inline">
                                {TRANSLATE.wd_howitworks}
                            </span>
                            <div className="flex items-center w-full md:w-auto justify-between relative md:justify-start md:px-0 px-8">
                                <div className="text-avioyx-100 cursor-pointer" onClick={()=> onSelectMore()}>
                                    {TRANSLATE.wd_learnmore}
                                </div>
                                <div className="btn-auto px-10 md:ml-6" onClick={()=> onClose()}>
                                   {TRANSLATE.wd_how_guide.cta}
                                </div>
                            </div>
                        </div>

                        <div className="flex md:flex-row flex-col gap-x-8 md:order-2 order-1">
                            <span className="font-semibold text-xl cursor-pointer md:hidden inline pl-2">
                                {TRANSLATE.wd_howitworks}
                            </span>
                            <div className="w-full md:w-1/4 md:text-center md:block flex items-center">
                                <img className="w-[140px] md:w-full" src="/assets/img/guide-1.jpg" />
                                <div className="font-semibold pl-4 md:pl-0">
                                    {TRANSLATE.wd_how_guide.a}
                                </div>
                            </div>
                            <img className="hidden md:block" src="/assets/img/chevron-right.svg" />
                            <div className="w-full md:w-1/4 md:text-center md:block flex items-center">
                                <img className="w-[140px] md:w-full" src="/assets/img/guide-2.jpg" />
                                <div className="font-semibold pl-4 md:pl-0">
                                    {TRANSLATE.wd_how_guide.b}
                                </div>
                            </div>
                            <img className="hidden md:block" src="/assets/img/chevron-right.svg" />
                            <div className="w-full md:w-1/4 md:text-center md:block flex items-center">
                                <img className="w-[140px] md:w-full" src="/assets/img/guide-3.jpg" />
                                <div className="font-semibold pl-4 md:pl-0">
                                    {TRANSLATE.wd_how_guide.c}
                                </div>
                            </div>
                            <img className="hidden md:block" src="/assets/img/chevron-right.svg" />
                            <div className="w-full md:w-1/4 md:text-center md:block flex items-center">
                                <img className="w-[140px] md:w-full" src="/assets/img/guide-4.jpg" />
                                <div className="font-semibold pl-4 md:pl-0">
                                    {TRANSLATE.wd_how_guide.d}
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}

export default Guide
