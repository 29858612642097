import React, { useEffect, useState } from 'react';

import SRC_SEND from '../../assets/img/send-message.svg';

import SRC_BACK from '../../assets/img/back.svg';
import { Helmet } from 'react-helmet-async';
import { postApiPrivate } from '../../api';

import SRC_LOADER from '../../assets/img/loader.png';
import moment from 'moment';

import SRC_GROOM from '../../assets/img/groom.svg';
import SRC_STAY from '../../assets/img/dog-stay.svg';
import SRC_WALKING from '../../assets/img/mobile-bar/walking.svg';
import SRC_TRAINING from '../../assets/img/mobile-bar/training.svg';

import ChatAlpha from '../General/ChatAlpha';
import ChatCustomer from '../General/ChatCustomer';
import ChatProvider from '../General/ChatProvider';

const Messages = (props) => {

    const { LANGUAGE, history, match } = props;

    const [conversations, setConversations] = useState([]);
    const [SearchChat, setSearchChat] = useState(false);
    const [SearchProvider, setSearchProvider] = useState(null);
    const [SearchCustomer, setSearchCustomer] = useState(null);
    const [SearchOther, setSearchOther] = useState(null);

    const [isLoading, setLoading] = useState(false);


    useEffect(() => {

        onFetchConversations();

    }, []);

    useEffect(() => {

        if (match.params.userId) {
            //Normal Chat
            setSearchChat('normal');
            return
        }
        else{

            if (match.params.storeId) {
                //Customer Chat
                if (match.params.customerId) {
                    //Store Chat
                    setSearchChat('store');
                    return
                }
                setSearchChat('customer');
                return
            }

        }
       
        setSearchProvider(null);
        setSearchCustomer(null);
        setSearchChat(null);

    }, [match.params.storeId, match.params.customerId, match.params.userId])

    const onFetchConversations = async () => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        let FORM = {

        }

        setLoading(true);

        try {

            const DATA_RESPONSE = await postApiPrivate('/inbox/all', FORM, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API LOADING SINGLE ORDER", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setConversations(DATA_RESPONSE.data);
            setLoading(false);


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }


    }


    const onSelectConversation = (conversation) => {

        const conversation_type = conversation.type;

        // alert(JSON.stringify(conversation, null, 2))
        // return 

        if (conversation_type === 'customer') {
            const store = conversation.store;
            setSearchProvider(store);
            history.push(`/${LANGUAGE}/inbox/provider/${conversation?.store?.id}/chat`)
        }
        else if (conversation_type === 'store') {
            const customer = conversation.customer;
            setSearchCustomer(customer);
            history.push(`/${LANGUAGE}/inbox/provider/${conversation?.store}/${conversation?.customer?.id}/chat`)
        }
        else if (conversation_type === 'self') {
            const other = conversation.other;
            setSearchOther(other);
            history.push(`/${LANGUAGE}/inbox/${conversation?.other?.id}/chat`)
        }
        else if (conversation_type === 'other') {
            const customer = conversation.customer;
            setSearchOther(customer);
            history.push(`/${LANGUAGE}/inbox/${conversation?.customer?.id}/chat`)
        }

    };

    const onCloseChat = () => {
        if (typeof history.goBack === 'function') {
            history.goBack();
        } else {
            // history.replace(`/${LANGUAGE}/search/dog-training/provider/${SearchProvider.id}`);
        }
    }

    if (isLoading) {
        return (
            <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
                <img className='animate-spin w-[24px] h-[24px]' src={SRC_LOADER} alt="" />
            </div>
        )
    }

    const sharedProps = {
        ...props
    }

    return (
        <div className='p-3'>

            <Helmet prioritizeSeoTags>
                <html lang={LANGUAGE} />
                <title>{'Avioyx - Inbox'}</title>
            </Helmet>

            <div className='w-full from-bottom'>
                {/* <pre>
                    {JSON.stringify(conversations, null, 2)}
                </pre> */}
                {conversations.map((conversation) => {

                    const conversation_type = conversation.type;

                    if (conversation_type === 'self') {
                        return (
                            <SelfCard
                                {...props}
                                item={conversation}
                                onSelectConversation={() => onSelectConversation(conversation)}
                            />
                        )
                    }
                    else if (conversation_type === 'other') {
                        return (
                            <OtherCard
                                {...props}
                                item={conversation}
                                onSelectConversation={() => onSelectConversation(conversation)}
                            />
                        )
                    }
                    else if (conversation_type === 'customer') {
                        return (
                            <CustomerCard
                                {...props}
                                item={conversation}
                                onSelectConversation={() => onSelectConversation(conversation)}
                            />
                        )
                    }
                    else if (conversation_type === 'store') {
                        return (
                            <StoreCard
                                {...props}
                                item={conversation}
                                onSelectConversation={() => onSelectConversation(conversation)}
                            />
                        )
                    }

                })}

            </div>
            {(SearchChat === 'customer') && (
                <ChatCustomer {...sharedProps}
                    onClose={() => onCloseChat()}
                    PROVIDER={SearchProvider}

                />
            )}
            {(SearchChat === 'store') && (
                <ChatProvider {...sharedProps}
                    onClose={() => onCloseChat()}
                    CUSTOMER={SearchCustomer}

                />
            )}
            {(SearchChat === 'normal') && (
                <ChatAlpha {...sharedProps}
                    onClose={() => onCloseChat()}
                    OTHER={SearchOther}

                />
            )}
        </div>
    );
};

const SelfCard = (props) => {

    const { item, onSelectConversation } = props;

    const conversation = item || {};
    const date = conversation.date || null;
    const dog = conversation.dog || null;
    const other = conversation.other || null;
    const message = conversation.message || null;
    const inbox_date = moment(date).fromNow();

    return (
        <>
            <div className="flex px-0 py-3 items-center relative rounded-lg bg-white" onClick={() => onSelectConversation(item)}>
                {other && (
                    <img
                        className="rounded-full mr-4 object-cover w-[60px] h-[60px] border border-zinc-200"
                        src={other.img}
                        alt={other.name}
                    />
                )}
                {dog && (
                    <img
                        className="rounded-full mr-4 object-cover w-[40px] h-[40px] border border-zinc-200"
                        src={dog.img}
                        alt={dog.name}
                    />
                )}

                <div className="w-full">
                    <div className="font-bold mb-1 flex justify-between">
                        {other.name}
                        <div className='text-xs text-gray-500 font-normal'>
                            {inbox_date}
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-x-4 sm:items-center">
                        <div className="font-regular text-sm block">
                            {message.text}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const OtherCard = (props) => {

    const { item, onSelectConversation } = props;

    const conversation = item || {};
    const date = conversation.date || null;
    const dog = conversation.dog || null;
    const customer = conversation.customer || null;
    const message = conversation.message || null;
    const inbox_date = moment(date).fromNow();

    return (
        <>
            <div className="flex px-0 py-3 items-center relative rounded-lg bg-white" onClick={() => onSelectConversation(item)}>
                {customer && (
                    <img
                        className="rounded-full mr-4 object-cover w-[60px] h-[60px] border border-zinc-200"
                        src={customer.img}
                        alt={customer.name}
                    />
                )}
                {dog && (
                    <img
                        className="rounded-full mr-4 object-cover w-[40px] h-[40px] border border-zinc-200"
                        src={dog.img}
                        alt={dog.name}
                    />
                )}

                <div className="w-full">
                    <div className="font-bold mb-1 flex justify-between">
                        {customer.name}
                        <div className='text-xs text-gray-500 font-normal'>
                            {inbox_date}
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-x-4 sm:items-center">
                        <div className="font-regular text-sm block">
                            {message.text}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const CustomerCard = (props) => {

    const { item, onSelectConversation } = props;

    const conversation = item || {};
    const date = conversation.date || null;
    const dog = conversation.dog || null;
    const store = conversation.store || null;
    const message = conversation.message || null;

    const inbox_date = moment(date).fromNow();

    return (
        <>
            <div className="flex px-0 py-3 items-center relative rounded-lg bg-white" onClick={() => onSelectConversation(item)}>
                {store && (
                    <img
                        className="rounded-full mr-4 object-cover w-[60px] h-[60px] border border-zinc-200"
                        src={store.img}
                        alt={store.name}
                    />
                )}
                {dog && (
                    <img
                        className="rounded-full mr-4 object-cover w-[40px] h-[40px] border border-zinc-200"
                        src={dog.img}
                        alt={dog.name}
                    />
                )}

                <div className="w-full">
                    <div className="font-bold mb-1 flex justify-between">
                        {store.name}
                        <div className='text-xs text-gray-500 font-normal'>
                            {inbox_date}
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-x-4 sm:items-center">
                        <div className="font-regular text-sm block">
                            {message.text}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const StoreCard = (props) => {

    const { item, USER, onSelectConversation } = props;

    const conversation = item || {};
    const date = conversation.date || null;
    const customer = conversation.customer || null;
    const dog = conversation.dog || null;
    const store = conversation.store || null;
    const message = conversation.message || null;

    const inbox_date = moment(date).fromNow();

    const Training = USER.training || null;
    const Walking = USER.walking || null;
    const Grooming = USER.grooming || null;
    const Sitting = USER.sitting || null;

    let MatchedStore = null;
    let MatchedIcon = null;

    if (Training && store === Training.id) {
        MatchedStore = Training;
        MatchedIcon = SRC_TRAINING;
    }
    if (Walking && store === Walking.id) {
        MatchedStore = Walking;
        MatchedIcon = SRC_WALKING;
    }
    if (Grooming && store === Grooming.id) {
        MatchedStore = Grooming;
        MatchedIcon = SRC_GROOM;
    }
    if (Sitting && store === Sitting.id) {
        MatchedStore = Sitting;
        MatchedIcon = SRC_STAY;
    }


    return (
        <>
            <div className="flex px-0 py-3 items-center relative rounded-lg bg-white" onClick={() => onSelectConversation(item)}>
                {customer && (
                    <img
                        className="rounded-full mr-4 object-cover w-[60px] h-[60px] border border-zinc-200"
                        src={customer.img}
                        alt={customer.name}
                    />
                )}
                {dog && (
                    <img
                        className="rounded-full mr-4 object-cover w-[40px] h-[40px] border border-zinc-200"
                        src={dog.img}
                        alt={dog.name}
                    />
                )}

                {MatchedStore && (
                    <img
                        className="rounded-full mr-4 object-cover w-[40px] h-[40px] border border-zinc-200"
                        src={MatchedStore.photo}
                        alt={MatchedStore.name}
                    />
                )}

                {MatchedIcon && (
                    <img
                        className="rounded-full mr-4 object-cover w-[30px] h-[30px] border border-zinc-200"
                        src={MatchedIcon}
                        alt={MatchedStore.name}
                    />
                )}


                <div className="w-full">
                    <div className="font-bold mb-1 flex justify-between">
                        {customer.name}
                        <div className='text-xs text-gray-500 font-normal'>
                            {inbox_date}
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-x-4 sm:items-center">
                        <div className="font-regular text-sm block">
                            {message.text}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
// const ChatWindow = (props) => {

//     const { onClose } = props;

//     return (
//         <div className="h-full w-full flex flex-col p-0 fixed top-0 left-0 bottom-0 right-0 z-[9999] bg-white from-bottom">
//             <div className="h-[70px] w-full flex px-3 items-center justify-start flex-shrink-0 bg-white border-b border-zinc-200">
//                 <img className="mr-2" src={SRC_BACK} onClick={() => onClose()} />
//                 <img className='w-[40px] h-[40px] object-cover rounded-full flex-shrink-0 border border-zinc-200 mr-2' src='https://images.unsplash.com/photo-1580489944761-15a19d654956?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8d29tYW4lMjBwb3J0cmFpdHxlbnwwfHwwfHx8MA%3D%3D' onClick={() => onClose()} />
//                 <div className="font-bold">Ahmed</div>
//                 <div className='flex items-center ml-auto text-sm'>
//                     <button className='bg-slate-100 rounded-full text-black px-4 py-3 mr-2'>Block</button>
//                 </div>
//             </div>

//             <div className="w-full h-full overflow-auto relative flex flex-col-reverse">

//                 <div className='flex w-full justify-end my-2 px-2'>
//                     <div className='bg-avioyx-100 text-white rounded-xl px-4 py-2 max-w-[80%]'>
//                         Hello this is me Tomas! BOTTOM!
//                         <div className='w-full text-xs opacity-40'>16:37</div>
//                     </div>
//                 </div>
//                 <div className='flex w-full justify-start my-2 px-2'>
//                     <div className='bg-slate-100 text-black rounded-xl px-4 py-2 max-w-[80%]'>
//                         How was your trip to Hong Kong? Lorem ipsum dolor sit amet lorem pisunm!!!
//                         <div className='w-full text-xs opacity-40'>16:40</div>
//                     </div>
//                 </div>
//                 <div className='flex w-full justify-start my-2 px-2'>
//                     <div className='bg-slate-100 text-black rounded-xl px-4 py-2 max-w-[80%]'>
//                         Hey how are you doing?
//                         <div className='w-full text-xs opacity-40'>16:39</div>
//                     </div>
//                 </div>
//                 <div className='flex w-full justify-start my-2 px-2'>
//                     <div className='bg-slate-100 text-black rounded-xl px-4 py-2 max-w-[80%]'>
//                         How was your trip to Hong Kong? Lorem ipsum dolor sit amet lorem pisunm!!!
//                         <div className='w-full text-xs opacity-40'>16:40</div>
//                     </div>
//                 </div>
//                 <div className='flex w-full justify-end my-2 px-2'>
//                     <div className='bg-avioyx-100 text-white rounded-xl px-4 py-2 max-w-[80%]'>
//                         It was amazing! Lots of beer and fun. Lorem ipsum dolor sit amet lorem ipsum dolor sit and whatever!
//                         <div className='w-full text-xs opacity-40'>16:37</div>
//                     </div>
//                 </div>
//                 <div className='flex w-full justify-end my-2 px-2'>
//                     <div className='bg-avioyx-100 text-white rounded-xl px-4 py-2 max-w-[80%]'>
//                         Hello this is me Tomas!
//                         <div className='w-full text-xs opacity-40'>16:37</div>
//                     </div>
//                 </div>
//                 <div className='flex w-full justify-start my-2 px-2'>
//                     <div className='bg-slate-100 text-black rounded-xl px-4 py-2 max-w-[80%]'>
//                         Hey how are you doing?
//                         <div className='w-full text-xs opacity-40'>16:39</div>
//                     </div>
//                 </div>
//                 <div className='flex w-full justify-start my-2 px-2'>
//                     <div className='bg-slate-100 text-black rounded-xl px-4 py-2 max-w-[80%]'>
//                         How was your trip to Hong Kong? Lorem ipsum dolor sit amet lorem pisunm!!!
//                         <div className='w-full text-xs opacity-40'>16:40</div>
//                     </div>
//                 </div>
//                 <div className='flex w-full justify-end my-2 mb-auto px-2'>
//                     <div className='bg-avioyx-100 text-white rounded-xl px-4 py-2 max-w-[80%]'>
//                         This is last message.TOP!
//                         <div className='w-full text-xs opacity-40'>16:37</div>
//                     </div>
//                 </div>
//             </div>
//             <div className='bg-white p-2 w-full flex-shrink-0 sticky bottom-0'>
//                 <input type='text' placeholder='Type here' className='bg-slate-200 w-full focus:ring-0 focus:outline-none focus:bg-slate-200 rounded-full h-[55px] px-6' />
//                 <button className='w-[50px] h-[50px] rounded-full flex items-center justify-center absolute top-[10px] right-[10px]'>
//                     <img src={SRC_SEND} />
//                 </button>
//             </div>
//         </div>
//     )
// }

export default Messages;
