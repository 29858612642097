import React, {useState} from 'react';
import axios from 'axios';

import {API_ROOT} from '../../api';
import {DefaultAlert} from '../../alerts';

import {Helmet} from 'react-helmet-async';

import { connect } from 'react-redux';
import { onUpdateLanguage } from '../../actions';


const AuthRecovery = (props) => {

    const {TRANSLATE, LANGUAGE} = props;

    const [EMAIL, setEmail] = useState('');
    const [isLoading, setLoading] = useState(false);
  
    const onKeyUp = (event) =>{
  
        event.preventDefault();
       
  
        if (event.keyCode === 13) {
  
            event.target.blur();
            onRecovery();
            
        }
    }
  
    const onRecovery = () => {
  

      if(!isValidEmail(EMAIL)){
         return DefaultAlert(TRANSLATE.wd_recover_password, TRANSLATE.error_invalid_email)
      }
  
      var form = {
          email: EMAIL,
      }
  
      setLoading(true);
  
      axios.post(API_ROOT+'/users/recovery',form).then(response => {
              
          setEmail('');
          setLoading(false);
  
          return DefaultAlert(TRANSLATE.wd_recover_password, TRANSLATE.alert_recovery_success)
   
       })
       .catch(error => {
  
           setLoading(false);
  
           if(!error || !error.response){
               return DefaultAlert(TRANSLATE.wd_recover_password, TRANSLATE.error_invalid_email)
           }
       
           return DefaultAlert(TRANSLATE.wd_recover_password, TRANSLATE.error_invalid_email)
       });
    }
    

    return ( 
        <>
            <Helmet prioritizeSeoTags>

                    <html lang={LANGUAGE} />
            
                    <title>{'Avioyx - '+TRANSLATE.wd_recover_password}</title>
                       
            </Helmet>

            <div className="w-full">
                <div className="mx-auto max-w-xl px-4 sm:px-6 lg:px-8 pb-10 pt-10 flex flex-col flex-wrap md:flex-nowrap items-start">

                    <div className="w-full pr-0 pt-4 md:pt-0 mb-10">
                        <h1 className="text-3xl md:text-4xl font-normal mb-2">
                            {TRANSLATE.auth_recovery.a}
                        </h1>
                        <p className="text-sm">
                            {TRANSLATE.auth_recovery.b}
                        </p>

                    </div>
                    <div className="flex flex-col w-full drop-shadow-2xl bg-white p-8 rounded-2xl">

                        <div className="input-group w-full">
                            <label>{TRANSLATE.wd_email} <span className="text-avioyx-100">*</span></label>
                            <input type="text" placeholder={TRANSLATE.tool_enter_email}
                                   name="email" 
                                   value={EMAIL} 
                                   onChange={(e)=> setEmail(e.target.value)} 
                                   onKeyUp={(e)=> onKeyUp(e)}
                            
                            />
                        </div>

                        {isLoading ? (
                            <div className='w-full h-[150] flex items-center justify-center'>
                                <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader.png' />
                            </div>
                        ) : (
                            <div className="w-full flex items-center justify-between">
                                <button className="btn-auto ml-auto px-10 py-4 w-full flex items-center justify-center"  onClick={()=> onRecovery()}>
                                    {TRANSLATE.btn_submit}
                                </button>
                            </div>
                        )}

                    </div>

                </div>
            </div>
        </>
    )
}


const isValidEmail = (email) => {

	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
  
}

const mapStateToProps = state => {
  return ({
    USER: state.user,
    PROVIDER: state.provider,
    CART: state.cart,
    PRODUCT: state.product,
    PRODUCTS: state.products,
    SEARCH: state.search
  });
}

const mapDispatchToProps = dispatch => ({
  onChangeLanguage: (lang) => dispatch(onUpdateLanguage(lang))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthRecovery);
