import React, {useState} from "react";
import { connect } from 'react-redux';
import { onUpdateCart } from "../../actions";



const Language = (props) => {

    const {onClose, theme, LANGUAGE, CURRENCY, match, history, SEARCH, TRANSLATE, setCurrency, onUpdateCart} = props;

    const [lang, setLang] = useState(LANGUAGE);
    const [curr, setCurr] = useState(CURRENCY);

    const onSaveUpdates = () => {

       // alert("OLD LANGUAGE = "+LANGUAGE+' ---> NEW LANGUAGE ='+lang);

        // alert(JSON.stringify(SEARCH, null, 2))
        // return 

        onClose();
        const CURRENT_URL = match.url;
        const CURRENT_PARAMS = match.params;
        const CURRENT_SEARCH = window.location.search;

        // alert(JSON.stringify({
        //     CURRENT_URL,
        //     CURRENT_PARAMS,
        //     CURRENT_SEARCH
        // }, null, 2))

        // return 
        
        let CHANGED_URL = '';
        
        const isSearchPage = CURRENT_URL.includes('/search/');
        const isFlowPage = CURRENT_URL.includes('/flow/');
        const isCheckoutPage = CURRENT_URL.includes('/checkout/')

        //HANDLE LANGUAGE
        if(isSearchPage){

            const newSearchUrl = SEARCH[lang+'_url'];
            CHANGED_URL = newSearchUrl;

            if(CURRENT_SEARCH){
                CHANGED_URL = newSearchUrl+''+CURRENT_SEARCH
            }
        }
        else{

            if(isFlowPage){

                const newSearchUrl = CURRENT_URL.replace('/'+LANGUAGE, '/'+lang)
                CHANGED_URL = newSearchUrl;

                if(CURRENT_SEARCH){
                    CHANGED_URL = newSearchUrl+''+CURRENT_SEARCH
                }
            }
            else{

                if(!CURRENT_PARAMS.lang){
                    CHANGED_URL = '/'+lang;
                }
                else{
                    CHANGED_URL = CURRENT_URL.replace('/'+LANGUAGE, '/'+lang)
                }

            }

        }

        //HANDLE CURRENCY
        if(CURRENCY !== curr){
            setCurrency(curr);
            onUpdateCart([]);

            if(isCheckoutPage){
                history.push('/'+lang)
                return
            }
        }
        
        
        history.push(CHANGED_URL);
    }

    const ActiveColor = (theme === 'provider') ? 'bg-avioyx-100 text-white border-0' : 'bg-avioyx-100 text-white border-0';

    return (
        <>
            <div className="fixed w-screen h-screen top-0 left-0 z-[9999] flex items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]">
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />

                <div className="mx-auto w-full max-w-[500px] px-4 sm:px-6 lg:px-8 pb-8 pt-8 bg-white rounded-2xl relative z-20 mr-3 ml-3">
                    <img className="absolute top-6 right-6 cursor-pointer" src="/assets/img/close.svg" onClick={()=> onClose()} />
                    <div className="flex flex-col">
                        <h3 className="text-lg font-semibold mb-2">Language</h3>
                        
                        <div className="flex flex-wrap w-full gap-4">
                            <div className={"pill "+(lang === 'en' ? ActiveColor : '')} onClick={()=> setLang('en')}>English 🇬🇧</div>
                            <div className={"pill "+(lang === 'it' ? ActiveColor : '')} onClick={()=> setLang('it')}>Italiano 🇮🇹</div>
                            <div className={"pill "+(lang === 'fr' ? ActiveColor : '')} onClick={()=> setLang('fr')}>Français 🇫🇷</div>
                            <div className={"pill "+(lang === 'es' ? ActiveColor : '')} onClick={()=> setLang('es')}>Espanol 🇪🇸</div>
                            {/* <div className={"pill "+(lang === 'de' ? ActiveColor : '')} onClick={()=> setLang('de')}>German 🇩🇪</div>
                            <div className={"pill "+(lang === 'ar' ? ActiveColor : '')} onClick={()=> setLang('ar')}>Arabic 🇸🇦</div>
                            <div className={"pill "+(lang === 'ja' ? ActiveColor : '')} onClick={()=> setLang('ja')}>Japanese 🇯🇵</div>
                            <div className={"pill "+(lang === 'zh' ? ActiveColor : '')} onClick={()=> setLang('zh')}>Chinese 🇨🇳</div> */}
                            
                        </div>
                        <h3 className="text-lg font-semibold mb-2 mt-6">Currency</h3>
                        
                        <div className="flex flex-wrap w-full gap-4">
                            <div className={"pill "+(curr === 'usd' ? ActiveColor : '')} onClick={()=> setCurr('usd')}>$ - USD</div>
                            <div className={"pill "+(curr === 'eur' ? ActiveColor : '')} onClick={()=> setCurr('eur')}>€ - EUR</div>
                            <div className={"pill "+(curr === 'gbp' ? ActiveColor : '')} onClick={()=> setCurr('gbp')}>£ - GBP</div>
                            
                        </div>
                        <hr className="my-6" />
                        <div className="flex items-center justify-between">
                            <div className="cursor-pointer" onClick={()=> onClose()}>
                                {TRANSLATE.btn_back}
                            </div>
                            {theme === 'provider' ? (
                                <div className="btn-auto bg-avioyx-100" onClick={()=> onSaveUpdates()}>
                                    {TRANSLATE.btn_save}
                                </div>
                            ) : (
                                <div className="btn-auto" onClick={()=> onSaveUpdates()}>
                                    {TRANSLATE.btn_save}
                                </div>
                            )}
                            
                        </div>
                    </div>

                </div>

            </div>


        </>
    )
}



const mapStateToProps = state => {
    return ({
      USER: state.user,
      CART: state.cart,
      PROVIDER: state.provider,
      STORES: state.providers,
      PRODUCT: state.product,
      PRODUCTS: state.products,
      TOTAL: state.count,
      SEARCH: state.search,
      GEOS: state.points
    });
}
  
const mapDispatchToProps = dispatch => ({
    onUpdateCart: (cart) => dispatch(onUpdateCart(cart))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Language);