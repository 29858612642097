import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { API_ROOT } from '../../api';
import { DefaultAlert } from '../../alerts';

import { useGoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

import SRC_LOADER from '../../assets/img/loader.png'
import SRC_GOOGLE from '../../assets/img/google.svg'

import { GetRouteSearchParams } from '../../helpers/GeneralHelper';


const AuthFormSignup = (props) => {
    const { TRANSLATE, history, onStartUser, type, onFinishSignup, LANGUAGE, ContainerClass, AsterixClass, ButtonClass, ButtonsClass, GoogleClass, OriginFrom, ContainerStyles } = props;

    const [EMAIL, setEmail] = useState('');
    const [PASSWORD, setPassword] = useState('');
    const [CONFIRMPASSWORD, setConfirmPassword] = useState('');
    const [FULLNAME, setFullname] = useState('');

    const [isLoading, setLoading] = useState(false);

    const onKeyUp = (event) => {

        event.preventDefault();


        if (event.keyCode === 13) {

            event.target.blur();
            onSignup();

        }
    }

    const onSignup = () => {

        const FORM = {
            fullname: FULLNAME.trim(),
            email: EMAIL.trim(),
            password: PASSWORD.trim(),
            confirmpassword: CONFIRMPASSWORD.trim(),
            lang: TRANSLATE.lang,
            type: OriginFrom
        }

        console.log("FORM ===> ", FORM)

        if (!isValidForm(FORM, TRANSLATE)) {
            return
        }


        setLoading(true);

        axios.post(API_ROOT + '/users/signup', FORM).then(response => {

            setLoading(false);

            localStorage.setItem('jwt_token', response.data.jwt_token);

            if (OriginFrom === 'provider') {
                window.location.href = '/' + LANGUAGE + '/onboarding/provider/step1';
            }
            else {
                onStartUser();
                history.push('/' + LANGUAGE + '/settings');
            }

            const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
            if (isNativeApp) {
                const webData = {
                    type: type || 'SIGNUP',
                    data: response.data.jwt_token
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(webData));
            }

        })
            .catch(error => {

                setLoading(false);

                if (!error || !error.response) {
                    return DefaultAlert('Signup', TRANSLATE.error_invalid_credentials)
                }

                var error_data = error.response.data;
                var error_message = error_data.error;

                console.log("ERROR ===> ", error_message);

                if (error_message === 'missing_password') {
                    return DefaultAlert(TRANSLATE.wd_signup, TRANSLATE.error_missing_password)
                }
                else if (error_message === 'missing_email') {
                    return DefaultAlert(TRANSLATE.wd_signup, TRANSLATE.error_invalid_email)
                }
                else if (error_message === 'user_already_exists') {
                    return DefaultAlert(TRANSLATE.wd_signup, TRANSLATE.error_already_exists)
                }

                return DefaultAlert(TRANSLATE.wd_signup, TRANSLATE.error_invalid_credentials)

            });
    }

    const onLoginGoogle = async (params) => {

        if (!params) {
            return DefaultAlert(TRANSLATE.wd_login, TRANSLATE.error_invalid_email)
        }

        setLoading(true);

        if (params.access_token) {
            const Response = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo?access_token=" + params.access_token);
            const ResponseData = Response ? Response.data : {};
            params = {
                ...params,
                ...ResponseData
            }
        }

        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        var FORM = {
            lang: LANGUAGE,
            oauth: params,
            timezone: userTimezone

        }

        axios.post(API_ROOT + '/users/login/google', FORM).then(response => {


            setLoading(false);

            localStorage.setItem('jwt_token', response.data.jwt_token);

            const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
            if (isNativeApp) {
                const webData = {
                    type: 'LOGIN',
                    data: response.data.jwt_token
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(webData));
            }

            onStartUser();


            history.push('/' + LANGUAGE + '/settings');

        })
            .catch(error => {

                setLoading(false);

                if (!error || !error.response) {
                    return DefaultAlert('Login', TRANSLATE.error_invalid_credentials)
                }

                var error_data = error.response.data;
                var error_message = error_data.error;

                if (error_message === 'missing_password') {
                    return DefaultAlert('Login', TRANSLATE.error_missing_password)
                }
                else if (error_message === 'missing_email') {
                    return DefaultAlert('Login', TRANSLATE.error_invalid_email)
                }

                return DefaultAlert('Login', TRANSLATE.error_invalid_credentials)
            });
    }

    return (
        <>

            <div className={ContainerClass} style={ContainerStyles}>
                <div className="w-full flex  bg-zinc-100">

                    <Link to={'/' + LANGUAGE + '/login'} className="w-1/2 py-4 text-center">{TRANSLATE.wd_login}</Link>
                    <div className="w-1/2 text-center py-4 bg-white font-semibold">{TRANSLATE.wd_signup}</div>

                </div>

                <div className="w-full p-6 pb-4">
                    <label class="text-sm font-semibold">{TRANSLATE.wd_full_name} <span className={AsterixClass}>*</span></label>
                    <input type="text" placeholder={TRANSLATE.wd_first_last}
                        name="fullname"
                        value={FULLNAME}
                        onChange={(e) => setFullname(e.target.value)}
                        onKeyUp={(e) => onKeyUp(e)}
                        class="border border-zinc-200 focus:border-zinc-300 h-[50px] w-full outline-0 bg-white ring-0 bg-transparent rounded-lg pl-4 focus:outline-none"

                    />
                </div>
                <div className="w-full p-6 pt-0">
                    <label class="text-sm font-semibold">{TRANSLATE.wd_email} <span className={AsterixClass}>*</span></label>
                    <input type="text" placeholder={TRANSLATE.tool_your_email}
                        name="email"
                        value={EMAIL}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyUp={(e) => onKeyUp(e)}
                        class="border border-zinc-200 focus:border-zinc-300 h-[50px] w-full outline-0 bg-white ring-0 bg-transparent rounded-lg pl-4 focus:outline-none"

                    />
                </div>
                <div className="w-full p-6 pt-0">
                    <label class="text-sm font-semibold">{TRANSLATE.wd_password} <span className={AsterixClass}>*</span></label>
                    <input type="password" placeholder="*************"
                        name="password"
                        value={PASSWORD}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={(e) => onKeyUp(e)}
                        autoComplete="new-password"
                        class="border border-zinc-200 focus:border-zinc-300 h-[50px] w-full outline-0 bg-white ring-0 bg-transparent rounded-lg pl-4 focus:outline-none"

                    />
                </div>
                <div className="w-full p-6 pt-0">
                    <label class="text-sm font-semibold">{TRANSLATE.wd_confirm_password} <span className={AsterixClass}>*</span></label>
                    <input type="password" placeholder="*************"
                        name="confirmpassword"
                        value={CONFIRMPASSWORD}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onKeyUp={(e) => onKeyUp(e)}
                        autoComplete="new-password"
                        class="border border-zinc-200 focus:border-zinc-300 h-[50px] w-full outline-0 bg-white ring-0 bg-transparent rounded-lg pl-4 focus:outline-none"

                    />
                </div>

                {/* <div className="w-full p-6 pb-4">
                    <label class="text-sm font-semibold">{TRANSLATE.wd_email} <span className={AsterixClass}>*</span></label>
                    <input class="border border-zinc-200 focus:border-zinc-300 h-[50px] w-full outline-0 bg-white ring-0 bg-transparent rounded-lg pl-4 focus:outline-none" type="text" placeholder={TRANSLATE.tool_your_email}
                        name="email"
                        value={EMAIL}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyUp={(e) => onKeyUp(e)}

                    />
                </div>
                <div className="w-full p-6 pt-0">
                    <label class="text-sm font-semibold">{TRANSLATE.wd_password} <span className={AsterixClass}>*</span></label>
                    <input class="border border-zinc-200 focus:border-zinc-300 h-[50px] w-full outline-0 ring-0  bg-white rounded-lg focus:outline-none" type="password" placeholder="*************"
                        name="password"
                        value={PASSWORD}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={(e) => onKeyUp(e)}

                    />
                </div> */}



                <div className="flex w-full px-6">

                    {isLoading ? (
                        <div className='w-full h-[150] p-10 flex items-center justify-center'>
                            <img className='animate-spin w-[24px] h-[24px]' src={SRC_LOADER} />
                        </div>
                    ) : (
                        <div className={ButtonsClass}>
                            <button className={ButtonClass} onClick={e => onSignup(e)}>
                                {TRANSLATE.wd_signup}
                            </button>
                            <div className="flex my-4 w-full items-center before:h-px  before:w-full before:block before:bg-zinc-200  after:h-px after:w-full after:block after:bg-zinc-200"><div className="px-3">or</div></div>
                            <GoogleLoginComponent {...props} GoogleClass={GoogleClass} onLoginGoogle={onLoginGoogle} />

                        </div>
                    )}

                </div>

            </div>
        </>
    )


}


const isValidEmail = (email) => {

    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);

}

const isValidForm = (form, TRANSLATE) => {

    var fullname_string = form.fullname.trim();
    var email_string = form.email.trim();
    var password_string = form.password.trim();
    var confirmpassword_string = form.confirmpassword.trim();

    if (fullname_string.length < 6) {

        var error_title = TRANSLATE.wd_first_last;
        var error_message = TRANSLATE.error_invalid_fullname;

        DefaultAlert(error_title, error_message)

        return false;
    }


    if (!(isValidEmail(email_string))) {

        var error_title = TRANSLATE.wd_email;
        var error_message = TRANSLATE.error_invalid_email;

        DefaultAlert(error_title, error_message)

        return false;

    }

    if (password_string.length < 6) {

        var error_title = TRANSLATE.wd_password;
        var error_message = TRANSLATE.error_invalid_password;

        DefaultAlert(error_title, error_message)

        return false;

    }


    if (password_string !== confirmpassword_string) {

        var error_title = TRANSLATE.wd_confirm_password;
        var error_message = TRANSLATE.error_invalid_confirmation;

        DefaultAlert(error_title, error_message)

        return false;
    }

    return true;


}



const GoogleLoginComponent = (props) => {

    const { GoogleClass, TRANSLATE, onLoginGoogle } = props;

    const [DATA, setData] = useState();

    const isLocalhost = window.location.href.includes('localhost:3000');

    const login = useGoogleLogin({
        flow: 'auth-code',
        ux_mode: 'redirect',
        prompt: 'select_account',
        redirect_uri: isLocalhost ? 'http://localhost:3000/en/login' : 'https://www.avioyx.com/en/login',
        onSuccess: credentialResponse => {
            console.log(JSON.stringify(credentialResponse));
            setData(credentialResponse);
            onLoginGoogle(credentialResponse);
            return
        },
        onError: error => {
            console.log('Login Failed');
            setData(error)
        },
    });

    return (
        <div className="w-full h-full">
            <button className={GoogleClass + ' mb-5'} onClick={() => login()}>
                <img src={SRC_GOOGLE} />{TRANSLATE.wd_login_google}
            </button>
        </div>
    );
}

export default AuthFormSignup
