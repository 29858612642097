// Sidebar.js
import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import SRC_HOME from '../../assets/img/icons/home.svg';
import SRC_AIRCRAFTS from '../../assets/img/icons/aircrafts.svg';
import SRC_COURSES from '../../assets/img/icons/courses.svg';
import SRC_FLIGHTS from '../../assets/img/icons/flights.svg';
import SRC_LOGBOOK from '../../assets/img/icons/logbook.svg';
import SRC_LICENSES from '../../assets/img/icons/licenses.svg';
import SRC_CALENDAR from '../../assets/img/icons/calendar.svg';
import SRC_CALCULATOR from '../../assets/img/icons/calculator.svg';
import SRC_AIRPORTS from '../../assets/img/icons/airports.svg';
import SRC_COMMUNITY from '../../assets/img/icons/community.svg';

import SRC_LOGO from '../../assets/img/icons/logo.svg';

const Sidebar = () => {
  const match = useRouteMatch();

  const generateDynamicURL = (path) => `/${match.params.lang}${path}`;

  return (
    <div className="bg-avioyx-100 text-white h-full flex flex-col overflow-y-auto w-[250px] p-4 flex-shrink-0 sticky top-0 left-0">
      <div className='w-full flex flex-col justify-center items-center p-4 py-8 hover:bg-avioyx-200 rounded-lg cursor-pointer'>
        <img className='w-[56px] h-[56px] rounded-full object-cover mb-2' src='https://images.unsplash.com/photo-1513956589380-bad6acb9b9d4?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzJ8fG1hbGUlMjBwb3J0cmFpdHxlbnwwfHwwfHx8MA%3D%3D' />
        <div className='font-semibold text-base'>John Smith</div>
        <div className='text-xs text-gray-400'>Student</div>
      </div>
      <nav className="mt-4 flex flex-col gap-y-1 w-full h-full">
        <NavLink
          to={generateDynamicURL('/dashboard')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500 opacity-100"
        >
          <img className='w-[24px] mr-5' src={SRC_HOME} />
          Dashboard
        </NavLink>
        <NavLink
          to={generateDynamicURL('/aircrafts')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500 hover:bg-sky-500"
        >
          <img className='w-[24px] mr-5' src={SRC_AIRCRAFTS} />
          Aircrafts
        </NavLink>
        {/*<NavLink
          to={generateDynamicURL('/aircrafts/:id')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          Aircraft Details
  </NavLink>*/}
        <NavLink
          to={generateDynamicURL('/courses')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          <img className='w-[24px] mr-5' src={SRC_COURSES} />
          Courses
        </NavLink>
        <NavLink
          to={generateDynamicURL('/flights')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          <img className='w-[24px] mr-5' src={SRC_FLIGHTS} />
          Flights
        </NavLink>
        {/*<NavLink
          to={generateDynamicURL('/flights/:id')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          Flight Details
</NavLink>*/}
        <NavLink
          to={generateDynamicURL('/logbook')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          <img className='w-[24px] mr-5' src={SRC_LOGBOOK} />
          Logbook
        </NavLink>
        <NavLink
          to={generateDynamicURL('/licenses')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          <img className='w-[24px] mr-5' src={SRC_LICENSES} />
          Licenses
        </NavLink>
        <NavLink
          to={generateDynamicURL('/calendar')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          <img className='w-[24px] mr-5' src={SRC_CALENDAR} />
          Calendar
        </NavLink>
        <NavLink
          to={generateDynamicURL('/calculator')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          <img className='w-[24px] mr-5' src={SRC_CALCULATOR} />
          Calculator
        </NavLink>
        <NavLink
          to={generateDynamicURL('/airports')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          <img className='w-[24px] mr-5' src={SRC_AIRPORTS} />
          Airports
        </NavLink>
       {/*} <NavLink
          to={generateDynamicURL('/airports/:id')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          Airport Details
</NavLink>*/}
        <NavLink
          to={generateDynamicURL('/community')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          <img className='w-[24px] mr-5' src={SRC_COMMUNITY} />
          Community
        </NavLink>
        {/*<NavLink
          to={generateDynamicURL('/search')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          Search
        </NavLink>
        <NavLink
          to={generateDynamicURL('/inbox')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          Inbox
        </NavLink>
        <NavLink
          to={generateDynamicURL('/notifications')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          Notifications
        </NavLink>
        <NavLink
          to={generateDynamicURL('/profile')}
          className="px-4 py-3 hover:bg-avioyx-200 text-gray-400 hover:text-white rounded-lg text-sm flex items-center"
          activeClassName="bg-sky-500 text-white hover:bg-sky-500"
        >
          Profile
</NavLink>*/}
      </nav>

      <nav>
      <NavLink
          to={generateDynamicURL('/dashboard')}
          className="px-4 py-3 mt-auto hover:bg-avioyx-200  hover:text-white rounded-lg text-sm flex items-center"
        >
          <img  src={SRC_LOGO} />
        </NavLink>
      </nav>
    </div>
  );
};

export default Sidebar;
