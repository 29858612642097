import React, { useEffect, useState } from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';

import { postApiPrivate } from './api';
import configureStore from './store';

import swal from 'sweetalert';
import ReactGA from "react-ga";
import GAListener from './helpers/GAnalytics';

import AuthContainer from './app/Containers/AuthContainer';
import DashboardContainer from './app/Containers/DashboardContainer';
import PublicContainer from './app/Containers/PublicContainer';
import ShopContainer from './app/Containers/ShopContainer';
import BlogContainer from './app/Containers/BlogContainer';

import LANG_IT from './translations/it';
import LANG_EN from './translations/en';
import LANG_FR from './translations/fr';
import LANG_ES from './translations/es';

import { GoogleOAuthProvider } from '@react-oauth/google';


const CLIENT_ID = '863081838943-30fkte6e38age2e1jj31nl9jm0it54t9.apps.googleusercontent.com';


const isProd = true;

if (isProd) {
  console.log = () => { };
}

const helmetContext = {};

const store = configureStore({});

const App = (props) => {
  return (
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <Router>
          <GoogleOAuthProvider clientId={CLIENT_ID}>
            <GAListener trackingId={'UA-67700835-1'} defaultId='UA-67700835-1'>
              <SubApp {...props} />
            </GAListener>
          </GoogleOAuthProvider>
        </Router>
      </Provider>
    </HelmetProvider>
  )
}

const SubApp = (props) => {

  const [USER, setUser] = useState(null);
  const [LANGUAGE, setLanguage] = useState('en');
  const [CURRENCY, setCurrency] = useState('usd');

  const [MC, setMC] = useState(0);
  const [NC, setNC] = useState(0);

  const [isModalUser, setModalUser] = useState(false);

  const [isModalSearch, setModalSearch] = useState(false);
  const [isModalCart, setModalCart] = useState(false);
  const [isModalLocalisation, setModalLocalisation] = useState(false);
  const [isModalGuide, setModalGuide] = useState(false);
  const [isModalCity, setModalCity] = useState(false);
  const [isModalPosts, setModalPosts] = useState(false);
  const [isModalAvatar, setModalAvatar] = useState(false);

  useEffect(() => {
    onStartUser();
  }, []);

  useEffect(() => {
    localStorage.setItem('language', LANGUAGE);
  }, [LANGUAGE]);


  const onStartUser = async (isReset) => {

    if (isReset) {
      onUpdateUser({});
      return
    }

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    if (JWT) {

      onFetchUser(JWT);
    }

  }

  const onFetchUser = async (jwt, payload) => {

    if (payload) {
      onUpdateUser(payload);
      return
    }

    if (!jwt) {
      console.log("ERROR NO JWT FOUND ---> On Fetch User");

      alert('Check your internet connection and try again. 3');

      return
    }

    try {

      const DATA_RESPONSE = await postApiPrivate('/users/profile', {}, jwt);

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API USER INFO", DATA_RESPONSE.error);

        swal('Warning', 'You have been disconnected', 'warning')

        if (isProd) {
          localStorage.removeItem('jwt_token');
        }

        onStartUser();

        return

      }

      const DATA_USER = DATA_RESPONSE || {};
      const DATA_MESSAGES = DATA_USER.mc || 0;
      const DATA_NOTIFICATIONS = DATA_USER.nc || 0;

      setMC(DATA_MESSAGES);
      setNC(DATA_NOTIFICATIONS);

      console.log("CHECKING USER PROFILE === ", DATA_USER);

      onUpdateUser(DATA_USER);

      //alert('ALERT ===> '+JSON.stringify(DATA_USER))

    }
    catch (e) {

      console.log("FETCH USER ERROR ===> ", e);

      // if(match?.url){
      //   window.location.replace('/' + LANGUAGE + '/login?next='+match?.url)
      // }
      // else{
      //   window.location.replace('/' + LANGUAGE + '/login')
      // }



      //alert('Account Disconnected ===> '+JSON.stringify(e, null, 2));
      return //alert('Check your internet connection and try again. 5');
    }

  }


  const onUpdateUser = (user) => {
    setUser(user);
  }

  const onUpdateLanguage = (language) => {
    setLanguage(language)
  }

  const onFetchCounts = (type, value) => {
    if (type === 'mc') {
      setMC(value);
    }
    else if (type === 'nc') {
      setNC(value);
    }
  }

  //TRACKING
  const onFetchAnalytics = ({ location }) => {

    console.log("ANALYTICS ===> ");

    ReactGA.addTrackers(
      [
        {
          trackingId: 'UA-67700835-1',
          gaOptions: {
            name: 'tracker2'
          }
        }
      ],
      { debug: true, alwaysSendToDefaultTracker: false }
    );

    ReactGA.set({ page: location.pathname }, ['tracker2']);
    ReactGA.pageview(location.pathname, ['tracker2']);

  }

  const onFacebookTracking = () => {

  }

  const onGoogleTracking = (event) => {

    ReactGA.event({
      category: event,
      action: event
    });

  }

  const onKlaviyoTracking = (event, item) => {

    var _learnq = window._learnq || [];

    if (!USER.email) {
      return
      alert("EVENT ==> ITEM ===> " + JSON.stringify({ event, item }, null, 2));
    }

    // alert("EVENT ==> ITEM ===> EMAIL ===> "+USER.email+'/n'+JSON.stringify({event, item}, null, 2));

    _learnq.push(['identify', {
      '$email': USER.email
    }]);


    if (event) {

      _learnq.push(['track', event, item]);

    }

  }

  let TRANSLATE = {};

  if (LANGUAGE === 'it') {
    TRANSLATE = LANG_IT;
  }
  else if (LANGUAGE === 'en') {
    TRANSLATE = LANG_EN;
  }
  else if (LANGUAGE === 'fr') {
    TRANSLATE = LANG_FR;
  }
  else if (LANGUAGE === 'es') {
    TRANSLATE = LANG_ES;
  }
  else {
    TRANSLATE = LANG_EN
  }

  const sharedProps = {
    ...props,
    USER,
    LANGUAGE,
    CURRENCY,
    TRANSLATE,
    MC,
    NC,

    onFetchUser,
    onStartUser,
    onUpdateUser,
    onFetchCounts,

    onFetchAnalytics,
    onGoogleTracking,
    onFacebookTracking,
    onKlaviyoTracking,
    onUpdateLanguage,

    isModalCart,
    isModalGuide,
    isModalLocalisation,
    isModalSearch,
    isModalUser,
    isModalCity,
    isModalPosts,
    isModalAvatar,

    setModalCart,
    setModalGuide,
    setModalLocalisation,
    setModalSearch,
    setModalUser,
    setModalCity,
    setModalPosts,
    setModalAvatar

  }

  return (

    <div className="App flex flex-col h-full bg-avioyx-200 text-white">
      <Switch>

        {/*** Public - Home ***/}
        <Route exact path="/" render={(props) => <PublicContainer {...sharedProps} {...props} />} />
        <Route exact path="/en" render={(props) => <PublicContainer {...sharedProps} {...props} />} />
        <Route exact path="/it" render={(props) => <PublicContainer {...sharedProps} {...props} />} />
        <Route exact path="/fr" render={(props) => <PublicContainer {...sharedProps} {...props} />} />
        <Route exact path="/es" render={(props) => <PublicContainer {...sharedProps} {...props} />} />

        {/*** Public - Shop ***/}
        <Route exact path="/:lang/shop" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/categories" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/categories/:id" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/products" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/products/:id" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/orders" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/orders/:id" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/subscriptions" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/subscriptions/:id" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout/info" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout/upsell" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout/shipping" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout/payment" render={(props) => <ShopContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/shop/checkout/confirmation/:id" render={(props) => <ShopContainer {...sharedProps} {...props} />} />

        {/*** Public - Blog ***/}
        <Route exact path="/:lang/blog" render={(props) => <BlogContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/blog/categories" render={(props) => <BlogContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/blog/categories/:id" render={(props) => <BlogContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/blog/categories/:id/:sub" render={(props) => <BlogContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/blog/posts" render={(props) => <BlogContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/blog/posts/:id" render={(props) => <BlogContainer {...sharedProps} {...props} />} />


        {/*** Public - Auth ***/}
        <Route exact path="/:lang/login" render={(props) => <AuthContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/signup" render={(props) => <AuthContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/recovery" render={(props) => <AuthContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/logout" render={(props) => <AuthContainer {...sharedProps} {...props} />} />


        {/******************************************************************************************************/}
        {/******************************************************************************************************/}
        {/******************************************************************************************************/}
        {/******************************************************************************************************/}

        <Route exact path="/:lang/dashboard" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/aircrafts" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/aircrafts/:id" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/courses" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/flights" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/flights/:id" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/flights/new-flight" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/logbook" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/licenses" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/calendar" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/calculator" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/airports" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/airports/:id" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/community" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/search" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/inbox" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/notifications" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/profile" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
       

        {/******************************************************************************************************/}
        {/******************************************************************************************************/}
        {/******************************************************************************************************/}
        {/******************************************************************************************************/}

        {/*** Private - Flow ***/}
        <Route exact path="/:lang/flow" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />

        {/*** Private - Settings ***/}
        <Route exact path="/:lang/settings" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/settings/me" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/settings/stores/:id" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />

        {/*** Private - Notifications ***/}
        <Route exact path="/:lang/notifications" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/notifications/:id" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />

        {/*** Private - Inbox ***/}
        <Route exact path="/:lang/inbox" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/inbox/:userId/chat" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/inbox/provider/:storeId/chat" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/inbox/provider/:storeId/:customerId/chat" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />

        {/******************************************************************************************************/}
        {/******************************************************************************************************/}
        {/******************************************************************************************************/}
        {/******************************************************************************************************/}

        {/*** Private - Social ***/}
        <Route exact path="/:lang/home" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/post/:id" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/post/:id/comments" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/story/:id" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/story/:id/viewers" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />

        {/*** Private - Public ***/}
        <Route exact path="/:lang/public" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/public/:id" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />

        {/*** Private - Stores ***/}
        <Route exact path="/:lang/stores" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />
        <Route exact path="/:lang/stores/:id" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />

      

        <Route component={() => { return (<h1>Error</h1>) }} />

      </Switch>
    </div>

  )
}

export default App