const TRANSLATER = {

  lang: 'en',

  //Seo
  seo_title: 'Avioyx - Ultimate Aviation App for Pilots',
  seo_description: 'Plan, fill, navigate, track and record your flights. Our pilot apps make it easy to manage your pilot life.',
  seo_city_title: "Rent baby gear in {{city}}, delivery and pick-up",
  seo_city_description: "Rent baby equipment for your vacation in {{city}}. Book strollers, car seats, cribs and many more baby gear for your holidays in {{city}}. Delivery and pick-up service.",
  seo_category_title: "Rent baby {{category}} in {{city}}, clean and safe",
  seo_category_description: "Rent baby {{category}} for your holiday in {{city}}. {{category}} clean, safe and guaranteed in {{city}}. Delivery {{category}} at the airport or your accommodation in {{city}}.",
  seo_store_title: "{{store_name}} - articles for children in {{city}}",
  seo_store_description: "{{store_name}} - items for children in {{city}}, clean, safe and guaranteed products",
  seo_product_description: "Rent {{category}} {{marca}} {{modello}} in {{city}} - avioyx",

  //Header
  wd_login: 'Login',
  wd_signup: 'Sign Up',
  wd_logout: 'Log out',
  wd_howitworks: 'How it works',
  wd_provider_area: 'Provider area',
  wd_customer_area: 'Customer area',
  wd_list_gear: 'List your gear',
  wd_blog: 'Blog',
  wd_when: 'When',
  wd_where: 'Where',
  wd_start: 'Start',
  wd_end: 'End',
  wd_checkin: 'Checkin',
  wd_checkout: 'Checkout',
  wd_search: 'Search',
  wd_arriving: 'Arriving',
  wd_leaving: 'Leaving',
  wd_from: 'from',
  wd_to: 'to',
  wd_and: 'and',
  wd_of: 'of',

  //Footer
  wd_learnmore: 'Learn more',
  wd_aboutus: 'About us',
  wd_trust: 'Trust & Safety',
  wd_download: 'Download',
  wd_where_we_are: 'Where we are',
  wd_other_cities: 'Other cities',
  wd_support: 'Support',
  wd_help_center: 'Help Center',
  wd_provider_resources: 'Provider Resources',
  wd_terms: 'Terms and Conditions',
  wd_privacy: 'Privacy Policy',
  wd_cancellation: 'Cancellation Terms',
  wd_socialmedia: 'Social Media',
  wd_faq: 'Frequently asked questions',

  //Words
  wd_locations: 'Locations',
  wd_provider: 'Provider',
  wd_providers: 'Providers',
  wd_trips_taken: 'Trips taken',
  wd_cities_covered: 'Cities covered',
  wd_countries: 'Countries',
  wd_time: 'Time',
  wd_click_here: 'Click here',
  wd_gear_type: 'Gear type',
  wd_child_age: 'Child\'s age',
  wd_searching_providers: 'Looking for providers',
  wd_searching_products: 'Looking for products',
  wd_all_cities: 'All Cities',
  wd_points_of_interest: 'Points of interest',
  wd_rental_dates: 'rental dates',
  wd_rentals: 'rentals',
  wd_total_earned: 'total earned',
  wd_total_paid: 'total paid',
  wd_additional_info: 'Additional information',
  wd_order_total: 'order total',
  wd_provider_place: 'Provider\'s place',
  wd_airports: 'airports',
  wd_train_stations: 'train stations',
  wd_ports: 'ports',
  wd_available_providers: 'available providers',
  wd_other_providers: 'other providers',

  //Search
  wd_destination: 'Destination',
  wd_rental_period: 'Rental period',
  wd_pickup_address: 'Pickup Address',
  wd_dropoff_address: 'Dropoff Address',
  wd_address: 'Address',
  wd_address_street_landmark: 'Street or Landmark',
  wd_address_landmark_airport: 'Street, Landmark or Airport',
  wd_address_city_postal: 'Street, City, Post code',
  wd_address_airport_station: 'Airports, Landmarks or Stations',
  wd_flexible: 'I\'m flexible',
  wd_pickup: 'Pickup',
  wd_dropoff: 'Dropoff',

  //Common
  wd_email: 'Email',
  wd_password: 'Password',
  wd_confirm_password: 'Confirm password',
  wd_full_name: 'Full name',
  wd_first_name: 'First name',
  wd_last_name: 'Last name',
  wd_first_last: 'First & Last name',
  wd_login_google: 'Log in with Google',
  wd_signup_google: 'Sign up with Google',
  wd_case_sensitive: 'Case sensitive',
  wd_recover_password: 'Password Recovery',
  wd_contact_details: 'Contact Details',
  wd_create_account: 'Create account',

  wd_brand: 'brand',
  wd_day: 'day',
  wd_days: 'days',
  wd_unit: 'unit',
  wd_units: 'units',
  wd_price: 'price',
  wd_location: 'location',
  wd_category: 'category',
  wd_categories: 'categories',
  wd_age: 'age',
  wd_quantity: 'quantity',
  wd_products: 'products',
  wd_product: 'product',
  wd_availability: 'availability',
  wd_more_articles: 'more articles',

  wd_store_name: 'store name',
  wd_store_number: 'store number',
  wd_store_currency: 'store currency',
  wd_store_status: 'store status',
  wd_store_about: 'About your store',
  wd_store_placeholder: 'Build trust with your customer by telling a bit about yourself',
  wd_store_availability: 'store availability',
  wd_phone_number: 'Phone number',
  wd_delivery_point: 'Delivery point',
  wd_product_name: 'Product name',
  wd_product_about: 'Product description',
  wd_daily_price: 'Daily price',
  wd_weekly_price: 'Weekly price',
  wd_monthly_price: 'Monthly price',
  wd_add_product: 'Add product',
  wd_all_categories: 'All Categories',
  wd_new_product: 'New Product',
  wd_edit_product: 'Edit Product',
  wd_published: 'Published',
  wd_unpublished: 'Unpublished',

  wd_all_orders: 'all orders',
  wd_pending_approval: 'pending approval',
  wd_upcoming: 'upcoming',
  wd_rental_ending: 'rental ending',
  wd_currently_renting: 'currently renting',
  wd_rental_completed: 'rental completed',
  wd_canceled_rejected: 'canceled & rejected',

  wd_pending: 'pending',
  wd_accepted: 'accepted',
  wd_rejected: 'rejected',
  wd_canceled: 'canceled',
  wd_delivering: 'delivering',
  wd_delivered: 'delivered',
  wd_completed: 'completed',
  wd_refunded: 'refunded',
  wd_received: 'received',

  wd_message_customer: 'Message the customer',

  //Results
  wd_available_self: 'Self Pickup & Dropoff available for FREE',
  wd_available_pick_for: 'Available Pickup & Dropoff for',
  wd_available_pick_from: 'Available Pickup & Dropoff from',

  //City 
  wd_your_trip_to: 'Your trip to',
  wd_rental_period_in: 'Rental period in',
  wd_available_providers_in: 'available providers in',
  wd_available_baby_gear: 'baby gear available',
  wd_choose_gear_from: 'Choose your baby gear from',
  wd_products_in: 'products in',

  //Provider
  wd_response_rate: 'Response rate',
  wd_response_within: 'Responds within 1 hour',
  wd_orders_accepted: 'Orders accepted',
  wd_delivery_airport: 'Delivers at airports',
  wd_delivery_station: 'Delivers at stations',
  wd_delivery_port: 'Delivers at ports',
  wd_delivery_zones: 'delivery zones',
  wd_top_clean_reviews: 'Top clean reviews',
  wd_weekly_discounts: 'Weekly discounts',


  //Product
  wd_description: 'description',
  wd_read_less: 'read less',
  wd_read_more: 'read more',
  wd_convenient_delivery: 'Convenient Delivery',
  wd_quality_product: 'Quality Product',
  wd_cleanliness_standards: 'Cleanliness Standards',
  wd_premium_support: 'Premium Support',



  //Menu
  wd_myorders: 'my orders',
  wd_orders: 'orders',
  wd_reviews: 'reviews',
  wd_notifications: 'notifications',
  wd_wallet: 'wallet',
  wd_favorites: 'favorites',
  wd_chat: 'chat',
  wd_contact: 'contact support',
  wd_settings: 'settings',

  wd_dashboard: 'dashboard',
  wd_myproducts: 'my products',
  wd_earnings: 'earnings',

  //Cart
  wd_your_cart: 'your cart',
  wd_cart_empty: 'Your cart is empty',
  wd_subtotal: 'subtotal',
  wd_total: 'total',
  wd_discount: 'discount',
  wd_coupon_discount: 'coupon discount',
  wd_weekly_discount: 'weekly discount',
  wd_monthly_discount: 'monthly discount',
  wd_more_products_provider: 'More products from provider',
  wd_consumable: 'Consumable',

  //Checkout
  wd_your_order: 'Your order',
  wd_order_details: 'Order details',
  wd_customer_details: 'Customer details',
  wd_delivery_return: 'Delivery & Return',
  wd_payment: 'Payment',
  wd_delivery_summary: 'Delivery summary',
  wd_delivery_pickup_provider: 'Delivery & Pickup by provider',
  wd_self_pickup: 'Free Self-Pickup & Return',
  wd_self_pickup_tool: 'View approximate pickup location',
  wd_card_number: 'Card Number',
  wd_expiry_month: 'Expiry Month',
  wd_expiry_year: 'Expiry Year',
  wd_security_code: 'Security Code',

  add_payment_method: 'Add a Payment Method',
  add_new_card: 'Add new card',
  add_another_card: 'Add another card',

  order_success: {
    t: 'Your order request has been received successfully.',
    s: 'Please check your email, we will be reaching out in the next 48 hours about your order status.',
    c: 'will be either accepting or rejecting your order request.',
    a: 'Booking Success',
    b: 'You Booked Successfully your order and here is your confirmation',
  },


  //Dashboard
  wd_your_earnings: 'Your earnings',
  wd_orders_count: 'You have {{TOTAL}} new orders',
  wd_product_rentals_count: 'This product has {{TOTAL}} rentals',
  wd_all_time: 'All time',
  wd_today: 'Today',
  wd_yesterday: 'Yesterday',
  wd_last_days: 'Last {{TOTAL}} days',
  wd_last_week: 'Last week',
  wd_last_month: 'Last month',
  wd_last_year: 'Last year',
  wd_quick_actions: 'Quick actions',
  wd_new_messages: 'New messages',

  //Inbox
  wd_choose_conversation: 'Choose a conversation',
  wd_no_conversations: 'No conversations',
  wd_type_here: 'Type here',

  //Settings
  wd_store_settings: 'store settings',
  wd_profile: 'profile',
  wd_delivery_pickup: 'delivery & pickup',
  wd_schedules: 'schedules',
  wd_payout: 'payout',


  //Buttons
  btn_start_now: 'Start now',
  btn_more_info: 'More information',
  btn_become_provider: 'Become a provider',
  btn_book_now: 'Book now',
  btn_provider_area: 'Go to provider area',
  btn_apply_now: 'Apply now',
  btn_rent_gear: 'Rent baby gear',
  btn_submit: 'Submit',
  btn_next: 'Next',
  btn_back: 'Back',
  btn_skip: 'Skip',
  btn_cancel: 'Cancel',
  btn_delete: 'Delete',
  btn_upload: 'Upload',
  btn_refresh: 'Refresh',
  btn_decline: 'Decline',
  btn_remove: 'Remove',
  btn_apply: 'Apply',
  btn_continue: 'Continue',
  btn_select: 'Select',

  btn_save: 'Save',
  btn_add_to_cart: 'Add to cart',
  btn_edit_profile: 'Edit profile',
  btn_save_changes: 'Save changes',
  btn_upload_picture: 'Upload picture',
  btn_use_picture: 'Use this picture',
  btn_add_delivery_area: 'Add delivery area',
  btn_add_new_product: 'Add new product',
  btn_publish_store: 'Publish Store',
  btn_save_product: 'Save Product',
  btn_delete_product: 'Delete Product',
  btn_clear_calendar: 'Clear calendar',
  btn_contact_customer: 'Contact customer',
  btn_contact_provider: 'Contact provider',
  btn_change_password: 'Change Password',

  btn_accepted_order: 'Accept order',
  btn_delivering_order: 'Delivering order',
  btn_delivered_order: 'Order delivered',
  btn_completed_order: 'Order completed',
  btn_leave_review: 'Leave a review',
  btn_check_review: 'Check the review',

  btn_continue_shopping: 'Continue shopping',
  btn_finish_booking: 'Finish booking',
  btn_view_products: 'View {{TOTAL}} Products',
  btn_view_all: 'View All',

  btn_show_map: 'Show Map',
  btn_hide_map: 'Hide Map',

  btn_place_order: 'Place order request',
  btn_manage_order: 'Manage order',



  //Questions
  ask_which_city: 'Which city are you going to?',
  ask_ready_book: 'Ready to book?',

  ask_where_delivery: 'Where should we deliver the baby gear?',
  ask_where_return: 'Where should we pickup the baby gear?',
  ask_coupon: 'Do you have a coupon?',

  ask_sure: 'Are you sure?',
  ask_sure_accept: 'Are you sure you want to accept this order?',
  ask_sure_reject: 'Are you sure you want to reject this order?',
  ask_sure_cancel: 'Are you sure you want to cancel this order?',

  ask_about_order: 'Do you have any questions about this order?',
  ask_why_reject: 'Why do you reject this order?',
  ask_why_cancel: 'Why do you cancel this order?',
  ask_help: 'Hello ! how can i help you?',


  //Answers
  yes_accept: 'Yes accept',
  yes_reject: 'Yes reject',
  yes_cancel: 'Yes cancel',

  //Tooltips
  tool_login: 'We will create an account for you so you can manage your orders and contact provider at your convenience. Already have an account?',
  tool_signup: 'Login to your account to manage your orders and contact provider at your convenience. Don\'t have an account?',

  tool_search_city: 'Search for a city example: London',
  tool_enter_city: 'Enter a city name',
  tool_your_email: 'Your email address',
  tool_your_name: 'Your name',

  tool_select_rental_dates: 'Select the rental dates to get the baby gear',
  tool_select_delivery: 'If unsure, just use the city for now',
  tool_select_checkin: 'Select the day to get the baby gear',
  tool_select_checkout: 'Select the day to return the baby gear',
  tool_select_age: 'Select the age of your child',
  tool_select_category: 'Select the category of gear you would like to rent',
  tool_select_availability: 'Select the dates on which you will not be available for rental service',
  tool_select_pickup: 'Select the pickup location',
  tool_select_dropoff: 'Select the dropoff location',
  tool_select_from_dropdown: 'Choose your delivery preference from options below',

  tool_enter_email: 'Please enter a valid email address',
  tool_enter_checkin: 'Please enter a valid checkin date',
  tool_enter_checkout: 'Please enter a valid checkout date',
  tool_enter_pickup: 'Please enter a valid pickup place',
  tool_enter_dropoff: 'Please enter a valid dropoff place',

  tool_store_address: 'Enter your store\'s address',
  tool_store_closed_dates: 'Store closed on the following dates',
  tool_delivery_locations: 'Delivery & Pickup Locations',

  tool_enter_store_address: 'Customers can pick-up the gear by themselves from your address without delivery service.',
  tool_enter_delivery_locations: 'Airports and popular landmarks are the most requested locations by customers.',

  tool_choose_gear_from: 'Choose your gear from {{TOTAL}} available providers',
  tool_choose_gear_other: 'These {{TOTAL}} providers do not offer delivery to your location but they deliver nearby or available for free self-pickup.',
  tool_add_delivery_area: 'More locations you add, more order you can receive.',
  tool_product_availability: 'Here are the dates where this product is rented',

  tool_dnd_images: 'Upload your product pictures',

  tool_once_deleted: 'Once deleted you won\'t be able to recover this',
  tool_once_proceed: 'Once deleted your {{TYPE}} will be deleted. Would you like to proceed?',

  tool_tell_reason_reject: 'Tell customer a reason why this order is not accepted',
  tool_tell_reason_cancel: 'Tell us a reason why this order will be canceled',

  tool_where_delivery: 'Insert the address where you would like to pickup the gear or select one of the delivery options available.',
  tool_where_return: 'Insert the address where you would like to drop off the gear or select one of the return options available.',

  tool_insert_coupon: 'Please insert the coupon code and click apply',
  tool_free_cancellation: 'Free cancelation up to 48 hours.',

  tool_view_delivery_map: 'You can view where {{store_name}} offers direct delivery on the map.',
  tool_free_self_pickup: 'Pick it up for free at provider\'s location.',
  tool_interested_other: 'Other baby gear you might be interested in.',
  tool_additional_info: 'Write more information on delivery and collection times and addresses, flight number etc..',

  tool_payment_hold: 'You will not be charged until the order is accepted. If the order is not accepted, the hold will be released. When your order request is accepted cancellations are fully refundable except avioyx fee until 48 hours before the first rental day.',
  tool_payment_finishing: 'By finishing the order you accept',
  tool_payment_cancel: 'Cancel reservation before',
  tool_payment_refund: 'and you will receive a full refund (except avioyx fee).',
  tool_select_card_payment: 'Select the card for rental',



  //Alerts
  alert_recovery_success: 'We have sent you an email ✉️ with the recovery instructions',
  alert_store_updated: {
    t: 'Store Updated',
    s: 'Your Store has been updated Successfully'
  },
  alert_availability: {
    reset_t: 'Availability is Reseted',
    reset_s: 'You store availability has been reseted successfully',
    save_t: 'Availability is Saved',
    save_s: 'You store availability has been saved successfully'
  },
  alert_order: {
    accepted_t: 'Order Accepted',
    accepted_s: 'You order has been accepted successfully',
    rejected_t: 'Order Rejected',
    rejected_s: 'You order has been rejected successfully',
    canceled_t: 'Order Canceled',
    canceled_s: 'You order has been canceled successfully'
  },
  alert_cart: { 
    added_t: 'Cart Updated',
    added_s: 'Product added to the cart successfully',
    consumable_t: 'Add Rental Item First',
    consumable_s: 'This product can only be ordered with a rental item. Please add a baby gear from this provider to your cart before adding consumable items.',
    multiple_t: 'Multiple providers',
    multiple_s: 'There are items from a different provider in your cart. Would you like to empty your cart to add this item?',
    multiple_c: 'Add Item',
  },
  alert_expired: {
    checkout_t: 'Checkout Expired',
    checkout_s: 'This checkout is expired. Add new products to your cart.',
  },


  //Errrs
  error_invalid_email: 'You have inserted an invalid email address. Please try again',
  error_invalid_credentials: 'Invalid credentials. Email and password are incorrect. Please try again ',
  error_invalid_fullname: 'You must enter your full name with at least 6 characters.',
  error_invalid_password: 'You must enter a Password of at least 6 characters.',
  error_invalid_confirmation: 'The password confirmation does not match the password entered.',

  error_already_exists: 'Account already exists for this email.',

  error_missing_password: 'You did not enter your password',
  error_missing_fullname: 'You did not enter your full name',
  error_missing_phone: 'You did not enter your phone number',

  error_invalid_address: 'You must enter a valid address with house number, street and city.',
  error_invalid_store_settings: 'You need to complete the checklist to access the store settings',
  error_invalid_card: 'The inserted card is not valid. Please try again',

  error_not_authorized: {
    t: 'Not Authorized',
    s: 'You\'re not authorized to visit this page'
  },

  //Landing Customer
  cust_intro: {
    a: 'Rental baby gear',
    b: 'is just a click away',
    p: 'Find everything that will make traveling with your little ones easier. Anytime, anywhere, with our local providers!'
  },
  cust_options: [
    {
      t: 'Wherever and whenever you need',
      i: '/assets/img/landing/time.svg'
    },
    {
      t: 'Lots of delivery options, including airports',
      i: '/assets/img/landing/delivery.svg'
    },
    {
      t: 'Safe and clean products',
      i: '/assets/img/landing/clean.svg'
    },
    {
      t: 'Premium customer support',
      i: '/assets/img/landing/support.svg'
    }
  ],
  cust_how: {
    t: 'How it works',
    a: 'Pick the baby gear that you need',
    b: 'Pick up or receive the baby gear from the provider',
    c: 'Return gear at the end of your trip'
  },
  cust_reviews: {
    t: 'More than 3,000 happy customers',
    o: [
      {
        i: 'https://user-images.trustpilot.com/6242c03147517c0013ce5370/73x73.png',
        n: 'Claudio Aruanno',
        a: 'Just 1 word PERFECT!',
        b: 'We were looking for a baby cot for our son and came across this company. They helped us a lot and the contact is very good as well as the service! We hadn\'t booked a mattress and they had one extra for us. Super nice and sweet. Thank you for this. My son slept wonderfully, because the quality was super!',
        c: 'We are from The Netherlands and we definitely recommend it for next time!'
      },
      {
        i: '/assets/img/avatar.svg',
        n: 'Cynthia Gonzalez',
        a: 'Stroller rental.',
        b: 'I had an excellent experience from the beginning of my booking to the end. They reached out to me a few days before travel to arrange the meet up, the day of when we met she was on time and communication was quick and easy. The stroller was in excellent condition. Upon returning the stroller, they were very flexible in the time and place to pick it up. I’m very glad I chose this company I will definitely recommend it.',
        c: ''
      },
      {
        i: '/assets/img/avatar.svg',
        n: 'Satu Malmi',
        a: '',
        b: 'Very good quality with the rented goods (stroller and a baby seat for car). Delivery and return also went smoothly and flexibly. Recommended for other families wanting to make sure their babies travel safely and not wanting to fly their own stuff abroad 🙂',
        c: ''
      }
    ]
  },
  cust_cities: 'Major cities available',
  cust_clean: {
    a: 'Clean',
    b: '& Safe',
    p: 'Cleanliness and safety have been our main priorities from the beginning. We pay attention to the smallest detail for you to have a perfect                             rental experience. Products that are found to not comply with our hygiene and safety standards are removed from our site and are not allowed to be published again.'
  },
  cust_travel: {
    a: 'Travel without',
    b: 'heavy bags',
    p: 'Have a stress-free holiday, where you will enjoy your trip and make memories with your family without lugging around lots of stuff. You can find the baby gear you need wherever and whenever you want. With avioyx, your favorite travel assistant, you no longer have to worry about your heavy bags.'
  },
  cust_parents: {
    a: 'Parent-to',
    b: '-parent sharing',
    p: 'Most of our providers are parents just like you, and the points you care about for your little one are also important for our providers. That\'s why we know you’ll find everything that you need to have a stress-free travel experience with your little one. We are happy to find solutions for you with our wide range of products, starting from affordable rental prices!'
  },
  cust_app: {
    a: 'Everything in one place',
    b: 'with the avioyx app.',
    o: [
      'Get everything you need in one place.',
      'Stay in touch with our Providers.',
      'Manage your rental order.',
      'Connect to our support team quickly.',
      'Take advantage of exclusive app-only promotions.'
    ]
  },
  cust_faq: [
    {
      t: 'How does the avioyx rental service work?',
      p: 'After selecting baby gear to rent and submitting your order, the Provider is notified. They can accept or reject your rental order. If accepted, you will receive a notification and be charged. The Provider will coordinate the delivery or pick-up with you. Once the item is returned, through pick up or drop off, your booking is complete.'
    },
    {
      t: 'When am I charged?',
      p: 'Your credit card will be charged once the booking is approved by the Provider. When you submit your rental order, a hold is placed on your account but automatically released if rejected.'
    },
    {
      t: 'Can I cancel and get a refund?',
      p: 'You can cancel your order anytime until 48 hours before your booking period to get a full refund minus service fees. After this, you are no longer eligible for a refund.'
    },
    {
      t: 'Can I modify my rental order?',
      p: 'Yes, your order may be modified anytime, and you will need your Provider\'s approval to accept the changes.'
    },
    {
      t: 'Is the baby gear sanitized?',
      p: 'We recommend baby gear be cleaned and sanitized immediately after the end of a booking. Baby gear should be cleaned with safe products that kill bacteria and viruses.'
    },

  ],

  //Landing Provider
  pro_intro: {
    a: 'Become a avioyx Provider',
    b: 'Launch your own baby gear rental store'
  },
  pro_start: {
    a: 'Start renting in minutes:',
    b: 'Store name',
    c: 'Location & delivery',
    d: 'Products'
  },
  pro_how: {
    t: 'How do I get started?',
    p: 'Becoming a avioyx Provider and launching your baby gear rental store is easy. Just 3 steps to get started! Create your store and add your first products to start receiving orders! We will help you along the way.'
  },
  pro_earning: {
    t: 'Earn up to €1,500/month',
    p: 'Are you looking for an opportunity to boost your monthly income? Do you have a stash of baby gear you\'re not using? You can now earn up to €1,500 monthly by renting your baby gear to traveling families! This is a great way to make some extra cash while helping families travel with young children. Whether it\'s a stroller, car seat, or crib, baby gear is always needed when families are away from home.',
  },
  pro_reviews: {
    i: '/assets/img/avatar.svg',
    n: 'Vincenzo',
    a: 'avioyx provider',
    b: 'I am Vincenzo, father of two beautiful girls, I am passionate about travel and I still remember the projects and programs with my wife when the first child was about to arrive, regarding the need to change cars to have more space to stow the luggage of the growing family ! When we became 4 we almost went into crisis! I married the avioyx project, believing that it is a great way to not deprive yourself of the pleasure of a long trip, without asking yourself the problem of carrying all that equipment.'
  },
  pro_options: [
    {
      t: 'Set your own schedule',
      i: '/assets/img/landing/schedule.svg'
    },
    {
      t: 'Start earning now',
      i: '/assets/img/landing/earn.svg'
    },
    {
      t: 'Meet new people',
      i: '/assets/img/landing/community.svg'
    },
    {
      t: 'Support from avioyx',
      i: '/assets/img/premium-support.svg'
    }
  ],
  pro_faq: [
    {
      t: 'What does it mean to be a avioyx provider?',
      p: 'From strollers and car seats to playpens and high chairs, you can provide families with everything they need to make their trip more comfortable and enjoyable. By eliminating the need for families to bring their own gear, you can also help them save money on baggage fees and reduce the stress of traveling with a baby. With your reliable and convenient service, families can focus on making memories during their travels without worrying about the logistics of baby gear.'
    },
    {
      t: 'Is there a fee to start on avioyx?',
      p: 'Starting your adventure as a avioyx Provider is completely free. Once you get an order, we will deduct a small fee from the total, which goes to running Babobo and finding customers in order to support you.'
    },
    {
      t: 'Do I need to buy gear to get started?',
      p: 'To get started, use your existing baby gear to start as a Provider on avioyx! In fact, we recommend if you want to get more baby gear to rent that, you buy it secondhand. Our only requirement for your baby gear is that it is in good condition and clean.'
    },
    {
      t: 'Where do I deliver the baby gear?',
      p: 'Most of our Providers deliver baby gear all over their respective cities. You can choose famous nearby landmarks and transport centers like train stations and airports. You can also give customers the option to pick up the baby gear directly from you!'
    },
    {
      t: 'How much baby gear do I need to get started?',
      p: 'You only need one item to start as a avioyx Provider. We recommend starting with a stroller, crib, or car seat, as they are our most popular rental items. Once your business takes off, you can also consider adding other items, such as high chairs, playpens, and baby carriers, to your inventory. By offering a wider range of products, you can cater to the needs of a larger customer base and increase your chances of attracting larger orders.'
    },
    {
      t: 'How much can I earn as a avioyx provider?',
      p: 'You can earn as much as €1,500 per month as a avioyx Provider. How much you earn will depend on where you are located and willing to deliver, as well as how many items that you have for rent. Our top earners always have strollers, cribs, and car seats for rent.'
    },
    {
      t: 'How do I get started?',
      p: 'To get started, you simply fill out our 3-step application. This includes setting up your online store and listing your first products. Once complete, you can publish your store and start accepting orders. It is as easy as it sounds!'
    }
  ],

  //Login / Signup / Recovery
  auth_login: {
    a: 'Log in',
    b: 'Don\'t have an account? ',
    c: 'Forgot your password?'
  },
  auth_signup: {
    a: 'Sign up',
    b: 'Already have an account?  ',
    c: {
      a: 'By clicking on subscribe, you accept our',
      b: 'Terms and Conditions',
      c: 'and ours',
      d: 'Privacy Policy',
    }
  },
  auth_recovery: {
    a: 'Forgot your password?',
    b: 'Enter your email below and we will send you instructions to reset your password.',
  },

  //About
  about_page: {
    a: 'avioyx is a baby gear rental service offering a variety of baby gear items through locals for traveling families in over 250+ cities.',
    b: 'avioyx was started in 2018 by Duygu Sefa, founder and provider of avioyx. As a mother of two, Duygu thought there must be an easier way than carrying all the baby gear necessary while traveling with kids.',
    c: 'After numerous trips with my little girl, I realized that traveling with the little ones can become very stressful, so I asked myself why not help families to travel free from all gear?',
    d: 'With a full understanding of what traveling families might be struggling with, in 2019 avioyx baby gear rental marketplace was launched. Since the first day, families have continued to trust and make avioyx indispensable during their travels.',
    e: 'Today, avioyx is a large family of not only traveling families looking for baby gear but also providers from all around the world renting out baby gear. avioyx providers are locals who want to make families’ travel an even more extraordinary adventure and, earn monthly extra income while doing it.'
  },

  //How it works
  how_page: {
    t: 'Traveling with little ones is easier than ever.',
    a: {
      t: 'Pick the baby gear you need',
      a: {
        t: 'Pick your location',
        s: 'Choose your destination to pick up your order by selecting the city that you will be visiting.'
      },
      b: {
        t: 'Select your dates',
        s: 'Add in your travel dates based on your trip. Feel free to contact the Provider if you have availability questions.'
      },
      c: {
        t: 'Pick the baby gear',
        s: 'You can find premium brand car seats, any type of stroller, portable cribs.'
      },
    },
    b: {
      t: 'Receive the baby gear from the Provider where you need it.',
      s: 'We have lots of delivery options!',
      a: {
        t: 'Directly to Airports.'
      },
      b: {
        t: 'Directly to hotels and rentals in city centers.'
      },
      c: {
        t: 'Meet at points of interest.',
        s: 'such as central train and bus stations or landmarks in the city. Or, collect the gear for free at the Provider\'s place.'
      },
      d: {
        a: 'It is free to pick up from the Provider\'s place.',
        b: 'For other delivery locations, Providers typically charge delivery fees. You will see the delivery fees when checking out.',
        c: 'Once you book your rental, the Provider will accept or reject the order within 24 hours. You will receive an email with the confirmation. A hold will be placed on your account until the order is accepted, and then you will be charged.',
        d: 'avioyx only charges when your order is accepted by the Provider.'
      }

    },
    c: {
      t: 'Return baby gear at the end of your trip',
      s: 'Depending on your chosen option, meet the avioyx Provider at the pick-up place on time or wait for them to collect the baby gear. Make sure to return the products in the same conditions. And that’s it!'
    },
    d: {
      a: 'Wherever you go,',
      b: 'the baby gear will meet you there!',
      c: 'Health and safety are our priority',
      d: 'Flexible pick up & return options',
      e: 'Support whenever you need it'
    }

  },

  //Trust & Safety
  trust_page: {
    a: {
      t: 'avioyx Trust & Safety',
      s: 'Trust and safety have been a priority from beginning until now. We pay attention to the smallest details for you to have a perfect rental experience.'
    },
    b: {
      t: 'Trust:',
      a: 'We have more than 2,000 happy customers.',
      b: 'Don\'t fear a change of travel plans, you can cancel your order with a full refund until 48 hours before your rental day!',
      c: 'If you aren’t satisfied with the baby gear that you rent, we will replace it with an alternative within 24 hours. If we can’t find an alternative option, we will refund your money!'
    },
    c: {
      t: 'Safety & Hygiene:',
      s: 'Our providers must meet our safety & hygiene cleaning standards. Rental baby gear are thoroughly cleaned by the Providers after each rental. All avioyx Providers and their products go through an approval process. And, your reviews are highly valuable for us. We take your reviews into consideration and take the necessary actions, when necessary.'
    },
    d: {
      t: 'Reservation Protection:',
      a: 'Your personal information will not be sold or distributed in any way. Your privacy is important to us. We require you to provide your full name, e-mail address, phone number, and payment information through our site.',
      b: 'We do our best to ensure you have a flawless booking experience, but if a avioyx Provider needs to cancel at the last minute, we\'ll do our best to help you find a replacement.'
    },
    e: {
      t: 'Looking forward to meeting you!',
      s: 'If you need anything throughout your rental experience, please do not hesitate to contact our team of experts. You can reach us at any time via live chat New Website review 11 on our website or app, or email at info@avioyx.com and we would be happy to assist you.'
    }

  },

  //Download
  download_page: {
    t: 'Download the app',
    s: 'Manage your rental easily with avioyx app. Download and start using it now!',
    a: 'Everything in one place with the avioyx app.',
    b: 'Developed with your needs in mind.'
  },

  //Locations
  locations_page: {
    t: 'Rent Baby Gear in',
    s: 'Rent car seats, strollers, cots and many other products. Our providers deliver wherever and whenever you want. Travel light and safe with your children.',
  },

  //Customer Flow
  cust_flow: {
    a: {
      t: 'When would you like to rent?',
      s: 'Please select the rental dates (Pickup & Drop-Off) in'
    },
    b: {
      t: 'Pick up & Drop off',
      s: 'Where do you want to be delivered - pickup & drop off in'
    },
    c: {
      t: 'What gear do you need?',
      s: 'Select the categories of gear and the age of your child'
    }
  },

  //Provider Flow
  pro_flow: {
    a: {
      t: 'Basic information about your store.',
      s: 'Your contact number will only be shared with the customer when you accept their order request.'
    },
    b: {
      t: 'Location & delivery.',
      s: 'This will help customers select your store best for their location'
    },
    c: {
      t: 'Awesome! Your first product is up.',
      s: 'Add more products here for quick store setup.'
    },
  },
  pro_check: {
    t: 'Thank you for creating your provider profile. Please follow these steps below to start renting.',
    a: 'Upload your profile picture',
    b: 'Set your delivery and pickup rates',
    c: 'Set your store\'s address',
    d: 'Upload products',
    e: 'Add contact information',
    z: 'Your profile is being verified. We will email you within 24 hours'
  },

  wd_list_months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],

  wd_how_guide: {
    a: 'Book your baby gear rental',
    b: 'Receive baby gear from your provider where you want',
    c: 'Enjoy your trip with your little one',
    d: 'Return baby gear at the end of your trip',
    cta: 'Got it'
  },

  wd_list_schedules: {
    t: 'Pick up and delivery times',
    s: 'Check your weekly availability for delivery service and pickup from home.',
    home: 'Pickup from home',
    service: 'Delivery service',
    a: 'monday',
    b: 'tuesday',
    c: 'wednesday',
    d: 'thursday',
    e: 'friday',
    f: 'saturday',
    g: 'sunday'
  },

  wd_list_payout: {
    t: 'Payout options',
    s: 'Add your payout information to receive your orders earnings.',
    a: 'Bank name',
    b: 'Social security number',
    c: 'Country',
    d: 'Type',
    e: 'Bank Transfer',
    f: 'Account number',
    g: 'Routing number',
    connected: 'Connected',
    disconnect: 'Disconnect'

  },

  wd_list_earnings: {
    earn_baby: 'Earn and spend BabyCoins?',
    ref_code: 'Your referral code', 
    recent: 'Recent transactions',
    ask_this: 'What is this ?',
    learn_how: 'Learn how',

  },

  wd_analytics: 'Analytics',
  wd_pending_rate: 'Pending Rate',
  wd_acceptance_rate: 'Acceptance Rate',
  wd_rejection_rate: 'Rejection Rate',
  wd_score: 'Score',
  wd_performance: 'Performance',
  wd_total_customers: 'Total Customers',
  wd_total_products: 'Total Products',
  wd_total_days: 'Total Days',
  wd_aov: 'Average Order Value',
  wd_total_earnings: 'Total earnings',
  wd_total_to_earn: 'Total to earn',
  wd_total_lost: 'Total lost',

  wd_list_orders: [
    'For this order you will be charged {{TOTAL}} if you cancel.',
    'For this order you will be charged {{TOTAL}} if you cancel.',
    'For this order you won\'t be charged if you cancel.',
    'If your order has been accepted by the provider:',
    'Orders that are cancelled before  the last 48 hours of the first rental day are refunded except of avioyx fee',
    'Orders that are canceled within last 48 hours before the first rental day are not refundable',
    'Cancellations cannot be refunded if the first day of the rental is within 48 hours.'
  ]


};

export default TRANSLATER;  