import React, { useEffect, useRef, useState } from "react";
import Parse from "parse";
import SRC_CLOSE from '../../assets/img/close.svg';
import SRC_SEND from '../../assets/img/send-message.svg';

import TextareaAutosize from 'react-textarea-autosize';

import { postApiPrivate } from '../../api';

import SRC_LOADER from '../../assets/img/loader.png';

import moment from 'moment'

import SRC_PLUS from '../../assets/img/mobile-bar/add.svg';


const ChatAlpha = (props) => {

    const AreaRef = useRef();
    const messagesEndRef = useRef(null)

    const { onClose, USER, match } = props;

    const [isLoading, setLoading] = useState(true);

    const [OTHER, setOther] = useState(props.OTHER);
    const [messageInput, setMessageInput] = useState('');
    const [conversation, setConversation] = useState(null);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (USER.id) {
            onFetchConversation()
        }
    }, [USER]);

    useEffect(() => {

        if (conversation) {

            Parse.initialize('avioyxapphdjfgsjkdf7839gsjd73hj0u2e0dj01x01', 'avioyxappsdfjskdgmfn4832v3yevy82i2340iwe1x01');
            Parse.serverURL = 'https://www.avioyx.com/parse';

            let client = new Parse.LiveQueryClient({
                applicationId: 'avioyxapphdjfgsjkdf7839gsjd73hj0u2e0dj01x01',
                serverURL: 'wss://www.avioyx.com/parse',
                javascriptKey: 'avioyxappsdfjskdgmfn4832v3yevy82i2340iwe1x01'
            });
            client.open();

            let query = new Parse.Query('Messages');
            query.equalTo("parent_inbox", { __type: 'Pointer', className: 'Inbox', objectId: conversation.i })
            // query.ascending('createdAt')
            // query.limit(1);
            let subscription = client.subscribe(query);

            subscription.on('open', () => {
                console.log('subscription opened');
            });

            subscription.on('create', (object) => {
                console.log("object created ===> ", object.get("from"));

                const newMessage = {
                    id: object.id,
                    text: object.get("text"),
                    date: object.createdAt,
                    from: object.get("from"),
                    to: object.get("to")
                }
                setMessages(previousMessages => {
                    const findIndex = previousMessages.findIndex((item) => item.status === 'sending' && item.text === newMessage.text);
                    if (findIndex === -1) {
                        return [...previousMessages, newMessage];
                    }
                    else {
                        const oldMessage = previousMessages[findIndex];
                        return [
                            ...previousMessages.slice(0, findIndex),
                            { ...oldMessage, status: 'sent' },
                            ...previousMessages.slice(findIndex + 1),
                        ]
                    }

                });
            });

            subscription.on('update', (object) => {
                console.log('object updated ===> ', object);
            });

            subscription.on('enter', (object) => {
                console.log('object entered ===> ', object);
            });

            subscription.on('leave', (object) => {
                console.log('object left ===> ', object);
            });

            subscription.on('delete', (object) => {
                console.log('object deleted ===> ', object);
            });

            subscription.on('close', () => {
                console.log('subscription closed');
            });

            return () => {
                subscription.unsubscribe();
                client.close();
            };

        }

    }, [conversation])

    const scrollToBottom = () => {
        // Find the chat container element by ref
        const chatContainer = document.getElementById('chat-container');

        // Scroll to the bottom
        if(chatContainer){
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
      
    };

    useEffect(() => {
        // Scroll to the bottom when messages change
        setTimeout(()=> {
            scrollToBottom();
        }, 100)
       
    }, [messages]);

    const onFetchConversation = async () => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const POST_FORM = {
            c: USER.id,
            o: match.params.userId
        }

        setLoading(true);

        try {

            const DATA_RESPONSE = await postApiPrivate('/inbox/alpha', POST_FORM, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING INBOX DETAILS", DATA_RESPONSE.error);
                setLoading(false);
                return
            }


            const Conversation = DATA_RESPONSE || {};
            const Other = Conversation.o || {};

           // alert('Here ===> ',JSON.stringify(DATA_RESPONSE, null, 2))

            setConversation(Conversation);
            onFetchMessages(Conversation);

            if(props.OTHER){
                setOther(Other)
            }
            else{
                setOther(Other)
            }
           

            return null

        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);

            return null
        }

    }

    const onFetchMessages = async (conversation) => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const POST_FORM = {
            id: conversation.i
        }

        setLoading(true);

        try {

            const DATA_RESPONSE = await postApiPrivate('/inbox/single', POST_FORM, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING INBOX DETAILS", DATA_RESPONSE.error);
                setLoading(false);
                return
            }


            const Messages = DATA_RESPONSE.data || [];

            setMessages(Messages)

            setLoading(false);

            return null

        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);

            return null
        }

    }


    const onSend = () => {

        const newMessage = {
            id: guidGenerator(),
            text: messageInput,
            date: new Date(),
            from: USER.id,
            to: OTHER.id,
            status: 'sending'
        }
        setMessages(previousMessages => [...previousMessages, newMessage]);
        setMessageInput('')

        const Message = Parse.Object.extend("Messages");
        const thisMessage = new Message();
        thisMessage.set("text", messageInput);
        thisMessage.set("from", USER.id);
        thisMessage.set("to", OTHER.id);
        thisMessage.set("parent_inbox", { __type: 'Pointer', className: 'Inbox', objectId: conversation.i });
        thisMessage.set("read", false);
        thisMessage.save()
    }

    const guidGenerator = () => {
        var S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }


    if (!OTHER) {
        return <></>;
    }

    const lastSeenDate = OTHER?.date;
    const status = getOnlineStatus(lastSeenDate);

    // this gives an object with dates as keys
    const groups = messages?.reduce((groups, message) => {

        const { date } = message

        const message_date = moment(date).format('dddd LL');

        if (!groups[message_date]) {
            groups[message_date] = [];
        }
        groups[message_date].push(message);

        return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
        return {
            date,
            messages: groups[date]
        };
    });

    const currentChat = groupArrays.map((item, index) => {

        const group_date = item.date;
        const group_messages = item.messages || [];

        const currentMessages = group_messages.map((item, index) => {

            const message_text = item.text;
            const message_date = moment(item.date).format('HH:mm');

            if (item.from === USER.id) {
                return (
                    <div
                        key={index}
                        className={`flex w-full justify-${'end'} my-2 px-2 scale-in`}
                    >
                        <div className={`bg-${'avioyx'}-100 text-${'white'} rounded-xl px-4 py-2 max-w-[80%]`}>
                            {message_text}
                            <div className='w-full text-xs opacity-40'>{message_date}</div>
                        </div>
                    </div>
                )
            }
            else {

                return (
                    <div
                        key={index}
                        className={`flex w-full justify-${'start'} my-2 px-2 scale-in`}
                    >
                        <div className={`bg-${'slate'}-100 text-${'black'} rounded-xl px-4 py-2 max-w-[80%]`}>
                            {message_text}
                            <div className='w-full text-xs opacity-40'>{message_date}</div>
                        </div>
                    </div>
                )

            }

        })


        return (
            <>
                <div className='flex items-center w-full text-xs text-center my-8 before:w-full before:h-[1px] before:bg-zinc-200 after:w-full after:h-[1px] after:bg-zinc-200'>
                    <div className='flex-shrink-0 mx-3 text-zinc-500'>{group_date}</div>
                </div>
                {currentMessages}
            </>
        )

    });


    return (
        <div className="h-full w-full flex flex-col p-0 fixed top-0 left-0 bottom-0 right-0 z-[9999] bg-white from-bottom">
            <div className="h-[70px] w-full flex px-3 items-center justify-start flex-shrink-0 bg-white border-b border-zinc-200">
                <img className='ml-2 w-[40px] h-[40px] object-cover rounded-full flex-shrink-0 border border-zinc-200 mr-2'
                    src={OTHER?.img}
                    onClick={() => onClose()} alt=""
                />
                <div className="flex flex-col">
                    <div className="font-bold">
                        {OTHER?.name}
                    </div>
                    <div className="text-xs">
                        {status}
                    </div>
                </div>
                <div className='flex items-center ml-auto text-sm'>
                    <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex lg:hidden items-center justify-center hover:bg-zinc-100 relative' onClick={() => onClose()}>
                        <img src={SRC_CLOSE} alt="" />
                    </div>
                </div>
            </div>

            <div className="w-full h-full overflow-auto relative flex flex-col" id="chat-container">

                <div ref={messagesEndRef} id={'lastMsg'} />

                {/* Display chat messages */}
                {isLoading ? (
                    <>
                        <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
                            <img className='animate-spin w-[24px] h-[24px]' src={SRC_LOADER} alt="" />
                        </div>
                    </>
                ) : (
                    <>
                        {currentChat}
                    </>
                )}



            </div>

            <div className='bg-white p-2 w-full flex-shrink-0 sticky bottom-0 flex items-center'>
                <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex lg:hidden items-center justify-center hover:bg-zinc-100 relative mr-2' onClick={() => alert('Want to add picker for sharing phone, images, dogs, videos, other providers,')}>
                    <img src={SRC_PLUS} alt="" />
                </div>
                <TextareaAutosize
                    placeholder={"Type here"}
                    className="rounded-lg bg-zinc-100 border-0 outline-0 ring-0 py-4 px-4 pr-12 w-full h-full max-h-[300px]"
                    cacheMeasurements
                    // onKeyPress={(e)=>onPressEnter(e)} 
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    // onBlur={() => AreaRef.current.focus()}
                    ref={AreaRef}
                />

                {messageInput?.trim()?.length > 0 ? (
                   <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex lg:hidden items-center justify-center hover:bg-zinc-100 relative ml-2' onClick={onSend}>
                        <img src={SRC_SEND} alt="" style={{ filter: 'brightness(0) saturate(100%) invert(73%) sepia(86%) saturate(5421%) hue-rotate(330deg) brightness(89%) contrast(91%)' }} />
                    </div>
                ) : (
                    <div className='w-[36px] scale-in h-[36px] rounded-lg cursor-pointer flex lg:hidden items-center justify-center hover:bg-zinc-100 relative ml-2'>
                        <img src={SRC_SEND} alt="" />
                    </div>
                )}
                {/* {messageInput?.trim()?.length > 0 ? (
                    <button
                        className='w-[50px] h-[50px] rounded-full flex items-center justify-center absolute top-[10px] right-[10px]'
                        onClick={onSend}
                    >
                        <img src={SRC_SEND} alt="" style={{ filter: 'brightness(0) saturate(100%) invert(73%) sepia(86%) saturate(5421%) hue-rotate(330deg) brightness(89%) contrast(91%)' }} />
                    </button>
                ) : (
                    <button className='w-[50px] h-[50px] rounded-full flex items-center justify-center absolute top-[10px] right-[10px]'
                    >
                        <img src={SRC_SEND} alt="" className="opacity-[0.5]" />
                    </button>
                )} */}

            </div>

        </div>
    )
}



function getOnlineStatus(lastSeenDateString) {
    // Convert the date string to a Date object
    const lastSeenDate = new Date(lastSeenDateString);

    // Check if the Date object is valid
    if (isNaN(lastSeenDate.getTime())) {
        return 'Invalid date format';
    }

    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - lastSeenDate;

    // Convert the time difference to seconds, minutes, hours, and days
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    // Determine the appropriate time unit to display
    if (secondsDifference < 5) {
        return 'online';
    } else if (minutesDifference < 1) {
        return 'last seen a few seconds ago';
    } else if (minutesDifference < 60) {
        return `last seen ${minutesDifference} minutes ago`;
    } else if (hoursDifference < 24) {
        return `last seen ${hoursDifference} hours ago`;
    } else if (daysDifference === 1) {
        const options = { hour: 'numeric', minute: 'numeric' };
        const formattedLastSeen = new Intl.DateTimeFormat('en-US', options).format(lastSeenDate);
        return `last seen yesterday at ${formattedLastSeen}`;
    } else if (daysDifference < 7) {
        const options = { weekday: 'long', hour: 'numeric', minute: 'numeric' };
        const formattedLastSeen = new Intl.DateTimeFormat('en-US', options).format(lastSeenDate);
        return `last seen on ${formattedLastSeen}`;
    } else {
        // Format the last seen date for display
        const options = { month: 'short', day: 'numeric' };
        const formattedLastSeen = new Intl.DateTimeFormat('en-US', options).format(lastSeenDate);
        return `last seen on ${formattedLastSeen}`;
    }
}

export default ChatAlpha