import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';

import SRC_SPEED from '../../assets/img/icons/speed.svg';
import SRC_ALT from '../../assets/img/icons/arrow-up.svg';
import SRC_TEMP from '../../assets/img/icons/temperature.svg';
import SRC_MAP from '../../assets/img/map.png';
import SRC_CLOSE from '../../assets/img/icons/close.svg';
import SRC_MIN from '../../assets/img/icons/minimize.svg';
import SRC_FLIGHTS from '../../assets/img/icons/flights.svg';
import SRC_COMP from '../../assets/img/icons/compass.svg';
import SRC_FUEL from '../../assets/img/icons/fuel.svg';
import SRC_TIME from '../../assets/img/icons/time.svg';
import SRC_CHEVRON from '../../assets/img/icons/chevron.svg';

const Flight = (props) => {

    const {  LANGUAGE, TRANSLATE, homelang } = props;

    return (
        <>
            <HeaderSEO {...props}
                LANGUAGE={homelang || LANGUAGE}

            />

            <div className='w-full h-full fixed top-0 left-0 z-[9999] bg-avioyx-200 flex'>
                <div className='w-1/2 h-full p-10'>
                    <div className='w-full flex items-start justify-between'>
                        <div className='flex items-center gap-x-2'>
                            <img className='cursor-pointer' src={SRC_CLOSE} />
                            <img className='cursor-pointer' src={SRC_MIN} />
                        </div>
                        <div className='flex items-center gap-x-2'>
                            <div className='flex items-center mr-3'>
                                <div className='w-3 h-3 animate-pulse rounded-full bg-lime-400 mr-2' />
                                <div className='text-lime-400'>In Flight</div>
                            </div>
                            <button className="px-4 py-2 bg-sky-500 rounded-lg hover:bg-sky-600">Finish Flight</button>
                        </div>
                    </div>
                    <div className='flex w-full justify-between items-center my-10'>
                        <div className='text-3xl font-semibold flex-shrink-0'>SFO</div>
                        <div className='w-full h-[2px] bg-black mx-6 relative'>
                            <div className='absolute left-0 top-0 h-full w-[44%] bg-sky-500'>
                                <div className='w-[10px] h-[10px] rounded-full bg-sky-500 absolute right-0 -top-[4px]' />
                            </div>
                            <div className='absolute top-3 w-full flex items-center justify-center opacity-50 text-xs'>
                                I-CNTR - Cessna - C152
                            </div>
                        </div>
                        <div className='text-3xl font-semibold flex-shrink-0'>SAN</div>
                    </div>

                    <div className='w-full flex flex-col gap-y-1'>
                        <div className='rounded-lg  bg-avioyx-100 p-6 cursor-pointer hover:bg-avioyx-50 relative'> 
                            <div className='text-lg font-semibold flex items-center'>
                                <div className='w-3 h-3 animate-pulse rounded-full bg-sky-500 mr-4 ml-1' />
                                Waypoint 3 - Waypoint 4
                            </div>
                            <div className='flex gap-x-10 mt-6'>
                                <div className='flex items-center'>
                                    <img className='mr-2 w-[22px]' src={SRC_ALT} />
                                    1000 ft
                                </div>
                                <div className='flex items-center'>
                                    <img className='mr-2 w-[22px]' src={SRC_COMP} />
                                    248°
                                </div>
                                <div className='flex items-center'>
                                    <img className='mr-2 w-[22px]' src={SRC_FLIGHTS} />
                                    40.08 NM
                                </div>
                                <div className='flex items-center'>
                                    <img className='mr-2 w-[22px]' src={SRC_FUEL} />
                                    40 L
                                </div>
                            </div>
                        </div>
                        <div className='rounded-lg  bg-avioyx-100 p-6 opacity-50 cursor-pointer hover:bg-avioyx-50 relative'> 
                            <div className='text-lg font-semibold flex items-center'>
                                <div className='w-3 h-3 rounded-full bg-black mr-4 ml-1' />
                                Waypoint 3 - Waypoint 4
                            </div>
                        </div>
                    </div>
                    <div className='w-full grid grid-cols-3 mt-6 gap-4'>
                        <div className='rounded-lg  bg-avioyx-100 p-6 py-12 cursor-pointer text-center relative'> 
                            <img src={SRC_TIME} className='blue-icon absolute top-3 right-3' />
                            <div className='text-xl font-semibold'>01:11:46</div>
                            <div className='font-light text-gray-400 text-xs'>Timer</div>
                        </div>
                        <div className='rounded-lg  bg-avioyx-100 p-6 py-12 cursor-pointer text-center relative'> 
                            <img src={SRC_SPEED} className='blue-icon absolute top-3 right-3' />
                            <div className='text-xl font-semibold'>80kt</div>
                            <div className='font-light text-gray-400 text-xs'>Ground speed</div>
                        </div>
                        <div className='rounded-lg  bg-avioyx-100 p-6 py-12 cursor-pointer text-center relative'> 
                            <img src={SRC_COMP} className='blue-icon absolute top-3 right-3' />
                            <div className='text-xl font-semibold'>248°</div>
                            <div className='font-light text-gray-400 text-xs'>Heading</div>
                        </div>
                        <div className='rounded-lg  bg-avioyx-100 p-6 py-12 cursor-pointer text-center relative'> 
                            <img src={SRC_ALT} className='blue-icon absolute top-3 right-3' />
                            <div className='text-xl font-semibold'>912ft</div>
                            <div className='font-light text-gray-400 text-xs'>Altitude</div>
                        </div>
                        <div className='rounded-lg  bg-avioyx-100 p-6 py-12 cursor-pointer text-center relative'> 
                            <img src={SRC_SPEED} className='blue-icon absolute top-3 right-3' />
                            <div className='text-xl font-semibold'>310ft/min</div>
                            <div className='font-light text-gray-400 text-xs'>Vertical Speed</div>
                        </div>
                        <div className='rounded-lg  bg-avioyx-100 p-6 py-12 cursor-pointer text-center relative'> 
                            <img src={SRC_FLIGHTS} className='blue-icon absolute top-3 right-3' />
                            <div className='text-xl font-semibold'>104NM</div>
                            <div className='font-light text-gray-400 text-xs'>Distance Flown</div>
                        </div>
                        <div className='rounded-lg flex justify-between col-span-3  bg-avioyx-100 p-6 py-6 cursor-pointer items-center text-center relative'> 
                            <div className='font-light text-gray-400 text-xs'>GPS Coordinates</div>
                            <div className='text-xl font-semibold'>29.721 N / 95.402 W</div>
                        </div>
                    </div>
                </div>
                <div className='w-1/2 h-full relative'>
                    <img src={SRC_MAP} className='w-full h-full object-cover' />
                    <div className='w-px h-full flex items-center justify-start absolute left-0 top-0'>
                        <div className='py-6 px-2 bg-avioyx-200 rounded-tr-xl rounded-br-xl flex-shrink-0'>
                         <img className='rotate-180' src={SRC_CHEVRON} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const HeaderSEO = (props) => {

    const { TRANSLATE, LANGUAGE } = props;

    const routeUrl = 'https://www.avioyx.com';
    const shareUrl = routeUrl + '/' + LANGUAGE;
    const imageUrl = routeUrl + "/assets/img/landing/hero.jpg";

    return (

        <Helmet prioritizeSeoTags>

            <html lang={LANGUAGE} />

            <title>{TRANSLATE.seo_title}</title>
            <meta name="description" content={TRANSLATE.seo_description} />

            <meta itemprop="name" content={TRANSLATE.seo_title} />
            <meta itemprop="description" content={TRANSLATE.seo_description} />
            <meta itemprop="image" content={imageUrl} />

            <link rel="canonical" href={routeUrl} />
            <link rel="alternate" href={routeUrl + '/en'} hrefLang='x-default' />
            <link rel="alternate" href={routeUrl + '/it'} hrefLang='it' />
            <link rel="alternate" href={routeUrl + '/fr'} hrefLang='fr' />
            <link rel="alternate" href={routeUrl + '/es'} hrefLang='es' />
            <link rel="alternate" href={routeUrl + '/de'} hrefLang='de' />
            <link rel="alternate" href={routeUrl + '/ar'} hrefLang='ar' />
            <link rel="alternate" href={routeUrl + '/ja'} hrefLang='ja' />
            <link rel="alternate" href={routeUrl + '/zh'} hrefLang='zh' />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@publisher_handle" />
            <meta name="twitter:title" content={TRANSLATE.seo_title} />
            <meta name="twitter:description" content={TRANSLATE.seo_description} />
            <meta name="twitter:creator" content="@avioyx" />
            <meta name="twitter:image:src" content={imageUrl} />

            <meta property="og:title" content={TRANSLATE.seo_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={shareUrl} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:description" content={TRANSLATE.seo_description} />
            <meta property="og:site_name" content="avioyx" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta property='fb:app_id' content='236904211103455' />

            <meta itemprop="thumbnailUrl" content={imageUrl} />
            <meta itemprop="image_src" content={imageUrl} />
            <meta itemprop="image" content={imageUrl} />

        </Helmet>
    )
}



export default Flight