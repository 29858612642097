import React, {useState, useEffect, useRef} from 'react';

//IMAGES
import { GetRouteSearchParams } from '../../helpers/GeneralHelper';

import InputRange from 'react-input-range'

import SRC_CLOSE from '../../assets/img/close.svg';
import 'react-input-range/lib/css/index.css'
 

const SidebarMobile = (props) => {

    const { onClose } = props; 

    const {history, match, location} = props;

    const [isModalBreed, setModalBreed] = useState(false);

    const [Breed, setBreed] = useState('');
    const [Gender, setGender] = useState('');
    const [Distance, setDistance] = useState(100)
    const [Age, setAge] = useState({
        min: 0,
        max: 20,
    })

    useEffect(() => {
        const routeParams = GetRouteSearchParams(location.search);
        const routeBreed = routeParams.breed || null;
        const routeGender = routeParams.gender || null;
        const routeDistance = routeParams.distance || 100;
        const routeMin = routeParams.min || 0;
        const routeMax = routeParams.max || 20;

        // Update the state with the route values
        setBreed(routeBreed);
        setGender(routeGender);
        setDistance(routeDistance)
        setAge({
            min: routeMin,
            max: routeMax
        });

    }, []);

    const onSelectBreed = (breed) => {

        if(!breed){
            return setModalBreed(true);
        }

        setBreed(breed.breed);
    }

    const onSaveFilters = () => {
        const routeParams = GetRouteSearchParams(location.search);
        const routeType = routeParams.type || 'dating';

        // Logic to save the selected filters
        let FORM = {
            type: routeType,
            distance: Distance,
            min: Age.min,
            max: Age.max
        }

        if(Breed){
            FORM = {...FORM, breed: Breed}
        }

        if(Gender){
            FORM = {...FORM, breed: Gender}
        }

        const queryString = new URLSearchParams(FORM).toString()

        //swal('Form', queryString, 'success');
        history.push('?'+queryString);

        onClose()
    };
    
    const matchParams = match.params;

    const routeType = matchParams.code || 'dog-training';

    if(routeType === 'dog-training' || routeType === 'dog-sitting' || routeType === 'dog-walking'){

        return (
            <>
                <div className="fixed w-screen h-full top-0 left-0 z-[9999]">
                    <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
                    <div className="w-full max-w-[500px] bg-white h-full z-20 relative ml-auto flex flex-col slider-in overflow-auto">
                        <div className="flex justify-between p-8 sticky top-0 bg-white z-20">
                            
                            <img className="cursor-pointer" src={SRC_CLOSE} onClick={() => onClose()} alt='' />
                            
                            {routeType === 'sitting' && (
                                <div className='text-xl'>Your Dog Sitting filters</div>
                            )}
                            {routeType === 'training' && (
                                <div className='text-xl'>Your Dog Training filters</div>
                            )}
                           
                            <div />
    
                        </div>
                        
    
                        <div className='flex flex-col h-full overflow-auto px-8'>
    
                        
                        <hr className='w-full bg-gray-200 my-6' />
                            <div className='w-full my-4'>
                                <label className='text-sm font-semibold mb-8 block w-full'>Distance</label>
                                <InputRange
                                    maxValue={200}
                                    minValue={0}
                                    formatLabel={value => `${value} km`}
                                    value={Distance}
                                    onChange={value => setDistance(value)} 
                                />
    
                                 <div className='text-xs text-gray-500 mt-4'>up to {Distance} km away</div>
                               
                            </div>
                            <hr className='w-full bg-gray-200 my-6' />
                           
                        </div>
                        <div className="flex drop-shadow-xl items-center mx-8 justify-center text-white py-4 text-base font-normal bg-avioyx-100 mb-8 cursor-pointer rounded-lg" onClick={()=> onSaveFilters()}>
                            Save changes
                        </div>
                        
                     
                    </div>    
    
                </div>
            </>
        )
    }

    return (
        <>
            <div className="fixed w-screen h-full top-0 left-0 z-[9999]">
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
                <div className="w-full max-w-[500px] bg-white h-full z-20 relative ml-auto flex flex-col slider-in overflow-auto">
                    <div className="flex justify-between p-8 sticky top-0 bg-white z-20">
                        
                        <img className="cursor-pointer" src={SRC_CLOSE} onClick={() => onClose()} alt='' />
                        
                        {routeType === 'dog-adoption' && (
                            <div className='text-xl'>Your Dog Adoption filters</div>
                        )}
                        {routeType === 'dog-friendship' && (
                            <div className='text-xl'>Your Dog Walking filters</div>
                        )}
                        {routeType === 'dog-breeding' && (
                            <div className='text-xl'>Your Dog Breeding filters</div>
                        )}
                      
                       
                        <div />

                    </div>
                    

                    <div className='flex flex-col h-full overflow-auto px-8'>

                        <div className='w-full my-4'>
                            <label className='text-sm font-semibold mb-8 block w-full'>Age range</label>
                            <div className='w-full'>
                                <InputRange
                                    maxValue={30}
                                    minValue={0}
                                    formatLabel={value => `${value} years`}
                                    value={Age}
                                    onChange={value => setAge(value)}
                                    onChangeComplete={value => console.log(value)} 
                                    
                                />
                            </div>
                            <div className='text-xs text-gray-500 mt-4'>Between {Age?.min} and {Age?.max} years old</div>
                        </div>
                        <hr className='w-full bg-gray-200 my-6' />
                        <div className='w-full my-4'>
                            <label className='text-sm font-semibold mb-8 block w-full'>Distance</label>
                            <div className='w-full'>
                                <InputRange
                                    maxValue={200}
                                    minValue={0}
                                    formatLabel={value => `${value} km`}
                                    value={Distance}
                                    onChange={value => setDistance(value)} 
                                />
                            </div>
                             <div className='text-xs text-gray-500 mt-4'>up to {Distance} km away</div>
                        </div>
                        <hr className='w-full bg-gray-200 my-6' />
                        <div className='w-full my-4'>
                            <label className='text-sm font-semibold mb-4 block w-full'>Gender</label>
                            <div className='text-base flex justify-between items-center w-full mb-2' onClick={()=> setGender((prev)=> prev === 'male' ? '' : 'male')}>
                                Male
                                {Gender === 'male' ? (
                                    <div className='w-[30px] h-[30px] rounded-lg bg-orange-700 flex items-center justify-center cursor-pointer before:w-[14px] before:h-[8px] before:border-l before:border-b before:block before:-mt-1 before:-rotate-45 before:border-white' />
                                ) : (
                                    <div className='w-[30px] h-[30px] rounded-lg bg-gray-200 flex items-center justify-center cursor-pointer' />
                                )}
                            </div>
                            <div className='text-base flex justify-between items-center w-full' onClick={()=> setGender((prev)=> prev === 'female' ? '' : 'female')}>
                                Female
                                {Gender === 'female' ? (
                                    <div className='w-[30px] h-[30px] rounded-lg bg-orange-700 flex items-center justify-center cursor-pointer before:w-[14px] before:h-[8px] before:border-l before:border-b before:block before:-mt-1 before:-rotate-45 before:border-white' />
                                ) : (
                                    <div className='w-[30px] h-[30px] rounded-lg bg-gray-200 flex items-center justify-center cursor-pointer' />
                                )}
                            </div>
                        </div>
                        <hr className='w-full bg-gray-200 my-6' />

                        <div className="input-group w-full pr-1" onClick={()=> onSelectBreed()}>
                            <label className='capitalize'>Breed</label>
                            <span className="inner-label capitalize">Breed</span>
                            <input type="text" placeholder="Golden Retriever"
                                onChange={(e) => setBreed(e.target.value)}
                                value={Breed}
                                style={{ paddingRight: 60 }}
                                id={'BreedInput'}

                            />
                        </div>
                        {/* <div className='w-full my-4'>
                            <label className='text-sm font-semibold mb-4 block w-full'>Breed</label>
                         
                            <select value={Breed} onChange={(e) => setBreed(e.target.value)} className='w-full h-[50px] bg-transparent border border-gray-200 rounded-lg focus:ring-0 focus:outline-none'>
                                <option value="">
                                   Select a Dog Breed
                                </option>
                                {BreedJSON.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    )
                                })}
                            </select>
                        </div> */}
                    </div>
                    <div className="flex drop-shadow-xl items-center mx-8 justify-center text-white py-4 text-base font-normal bg-avioyx-100 mb-8 cursor-pointer rounded-lg" onClick={()=> onSaveFilters()}>
                        Save changes
                    </div>
                    
                 
                </div>    

            </div>

        </>
    )
}


export default SidebarMobile