import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { isDesktop } from 'react-device-detect';

import Landing from '../Public/Landing';


const PublicContainer = (props) => {

  
  const { TRANSLATE, USER, CART, LANGUAGE, onUpdateLanguage, onFetchUser, onFacebookTracking, onKlaviyoTracking, onFetchAnalytics, setOverflow, location, match, history } = props;

  const [isSelecting, setSelecting] = useState(false); 

  const sharedProps = {
    ...props,
    TRANSLATE,
    USER,
    CART,
    LANGUAGE,
    onUpdateLanguage,
    onFetchUser,
    onFacebookTracking,
    onKlaviyoTracking,
    setOverflow,
    isSelecting,
    setSelecting
  }


  useEffect(() => {

    const language = match.params.lang;
    const langArray = ['en', 'it', 'fr', 'es'];

    if(langArray.includes(language)){
      onUpdateLanguage(language);
    }
    else{
      const LANG = localStorage.getItem('language') || 'en';
      onUpdateLanguage(LANG);
    }

  }, [match.params.lang]);


  useEffect(() => {
    onFetchAnalytics({ location });
    onFacebookTracking('pageView');

    window.scrollTo(0, 0);

  }, [match.url]);



  const { isModalCart, isModalSearch, isModalLocalisation, isModalUser, isModalProvider, isModalGuide, isModalCity } = props;

  useEffect(() => {

    const isActiveChat = match.params.store && match.params.customer && !isDesktop;

    if (isModalCart || isModalSearch || isModalLocalisation || isModalUser || isModalProvider || isModalGuide || isActiveChat || isModalCity) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isModalCart, isModalSearch, isModalLocalisation, isModalUser, isModalProvider, isModalGuide, isModalCity, match.params])



  return (

    <>

      <Switch>

          <Route exact path="/" render={(props) => <Landing {...sharedProps} {...props} />} />
          <Route exact path="/en" render={(props) => <Landing {...sharedProps} {...props} />} /> 
          <Route exact path="/fr" render={(props) => <Landing {...sharedProps} {...props} />} />
          <Route exact path="/it" render={(props) => <Landing {...sharedProps} {...props} />} />
          <Route exact path="/es" render={(props) => <Landing {...sharedProps} {...props} />} />

          
      </Switch>

    </>

  )


}


export default PublicContainer;
