import React from "react";
import { Link } from "react-router-dom";

import MobileBar from "./MobileBar.js";

import SRC_APPSTORE from '../../assets/img/app-store.png';
import SRC_PLAYSTORE from '../../assets/img/play-store.png';


const Footer = (props) => {

    const {TRANSLATE, LANGUAGE, match, isProvider, setModalSearch} = props;

    const CURRENT_URL = match.url;
    const isPublic = CURRENT_URL.includes('/public/');
    const isPosts = CURRENT_URL.includes('/post/');
    const isFlow = CURRENT_URL.includes('/flow');
   
    const isFooter = false; //(!isProviderArea && !isNotifications && !isFlow && !isInbox);

    if(isPublic || isPosts || isFlow){
        return <></>
    }

    return (
        <>

            <MobileBar {...props} />
            
            {isFooter && (
                <footer className="py-8 w-full bg-white">
                    <div className="mx-auto flex-wrap max-w-8xl px-4 sm:px-6 lg:px-8 border-t border-t-px border-zinc-200 text-xs flex items-start">
                        <div className='w-full md:w-[20%] py-10 flex flex-col'>
                            <div className="flex items-center">
                                {/* <img className='w-[30px] h-[30px] object-contain' src={SRC_LOGO} /> */}
                                © 2023 avioyx
                            </div>
                            <div className="btn-auto mr-auto mt-6" onClick={()=> setModalSearch(true)}>
                                {TRANSLATE.btn_book_now}
                            </div>
                        </div>

                        <div className='w-1/2 md:w-[20%] py-10 flex flex-col'>
                            <div className='font-semibold mt-2 mb-4'>
                                {TRANSLATE.wd_learnmore}
                            </div>
                            <Link to={'/'+LANGUAGE+'/about'} className='mb-2'>
                                {TRANSLATE.wd_aboutus}
                            </Link>
                            <Link to={'/'+LANGUAGE+'/how-it-works'} className='mb-2'>
                                {TRANSLATE.wd_howitworks}
                            </Link>
                            <div className='mb-2' onClick={()=> setModalSearch(true)}>
                                {TRANSLATE.btn_book_now}
                            </div>
                            <Link to={'/'+LANGUAGE+'/become-provider'} className='mb-2'>
                                {TRANSLATE.btn_become_provider}
                            </Link>
                            <a href={'/'+LANGUAGE+'/blog'} className='mb-2' target="_blank">
                                {TRANSLATE.wd_blog}
                            </a>
                            <Link to={'/'+LANGUAGE+'/trust-and-safety'} className='mb-2'>
                                {TRANSLATE.wd_trust}
                            </Link>
                            <Link to={'/'+LANGUAGE+'/download'} className='mb-2'>Download</Link>
                        </div>
                        <div className='w-1/2 md:w-[20%] py-10 flex flex-col'>
                            <div className='font-semibold mt-2 mb-4'>
                                {TRANSLATE.wd_where_we_are}
                            </div>
                           
                            <Link className='mb-2' to={'/'+LANGUAGE+'/locations'}>
                                {TRANSLATE.wd_other_cities}
                            </Link>
                        </div>
                        <div className='w-1/2 md:w-[20%] py-10 flex flex-col'>
                            <div className='font-semibold mt-2 mb-4'>
                                {TRANSLATE.wd_support}
                            </div>
                            <Link to={'/'+LANGUAGE+'/legal/terms'} className='mb-2'>
                                {TRANSLATE.wd_terms}
                            </Link>
                            <Link to={'/'+LANGUAGE+'/legal/privacy'} className='mb-2'>
                                {TRANSLATE.wd_privacy}
                            </Link>
                            <Link to={'/'+LANGUAGE+'/legal/cancellation'} className='mb-2'>
                                {TRANSLATE.wd_cancellation}
                            </Link>
                        </div>
                        <div className='w-1/2 md:w-[20%] py-10 flex flex-col'>
                            <div className='font-semibold mt-2 mb-4'>
                                {TRANSLATE.wd_socialmedia}
                            </div>
                            <a className='mb-2' href="https://www.facebook.com/avioyx" target='_blank'>Facebook</a>
                            <a className='mb-2' href="https://www.instagram.com/avioyx/" target='_blank'>Instagram</a>
                            <a className='mb-2' href="https://www.linkedin.com/company/avioyx/" target='_blank'>LinkedIn</a>
                            <div className='font-semibold mt-6 mb-4'>{TRANSLATE.download_page.t}</div>
                            <a href="https://apps.apple.com/us/app/avioyx/id1572432866?ign-mpt=uo%3D2" target='_blank' className='mb-2'><img className='w-[120px]' src={SRC_APPSTORE} /></a>
                            <a href='https://play.google.com/store/apps/details?id=com.avioyx.app' target='_blank' className='mb-2'><img className='w-[120px]' src={SRC_PLAYSTORE} /></a>
                        </div>

                    </div>
                </footer>
            )}
            
        </>
    )
}

export default Footer