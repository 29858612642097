import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import AuthFormLogin from './AuthFormLogin';

import SRC_DOG_1 from './dog1.jpg';
import SRC_DOG_2 from './dog2.jpg';
import SRC_LOGO from '../../assets/img/logo-white.svg';

import { GetRouteSearchParams } from '../../helpers/GeneralHelper';

import swal from 'sweetalert';
import { API_ROOT } from '../../api';
import axios from 'axios';

const AuthLogin = (props) => {

    const { TRANSLATE, LANGUAGE, location, history, onStartUser } = props;

    const routeParams = GetRouteSearchParams(location.search);
    const routeCode = routeParams.code || '';

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {

        const routeParams = GetRouteSearchParams(location.search);
        const routeCode = routeParams.code || '';

        if (routeCode) {
            //alert('The Code is ===> '+routeCode);
            onLoginGoogleFlow(routeCode);
        }

    }, [location.search])

    const onLoginGoogleFlow = async (authCode) => {

        const isLocalhost = window.location.href.includes('localhost:3000');

        try {

            setLoading(true);

            const clientId = '863081838943-30fkte6e38age2e1jj31nl9jm0it54t9.apps.googleusercontent.com';
            const clientSecret = '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';
            const redirectUri = isLocalhost ? 'http://localhost:3000/en/login' : 'https://www.avioyx.com/en/login';

            const tokenEndpoint = 'https://oauth2.googleapis.com/token';
            const userDataEndpoint = 'https://www.googleapis.com/oauth2/v3/userinfo';

            const data = new URLSearchParams();
            data.append('code', authCode);
            data.append('client_id', clientId);
            data.append('client_secret', clientSecret);
            data.append('redirect_uri', redirectUri);
            data.append('grant_type', 'authorization_code');

            // Step 1: Exchange auth code for access token and refresh token
            const tokenResponse = await axios.post(tokenEndpoint, data,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            //return swal('success', JSON.stringify(tokenResponse, null, 2), 'success');


            const tokenData = tokenResponse.data;


            // Step 2: Fetch user data using access token
            const userDataResponse = await fetch(userDataEndpoint, {
                headers: {
                    Authorization: `Bearer ${tokenData.access_token}`
                }
            });

            const userData = await userDataResponse.json();

            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


            var FORM = {
                lang: LANGUAGE,
                oauth: {
                    ...userData,
                    accessToken: tokenData.access_token,
                    refreshToken: tokenData.refresh_token,
                    picture: userData.picture ? userData.picture.replace('s96', 's512') : ''

                },
                timezone: userTimezone

            }

            //return swal('success', JSON.stringify(FORM, null, 2), 'success');

            axios.post(API_ROOT + '/users/login/google', FORM).then(response => {

                setLoading(false);

                localStorage.setItem('jwt_token', response.data.jwt_token);

                const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
                if (isNativeApp) {
                    const webData = {
                        type: 'LOGIN',
                        data: response.data.jwt_token
                    }
                    window.ReactNativeWebView.postMessage(JSON.stringify(webData));
                }

                onStartUser();

                history.push(`/${LANGUAGE}/settings`);

            })
                .catch(error => {

                    setLoading(false);

                    history.push(`/${LANGUAGE}/login`);

                    if (!error || !error.response) {
                        return swal('Login', TRANSLATE.error_invalid_credentials, 'warning')
                    }

                    var error_data = error.response.data;
                    var error_message = error_data.error;

                    if (error_message === 'missing_password') {
                        return swal('Login', TRANSLATE.error_missing_password, 'warning')
                    }
                    else if (error_message === 'missing_email') {
                        return swal('Login', TRANSLATE.error_invalid_email, 'warning')
                    }

                    return swal('Login', TRANSLATE.error_invalid_credentials, 'warning')
                });

        }
        catch (e) {
            setLoading(false);
            history.push(`/${LANGUAGE}/login`);
            swal('error', e.message, 'success')
        }

    }

    return (
        <>

            <Helmet prioritizeSeoTags>

                <html lang={LANGUAGE} />

                <title>{'Avioyx.app | ' + TRANSLATE.wd_login}</title>

            </Helmet>

            <div className="w-full h-full fixed top-0 left-0 bg-avioyx-100 z-50">
                {routeCode ? (
                    <div className='w-full flex flex-col items-center justify-center' style={{height: 'calc(100% - 200px)'}}>
                        <div className='avioyx-circle bg-white'>
                            <div className='avioyx-paw-small left bg-white' />
                            <div className='avioyx-paw-small right bg-white' />
                            <div className='avioyx-paw-big left bg-white' />
                            <div className='avioyx-paw-big right bg-white' />
                        </div>
                        <div className="text-lg text-white">Loading...</div>
                    </div>
                ) : (
                    <div className="mx-auto max-w-[320px] flex flex-col h-full md:flex-nowrap items-center justify-between">

                        <Link to={`/${LANGUAGE}`}>
                            <img className='w-32 mt-10 flex-shrink-0 mb-8 from-top' src={SRC_LOGO} alt=''/>
                        </Link>
                        
                        <div className='relative'>
                            <img className='scale-in h-[400px] w-[300px] object-cover rounded-3xl absolute -right-3 drop-shadow-2xl -top-6 rotate-6' src={SRC_DOG_1} alt=''/>
                            <img className='scale-in h-[400px] w-[300px] z-20 object-cover rounded-3xl absolute -left-3 drop-shadow-2xl top-6 -rotate-6' src={SRC_DOG_2} alt='' />

                            <AuthFormLogin {...props}
                                ContainerClass="flex flex-col w-full bg-white rounded-3xl relative z-40 overflow-hidden scale-in"
                                ContainerStyles={{ boxShadow: '0 25px 25px rgb(0 0 0 / 0.15)' }}
                                AsterixClass="text-avioyx-100"
                                ButtonClass="btn-auto ml-auto px-3 py-4 w-full text-center flex justify-center"
                                ButtonsClass="w-full flex items-center justify-between flex-col"
                                GoogleClass="btn-blank px-3 w-full flex justify-center items-center"
                                OriginFrom="root"
                            />
                        </div>

                        <p className="text-sm my-6 relative z-20 scale-in">{TRANSLATE.auth_login.c}{' '}
                            <Link className="text-white" to={'/' + LANGUAGE + '/recovery'}>{TRANSLATE.wd_click_here}</Link>
                        </p>
                    </div>
                )}
            </div>
        </>
    )
}

export default AuthLogin

