import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';

import SRC_LOGO_ICON from '../../assets/img/logo_icon.png';

const Shop = (props) => {

    const {  LANGUAGE, TRANSLATE, homelang } = props;

    return (
        <>
            <HeaderSEO {...props}
                LANGUAGE={homelang || LANGUAGE}

            />

            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10">
                    <div className="flex w-full items-center justify-center">

                        <div className="md:w-2/3 w-full lg:pl-14 py-2 text-center">
                            <div className="flex flex-col items-center mb-6 justify-between md:flex-nowrap flex-wra mt-8 w-full">
                                <div className="md:text-9xl text-5xl font-bold md:w-auto w-full md:mb-0 mb-4 capitalize">
                                    Shop
                                </div>
                                <p className='text-lg font-normal md:mt-8'>
                                    Welcome to avioyx, the leading aviation tech company revolutionizing the way pilots, flight academies, airline companies, and aircraft manufacturers operate. Our innovative platforms for web, iOS, and Android provide a comprehensive suite of tools and services tailored to meet the needs of aviation professionals worldwide.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="flex w-full items-center justify-center">

                        <div className="w-full lg:pl-14 py-2">
                            <div className="flex items-center mb-6 justify-between md:flex-nowrap flex-wra mt-8">
                                <img src={SRC_LOGO_ICON} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


const HeaderSEO = (props) => {

    const { TRANSLATE, LANGUAGE } = props;

    const routeUrl = 'https://www.avioyx.com';
    const shareUrl = routeUrl + '/' + LANGUAGE;
    const imageUrl = routeUrl + "/assets/img/landing/hero.jpg";

    return (

        <Helmet prioritizeSeoTags>

            <html lang={LANGUAGE} />

            <title>{TRANSLATE.seo_title}</title>
            <meta name="description" content={TRANSLATE.seo_description} />

            <meta itemprop="name" content={TRANSLATE.seo_title} />
            <meta itemprop="description" content={TRANSLATE.seo_description} />
            <meta itemprop="image" content={imageUrl} />

            <link rel="canonical" href={routeUrl} />
            <link rel="alternate" href={routeUrl + '/en'} hrefLang='x-default' />
            <link rel="alternate" href={routeUrl + '/it'} hrefLang='it' />
            <link rel="alternate" href={routeUrl + '/fr'} hrefLang='fr' />
            <link rel="alternate" href={routeUrl + '/es'} hrefLang='es' />
            <link rel="alternate" href={routeUrl + '/de'} hrefLang='de' />
            <link rel="alternate" href={routeUrl + '/ar'} hrefLang='ar' />
            <link rel="alternate" href={routeUrl + '/ja'} hrefLang='ja' />
            <link rel="alternate" href={routeUrl + '/zh'} hrefLang='zh' />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@publisher_handle" />
            <meta name="twitter:title" content={TRANSLATE.seo_title} />
            <meta name="twitter:description" content={TRANSLATE.seo_description} />
            <meta name="twitter:creator" content="@avioyx" />
            <meta name="twitter:image:src" content={imageUrl} />

            <meta property="og:title" content={TRANSLATE.seo_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={shareUrl} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:description" content={TRANSLATE.seo_description} />
            <meta property="og:site_name" content="avioyx" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta property='fb:app_id' content='236904211103455' />

            <meta itemprop="thumbnailUrl" content={imageUrl} />
            <meta itemprop="image_src" content={imageUrl} />
            <meta itemprop="image" content={imageUrl} />

        </Helmet>
    )
}



export default Shop