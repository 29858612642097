import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';

import SRC_LOGO_ICON from '../../assets/img/logo_icon.png';
import SRC_AIRCRAFTS from '../../assets/img/icons/aircrafts.svg';
import SRC_COURSES from '../../assets/img/icons/courses.svg';
import SRC_FLIGHTS from '../../assets/img/icons/flights.svg';
import SRC_LOGBOOK from '../../assets/img/icons/logbook.svg';
import SRC_LICENSES from '../../assets/img/icons/licenses.svg';
import SRC_CALENDAR from '../../assets/img/icons/calendar.svg';
import SRC_CALCULATOR from '../../assets/img/icons/calculator.svg';
import SRC_AIRPORTS from '../../assets/img/icons/airports.svg';
import SRC_COMMUNITY from '../../assets/img/icons/community.svg';
import SRC_SPEED from '../../assets/img/icons/speed.svg';
import SRC_ALT from '../../assets/img/icons/arrow-up.svg';
import SRC_TEMP from '../../assets/img/icons/temperature.svg';
import SRC_GLIDE from '../../assets/img/icons/glide.svg';
import SRC_CLOSE from '../../assets/img/icons/close.svg';

const Calculator = (props) => {

    const {  LANGUAGE, TRANSLATE, homelang } = props;

    return (
        <>
           {/* <CalcSlider />*/}
            <HeaderSEO {...props}
                LANGUAGE={homelang || LANGUAGE}

            />

            <div className='w-full grid gap-4 grid-cols-4 pb-10'>
                <div className='col-span-4 flex items-center font-semibold'>
                    <img className='mr-3 blue-icon' src={SRC_SPEED} />Speed
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>TAS</div>
                    <div className='font-light text-gray-400 text-xs'>True Air Speed</div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>CAS</div>
                    <div className='font-light text-gray-400 text-xs'>Calibrated Air Speed</div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>EAS</div>
                    <div className='font-light text-gray-400 text-xs'>Equivalent Air Speed</div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>MN</div>
                    <div className='font-light text-gray-400 text-xs'>Mach Number</div>
                </div>

                <div className='col-span-4 flex items-center font-semibold mt-8'>
                    <img className='mr-3 blue-icon' src={SRC_ALT} />Altitude
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>cQNH</div>
                    <div className='font-light text-gray-400 text-xs'>QNH Correction</div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>PA</div>
                    <div className='font-light text-gray-400 text-xs'>Pressure Altitude</div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>DA</div>
                    <div className='font-light text-gray-400 text-xs'>Density Altitude</div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>TA</div>
                    <div className='font-light text-gray-400 text-xs'>True Altitude</div>
                </div>

                <div className='col-span-4 flex items-center font-semibold mt-8'>
                    <img className='mr-3 blue-icon' src={SRC_TEMP} />Temperature
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>SAT</div>
                    <div className='font-light text-gray-400 text-xs'>Saturated Air Temperature</div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>ISA</div>
                    <div className='font-light text-gray-400 text-xs'>International Standart Altitude</div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>Cloud Base</div>
                    <div className='font-light text-gray-400 text-xs'>Dewpoint & OAT</div>
                </div>

                <div className='col-span-4 flex items-center font-semibold mt-8'>
                    <img className='mr-3 blue-icon' src={SRC_GLIDE} />Glidepath
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>Glidepath Angle</div>
                    <div className='font-light text-gray-400 text-xs'>% of GlidePath</div>
                </div>
                <div className='rounded-lg  bg-avioyx-100 p-6 py-20 cursor-pointer hover:blue-shadow text-center transition-all duration-100'> 
                    <div className='text-xl font-semibold'>Glidepath Height</div>
                    <div className='font-light text-gray-400 text-xs'>Angle and Distance to go</div>
                </div>
                
            </div>
        </>
    )
}


const HeaderSEO = (props) => {

    const { TRANSLATE, LANGUAGE } = props;

    const routeUrl = 'https://www.avioyx.com';
    const shareUrl = routeUrl + '/' + LANGUAGE;
    const imageUrl = routeUrl + "/assets/img/landing/hero.jpg";

    return (

        <Helmet prioritizeSeoTags>

            <html lang={LANGUAGE} />

            <title>{TRANSLATE.seo_title}</title>
            <meta name="description" content={TRANSLATE.seo_description} />

            <meta itemprop="name" content={TRANSLATE.seo_title} />
            <meta itemprop="description" content={TRANSLATE.seo_description} />
            <meta itemprop="image" content={imageUrl} />

            <link rel="canonical" href={routeUrl} />
            <link rel="alternate" href={routeUrl + '/en'} hrefLang='x-default' />
            <link rel="alternate" href={routeUrl + '/it'} hrefLang='it' />
            <link rel="alternate" href={routeUrl + '/fr'} hrefLang='fr' />
            <link rel="alternate" href={routeUrl + '/es'} hrefLang='es' />
            <link rel="alternate" href={routeUrl + '/de'} hrefLang='de' />
            <link rel="alternate" href={routeUrl + '/ar'} hrefLang='ar' />
            <link rel="alternate" href={routeUrl + '/ja'} hrefLang='ja' />
            <link rel="alternate" href={routeUrl + '/zh'} hrefLang='zh' />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@publisher_handle" />
            <meta name="twitter:title" content={TRANSLATE.seo_title} />
            <meta name="twitter:description" content={TRANSLATE.seo_description} />
            <meta name="twitter:creator" content="@avioyx" />
            <meta name="twitter:image:src" content={imageUrl} />

            <meta property="og:title" content={TRANSLATE.seo_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={shareUrl} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:description" content={TRANSLATE.seo_description} />
            <meta property="og:site_name" content="avioyx" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta property='fb:app_id' content='236904211103455' />

            <meta itemprop="thumbnailUrl" content={imageUrl} />
            <meta itemprop="image_src" content={imageUrl} />
            <meta itemprop="image" content={imageUrl} />

        </Helmet>
    )
}

const CalcSlider = (props) => {

    return (
        <div className='w-full h-full fixed top-0 left-0 z-[99999]'>
            <div className='w-full h-full absolute z-20  left-0 top-0 bg-avioyx-200 opacity-50' />
            <div className='w-[350px] bg-avioyx-100 absolute top-0 right-0 h-full shadow-xl z-30 flex flex-col p-6'>
                <img src={SRC_CLOSE} className='absolute top-4 right-4 cursor-pointer' />
                <div className='w-full'>
                    <div className='text-xl font-semibold mb-8'>True Air Speed</div>
                    <div className='input-group'>
                        <label>Calibrated Air Speed</label>
                        <div className='w-full flex'>
                            <input type='text' placeholder='CAS' className='rounded-lg text-center bg-avioyx-200' />
                            <input type='text' placeholder='kt' className='rounded-lg text-center bg-avioyx-200 w-[70px] ml-2' />
                        </div>
                    </div>
                    <div className='input-group'>
                        <label>Pressure Altitude</label>
                        <div className='w-full flex'>
                            <input type='text' placeholder='PA' className='rounded-lg text-center bg-avioyx-200' />
                            <input type='text' placeholder='ft' className='rounded-lg text-center bg-avioyx-200 w-[70px] ml-2' />
                        </div>
                    </div>
                    <div className='input-group'>
                        <label>Saturated Air Temperature</label>
                        <div className='w-full flex'>
                            <input type='text' placeholder='SAT' className='rounded-lg text-center bg-avioyx-200' />
                            <input type='text' placeholder='°C' className='rounded-lg text-center bg-avioyx-200 w-[70px] ml-2' />
                        </div>
                    </div>
                    <hr className='border-black' />

                    <div className='flex justify-between my-8'>
                        <div>
                            <div className='font-semibold'>SIGMA</div>
                            <div className='text-xs text-gray-400'>Sigma Coeficient</div>
                        </div>
                        <div className='text-2xl font-semibold'>0.947</div>
                    </div>

                    <div className='flex justify-between my-8'>
                        <div>
                            <div className='font-semibold'>TAS</div>
                            <div className='text-xs text-gray-400'>True Air Speed</div>
                        </div>
                        <div className='text-2xl font-semibold'>102.72 kt</div>
                    </div>
                    <hr className='border-black' />
                </div>
                <div className='w-full flex items-center justify-between mt-auto'>
                    <button className="px-4 py-2 bg-avioyx-100 rounded-lg hover:bg-avioyx-200">Clear</button>
                    <button className="px-4 py-2 bg-sky-500 rounded-lg hover:bg-sky-600">Calculate</button>
                </div>
            </div>
        </div>
    )
}



export default Calculator