import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';

import SRC_LOGO_ICON from '../../assets/img/logo_icon.png';
import SRC_PROFILE from '../../assets/img/icons/profile.svg';
import SRC_TIME from '../../assets/img/icons/time.svg';
import SRC_AIRCRAFTS from '../../assets/img/icons/aircrafts.svg';
import SRC_SEARCH from '../../assets/img/icons/search.svg';
import SRC_CHART from '../../assets/img/icons/chart.svg';
import SRC_CLOSE from '../../assets/img/icons/close.svg';

const Logbook = (props) => {

    const {  LANGUAGE, TRANSLATE, homelang } = props;

    return (
        <>
            {/*<LogDetail />*/}
            <HeaderSEO {...props}
                LANGUAGE={homelang || LANGUAGE}

            />

            <div className='w-full flex gap-x-4'>
                <div className='w-full flex flex-col gap-y-4 pb-10'>
                    <div className='w-full input-group with-icon mb-2'>
                        <img className='input-icon' src={SRC_SEARCH} />
                        <input type='text' placeholder='Search Logs' />
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 pl-2 cursor-pointer hover:bg-avioyx-50 relative flex'> 
                        <div className='w-[90px] flex-shrink-0 mr-4 flex flex-col items-start justify-center text-center'>
                            <div className='text-5xl font-semibold w-full'>
                                23
                            </div>
                            <div className='text-sm opacity-50 mt-2 w-full'>Nov, 23</div>
                        </div>
                        <div className='w-full'>
                            <div className='flex w-full justify-between items-center'>
                                <div className='text-lg font-semibold flex-shrink-0'>SFO</div>
                                <div className='w-full h-[2px] bg-black mx-6 relative'>
                                    <div className='absolute left-0 top-0 h-full w-[100%] bg-sky-500'>
                                        <div className='w-[10px] h-[10px] rounded-full bg-sky-500 absolute right-0 -top-[4px]' />
                                    </div>
                                    <div className='absolute top-3 w-full flex items-center justify-center opacity-50 text-xs'>
                                        1hr 59min
                                    </div>
                                </div>
                                <div className='text-lg font-semibold flex-shrink-0'>SAN</div>
                            </div>
                            <div className='flex gap-x-10 pt-4 mt-3 justify-between'>
                                <div className='flex items-center text-xs opacity-50'>
                                    <img className='mr-2 w-[18px]' src={SRC_AIRCRAFTS} />
                                    I-CNTR - Cessna - C152
                                </div>
                                <div className='flex items-center text-xs opacity-50'>
                                    <img className='mr-2 w-[18px]' src={SRC_PROFILE} />
                                    Ahmed Allem
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 pl-2 cursor-pointer hover:bg-avioyx-50 relative flex'> 
                        <div className='w-[90px] flex-shrink-0 mr-4 flex flex-col items-start justify-center text-center'>
                            <div className='text-5xl font-semibold w-full'>
                                20
                            </div>
                            <div className='text-sm opacity-50 mt-2 w-full'>Nov, 23</div>
                        </div>
                        <div className='w-full'>
                            <div className='flex w-full justify-between items-center'>
                                <div className='text-lg font-semibold flex-shrink-0'>SFO</div>
                                <div className='w-full h-[2px] bg-black mx-6 relative'>
                                    <div className='absolute left-0 top-0 h-full w-[100%] bg-sky-500'>
                                        <div className='w-[10px] h-[10px] rounded-full bg-sky-500 absolute right-0 -top-[4px]' />
                                    </div>
                                    <div className='absolute top-3 w-full flex items-center justify-center opacity-50 text-xs'>
                                        1hr 59min
                                    </div>
                                </div>
                                <div className='text-lg font-semibold flex-shrink-0'>SAN</div>
                            </div>
                            <div className='flex gap-x-10 pt-4 mt-3 justify-between'>
                                <div className='flex items-center text-xs opacity-50'>
                                    <img className='mr-2 w-[18px]' src={SRC_AIRCRAFTS} />
                                    I-CNTR - Cessna - C152
                                </div>
                                <div className='flex items-center text-xs opacity-50'>
                                    <img className='mr-2 w-[18px]' src={SRC_PROFILE} />
                                    Ahmed Allem
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 pl-2 cursor-pointer hover:bg-avioyx-50 relative flex'> 
                        <div className='w-[90px] flex-shrink-0 mr-4 flex flex-col items-start justify-center text-center'>
                            <div className='text-5xl font-semibold w-full'>
                                18
                            </div>
                            <div className='text-sm opacity-50 mt-2 w-full'>Nov, 23</div>
                        </div>
                        <div className='w-full'>
                            <div className='flex w-full justify-between items-center'>
                                <div className='text-lg font-semibold flex-shrink-0'>SFO</div>
                                <div className='w-full h-[2px] bg-black mx-6 relative'>
                                    <div className='absolute left-0 top-0 h-full w-[100%] bg-sky-500'>
                                        <div className='w-[10px] h-[10px] rounded-full bg-sky-500 absolute right-0 -top-[4px]' />
                                    </div>
                                    <div className='absolute top-3 w-full flex items-center justify-center opacity-50 text-xs'>
                                        1hr 59min
                                    </div>
                                </div>
                                <div className='text-lg font-semibold flex-shrink-0'>SAN</div>
                            </div>
                            <div className='flex gap-x-10 pt-4 mt-3 justify-between'>
                                <div className='flex items-center text-xs opacity-50'>
                                    <img className='mr-2 w-[18px]' src={SRC_AIRCRAFTS} />
                                    I-CNTR - Cessna - C152
                                </div>
                                <div className='flex items-center text-xs opacity-50'>
                                    <img className='mr-2 w-[18px]' src={SRC_PROFILE} />
                                    Ahmed Allem
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='rounded-lg  bg-avioyx-100 p-6 pl-2 cursor-pointer hover:bg-avioyx-50 relative flex'> 
                        <div className='w-[90px] flex-shrink-0 mr-4 flex flex-col items-start justify-center text-center'>
                            <div className='text-5xl font-semibold w-full'>
                                16
                            </div>
                            <div className='text-sm opacity-50 mt-2 w-full'>Nov, 23</div>
                        </div>
                        <div className='w-full'>
                            <div className='flex w-full justify-between items-center'>
                                <div className='text-lg font-semibold flex-shrink-0'>SFO</div>
                                <div className='w-full h-[2px] bg-black mx-6 relative'>
                                    <div className='absolute left-0 top-0 h-full w-[100%] bg-sky-500'>
                                        <div className='w-[10px] h-[10px] rounded-full bg-sky-500 absolute right-0 -top-[4px]' />
                                    </div>
                                    <div className='absolute top-3 w-full flex items-center justify-center opacity-50 text-xs'>
                                        1hr 59min
                                    </div>
                                </div>
                                <div className='text-lg font-semibold flex-shrink-0'>SAN</div>
                            </div>
                            <div className='flex gap-x-10 pt-4 mt-3 justify-between'>
                                <div className='flex items-center text-xs opacity-50'>
                                    <img className='mr-2 w-[18px]' src={SRC_AIRCRAFTS} />
                                    I-CNTR - Cessna - C152
                                </div>
                                <div className='flex items-center text-xs opacity-50'>
                                    <img className='mr-2 w-[18px]' src={SRC_PROFILE} />
                                    Ahmed Allem
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className='w-[300px] flex-shrink-0'>
                    <div className='rounded-lg sticky  w-full flex-shrink-0 bg-avioyx-100 p-4 flex items-center justify-center mb-auto'>
                        <span className='py-10'>Calendar</span>
                    </div>
                    <div className='p-6 bg-avioyx-100 rounded-lg col-span-1 flex flex-col mt-4'>
                        <div className='w-full flex items-start justify-between mb-4'>
                            <div className='flex items-center text-sm'>
                                <img className='mr-3 blue-icon' src={SRC_CHART} />
                                Totals
                            </div>
                        </div>
                        <div className='w-full py-2 flex items-center justify-between text-sm'>
                            This month
                            <div className='font-semibold'>09hr 39min</div>
                        </div>
                        <div className='w-full py-2 flex items-center justify-between text-sm'>
                            Day
                            <div className='font-semibold'>07hr 39min</div>
                        </div>
                        <div className='w-full py-2 flex items-center justify-between text-sm'>
                            Night
                            <div className='font-semibold'>02hr 00min</div>
                        </div>
                        <hr className='border-black my-3' />
                        <div className='w-full py-2 flex items-center justify-between text-sm'>
                            GRAND TOTAL
                            <div className='font-semibold'>96hr 54min</div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}


const HeaderSEO = (props) => {

    const { TRANSLATE, LANGUAGE } = props;

    const routeUrl = 'https://www.avioyx.com';
    const shareUrl = routeUrl + '/' + LANGUAGE;
    const imageUrl = routeUrl + "/assets/img/landing/hero.jpg";

    return (

        <Helmet prioritizeSeoTags>

            <html lang={LANGUAGE} />

            <title>{TRANSLATE.seo_title}</title>
            <meta name="description" content={TRANSLATE.seo_description} />

            <meta itemprop="name" content={TRANSLATE.seo_title} />
            <meta itemprop="description" content={TRANSLATE.seo_description} />
            <meta itemprop="image" content={imageUrl} />

            <link rel="canonical" href={routeUrl} />
            <link rel="alternate" href={routeUrl + '/en'} hrefLang='x-default' />
            <link rel="alternate" href={routeUrl + '/it'} hrefLang='it' />
            <link rel="alternate" href={routeUrl + '/fr'} hrefLang='fr' />
            <link rel="alternate" href={routeUrl + '/es'} hrefLang='es' />
            <link rel="alternate" href={routeUrl + '/de'} hrefLang='de' />
            <link rel="alternate" href={routeUrl + '/ar'} hrefLang='ar' />
            <link rel="alternate" href={routeUrl + '/ja'} hrefLang='ja' />
            <link rel="alternate" href={routeUrl + '/zh'} hrefLang='zh' />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@publisher_handle" />
            <meta name="twitter:title" content={TRANSLATE.seo_title} />
            <meta name="twitter:description" content={TRANSLATE.seo_description} />
            <meta name="twitter:creator" content="@avioyx" />
            <meta name="twitter:image:src" content={imageUrl} />

            <meta property="og:title" content={TRANSLATE.seo_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={shareUrl} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:description" content={TRANSLATE.seo_description} />
            <meta property="og:site_name" content="avioyx" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
            <meta property='fb:app_id' content='236904211103455' />

            <meta itemprop="thumbnailUrl" content={imageUrl} />
            <meta itemprop="image_src" content={imageUrl} />
            <meta itemprop="image" content={imageUrl} />

        </Helmet>
    )
}

const LogDetail = (props) => {

    return (
        <div className='w-full h-full fixed top-0 left-0 z-[99999]'>
            <div className='w-full h-full absolute z-20  left-0 top-0 bg-avioyx-200 opacity-50' />
            <div className='w-[350px] bg-avioyx-100 absolute top-0 right-0 h-full shadow-xl z-30 flex flex-col p-6'>
                <img src={SRC_CLOSE} className='absolute top-4 right-4 cursor-pointer' />
                <div className='w-full'>
                        <div className='w-full py-4 text-gray-400 text-lg font-semibold'>Flight</div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            Date
                            <div className='font-semibold'>Sat - 25/11/2023</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            Flight Number
                            <div className='font-semibold'>4093</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            Aircraft ID
                            <div className='font-semibold'>C152</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            Aircraft Type
                            <div className='font-semibold'>I CNTR</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            From
                            <div className='font-semibold'>KSFO</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            To
                            <div className='font-semibold'>KSAN</div>
                        </div>

                        <div className='w-full py-4 text-gray-400 text-lg font-semibold'>Time</div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            Total Time
                            <div className='font-semibold'>1h 59min</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            Day
                            <div className='font-semibold'>1h 39min</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            Night
                            <div className='font-semibold'>0h 20min</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            PIC
                            <div className='font-semibold'>-</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            DUAL
                            <div className='font-semibold'>1h 59min</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            SIC
                            <div className='font-semibold'>-</div>
                        </div>
                        <div className='w-full py-4 text-gray-400 text-lg font-semibold'>Duty</div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            On Duty
                            <div className='font-semibold'>1h 59min</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            Off Duty
                            <div className='font-semibold'>1h 39min</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            Instructor
                            <div className='font-semibold'>John Irons</div>
                        </div>
                        <div className='w-full py-1 flex items-center justify-between text-sm'>
                            Pilot Flying
                            <div className='font-semibold'>Ahmed Allem</div>
                        </div>
                </div>
                <div className='w-full flex items-center justify-between mt-auto'>
                    <button className="px-4 py-2 bg-avioyx-100 rounded-lg hover:bg-avioyx-200">Clear</button>
                    <button className="px-4 py-2 bg-sky-500 rounded-lg hover:bg-sky-600">Calculate</button>
                </div>
            </div>
        </div>
    )
}



export default Logbook